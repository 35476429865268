import yen1object from '../assets/img/ep4/yen-1-object.jpg'
import yen2text from '../assets/img/ep4/yen-2-text.jpg'
import yen2stories from '../assets/img/ep4/yen2stories.jpg'
import yen2space from '../assets/img/ep4/yen2space.jpg'
import yen3adverts from '../assets/img/ep4/yen-3-adverts.jpg'
import khengletters1 from '../assets/img/ep4/kheng-letters1.jpg'
import khengletters2 from '../assets/img/ep4/kheng-letters2.jpg'
import khengvideos1 from '../assets/img/ep4/kheng-videos1.jpg'
import khengvideos2 from '../assets/img/ep4/kheng-videos2.jpg'
import khengmusic1 from '../assets/img/ep4/kheng-music1.jpg'
import khengmusic2 from '../assets/img/ep4/kheng-music2.jpg'

const content = [
  {
    id: 'efour1',
    direction: 'incoming',
    text: 'It has been an eventful month.',
    next: 'efour2',
  },
  {
    id: 'efour2',
    next: 'efour3',
    direction: 'incoming',
    text:
      'You’re in your bedroom at the end of another busy week, with a little time to kill.',
  },
  {
    id: 'efour3',
    direction: 'incoming',
    text: 'You ...',
    externalEvent: 'makejournobookchoice',
  },
  {
    id: 'efour4a0',
    next: 'efour4a1',
    auto: true,
    direction: 'outgoing',
    text: 'Notice your journal.',
  },
  {
    id: 'efour4a1',
    next: 'efour4a2',
    direction: 'incoming',
    text: 'The one you’ve been meaning to write in for ages.',
  },
  {
    id: 'efour4a2',
    direction: 'incoming',
    text:
      'But you’re not ready to sit down and process the week yet. Perhaps a little later?',
    externalEvent: 'makebookchoice',
  },
  {
    id: 'efour4b0',
    next: 'efour4b1',
    auto: true,
    direction: 'outgoing',
    text: 'Notice a stack of books.',
    externalEvent: 'bookshelfappear',
  },
  {
    id: 'efour4b1',
    next: 'efour4b2',
    direction: 'incoming',
    text: 'You’ve been meaning to sell the stack on Carousell for a while.',
  },
  {
    id: 'efour4b2',
    next: 'efour4b3',
    direction: 'incoming',
    text: 'Well, it’s time to do it. Earn a little extra cash.',
  },
  {
    id: 'efour4b3',
    next: 'efour4b4',
    direction: 'incoming',
    text: 'You pick up the pile and photograph each book.',
  },
  {
    id: 'efour4b4',
    next: 'efour4b5',
    direction: 'incoming',
    text:
      'As you go through the books, some bring back memories, others confuse you. When did you even buy this?',
  },
  {
    id: 'efour4b5',
    next: 'efour4b6',
    direction: 'incoming',
    text:
      'The last book has a handwritten note on the first page. It’s a gift from your father. You got it on the first day of university.',
  },
  {
    id: 'efour4b6',
    next: 'efour4b7',
    direction: 'incoming',
    text:
      'How did it end up here? It’s not something you’re willing to part with so you place it gently on the bookshelf.',
  },
  {
    id: 'efour4b7',
    next: 'efour4b8',
    direction: 'incoming',
    text:
      'With that, the photos are done. Now to upload them and list everything … Done!',
  },
  {
    id: 'efour4b8',
    next: 'efour4b9',
    direction: 'incoming',
    text: 'Oh, what’s that for sale?',
  },
  {
    id: 'efour4b9',
    next: 'efour4b10',
    direction: 'incoming',
    text:
      'You click it. And another thing, and another. It’s fun to see what people are trying to sell off.',
  },
  {
    id: 'efour4b10',
    next: 'efour4b11',
    direction: 'incoming',
    text:
      'You scroll past the shops selling K-pop albums, old tableware, clothes, renting rooms (always thought it strange that anyone would look for this on Carousell, but anyway) and arrive at … a value store?',
  },
  {
    id: 'efour4b11',
    next: 'efour4b12',
    direction: 'incoming',
    text:
      'Hm. You thought those were just small shops on streets, peddling wares for a dollar or two. Best place for a bargain.',
  },
  {
    id: 'efour4b12',
    next: 'efour4b13',
    direction: 'incoming',
    text:
      'Ok this looks like most stores so far … but wait ... plastic containers. Used? Who would buy that?',
  },
  {
    id: 'efour4b13',
    next: 'efour4b14',
    direction: 'incoming',
    text:
      'The store description says “Everything must go. No returns, no refunds. All value is to be negotiated. Here are souvenirs from a past imperfect relationship.”',
  },
  {
    id: 'efour4b14',
    direction: 'incoming',
    text: 'You want to find out more.',
    externalEvent: 'openyenphang',
  },
  {
    id: 'efour5',
    next: 'efour6',
    direction: 'incoming',
    text: 'Carousell was a surprising choice.',
    externalEvent: 'playcuration',
  },
  {
    id: 'efour6',
    direction: 'incoming',
    text:
      'A work of art consisting of everyday objects accompanied by text, uploaded onto a platform selling everyday objects that were also accompanied by texts. And adverts.',
    options: [
      {
        image: (props) => <img {...props} src={yen1object} alt="" />,
        to: 'efour7',
      },
      {
        image: (props) => <img {...props} src={yen2text} alt="" />,
        to: 'efour17',
      },
      {
        image: (props) => <img {...props} src={yen3adverts} alt="" />,
        to: 'efour23',
      },
    ],
  },
  {
    id: 'efour7',
    next: 'efour8',
    auto: true,
    direction: 'outgoing',
    image: (props) => <img {...props} src={yen1object} alt="" />,
  },
  {
    id: 'efour8',
    next: 'efour9',
    direction: 'incoming',
    text:
      "In some way, all of these things were not necessary in the seller's life. Not anymore.",
  },
  {
    id: 'efour9',
    next: 'efour10',
    direction: 'incoming',
    text: 'Their time with the seller over.',
  },
  {
    id: 'efour10',
    next: 'efour11',
    direction: 'incoming',
    text: 'Their time with you begins, should you choose to purchase them.',
  },
  {
    id: 'efour11',
    next: 'efour12',
    direction: 'incoming',
    text: 'You consider the objects being sold by',
    options: [
      { text: 'The artist.', to: 'efour12a0' },
      { text: 'The other sellers.', to: 'efour12b0' },
    ],
  },
  {
    id: 'efour12a0',
    next: 'efour12a1',
    auto: true,
    direction: 'outgoing',
    text: 'The artist.',
  },
  {
    id: 'efour12a1',
    next: 'efour12a2',
    direction: 'incoming',
    text:
      'It always struck you that there were some items that went unsold for ages on Carousell.',
  },
  {
    id: 'efour12a2',
    next: 'efour12a3',
    direction: 'incoming',
    text:
      'Things that felt too old, or irrelevant, or things that just did not look like they were worth buying.',
  },
  {
    id: 'efour12a3',
    next: 'efour12a4',
    direction: 'incoming',
    text:
      'In the case of this store, this artwork, it is clear that the objects hold much value for the seller, but little for you.',
  },
  {
    id: 'efour12a4',
    next: 'efour12a5',
    direction: 'incoming',
    text: 'You can’t think of why you’d ever buy them.',
  },
  {
    id: 'efour12a5',
    next: 'efour12a6',
    direction: 'incoming',
    text:
      'Maybe this would change, dramatically if you were to see them elsewhere. Perhaps under the harsh white light of a white cube.',
  },
  {
    id: 'efour12a6',
    next: 'efour12a7',
    direction: 'incoming',
    text:
      'So then, why Carousell? An everyday platform where these things could be easily ignored?',
  },
  {
    id: 'efour12a7',
    direction: 'incoming',
    text: 'Maybe to think about...',
    options: [
      { text: 'Value?', to: 'efour13a0' },
      { text: 'Consumer culture?', to: 'efour13b0' },
    ],
  },
  {
    id: 'efour12b0',
    next: 'efour12b1',
    auto: true,
    direction: 'outgoing',
    text: 'The other sellers.',
  },
  {
    id: 'efour12b1',
    next: 'efour12b2',
    direction: 'incoming',
    text:
      'You enjoy scrolling all the way down and seeing all the other things people are selling that have been deemed similar to the artist’s listings.',
  },
  {
    id: 'efour12b2',
    next: 'efour12b3',
    direction: 'incoming',
    text: 'You click through some of them.',
  },
  {
    id: 'efour12b3',
    next: 'efour12b4',
    direction: 'incoming',
    text: 'Their listings are mostly similar, save for the description.',
  },
  {
    id: 'efour12b4',
    next: 'efour14',
    direction: 'incoming',
    text:
      'Some focused simply on selling, others offer a bit of a marketing pitch. You wonder if you’ll find another seller with stories to share.',
  },
  {
    id: 'efour13a0',
    next: 'efour13a1',
    auto: true,
    direction: 'outgoing',
    text: 'Value.',
  },
  {
    id: 'efour13a1',
    next: 'efour13a2',
    direction: 'incoming',
    text: 'In one listing, the artist writes',
  },
  {
    id: 'efour13a2',
    next: 'efour13a3',
    direction: 'incoming',
    text: 'What is value?',
    textStyle: 'italic',
  },
  {
    id: 'efour13a3',
    next: 'efour13a4',
    direction: 'incoming',
    text: 'Carousell offers an interesting way to consider this question.',
  },
  {
    id: 'efour13a4',
    next: 'efour13a5',
    direction: 'incoming',
    text:
      'The value of an object is determined both by the seller and the buyer.',
  },
  {
    id: 'efour13a5',
    next: 'efour13a6',
    direction: 'incoming',
    text:
      'This store in particular also asks us to consider the value of an object as part of our everyday lives.',
  },
  {
    id: 'efour13a6',
    next: 'efour13a7',
    direction: 'incoming',
    text: 'Stories are shared alongside the object.',
  },
  {
    id: 'efour13a7',
    next: 'efour13a8',
    direction: 'incoming',
    text: 'And beyond, it also asks –',
  },
  {
    id: 'efour13a8',
    next: 'efour14',
    direction: 'incoming',
    text:
      'What is the value of a life? How is it measured over a lifetime, even in death?',
    fontStyle: 'italic',
  },
  {
    id: 'efour13b0',
    next: 'efour13b1',
    auto: true,
    direction: 'outgoing',
    text: 'Consumer culture.',
  },
  {
    id: 'efour13b1',
    next: 'efour13b2',
    direction: 'incoming',
    text:
      'You suppose it’s interesting to create a work on Carousell because it brings together different types of consumer culture into one space.',
  },
  {
    id: 'efour13b2',
    next: 'efour13b3',
    direction: 'incoming',
    text: 'Here, things are sold second-hand and first-hand.',
  },
  {
    id: 'efour13b3',
    next: 'efour13b4',
    direction: 'incoming',
    text:
      'Fresh bottles of soap, gently used make-up that doesn’t suit someone, bulk orders of albums that cost too much to ship otherwise.',
  },
  {
    id: 'efour13b4',
    next: 'efour13b5',
    direction: 'incoming',
    text: 'It’s a supermarket for anything and everything.',
  },
  {
    id: 'efour13b5',
    next: 'efour13b6',
    direction: 'incoming',
    text: 'You want it, Carousell has probably got it.',
  },
  {
    id: 'efour13b6',
    next: 'efour13b7',
    direction: 'incoming',
    text:
      'Amidst all this, the rest of the commercial world bleeds in through adverts.',
  },
  {
    id: 'efour13b7',
    next: 'efour14',
    direction: 'incoming',
    text:
      'Always in the corner of your eye, reminding you that there is more out there.',
  },
  {
    id: 'efour14',
    direction: 'incoming',
    text: '',
    options: [
      { text: 'You want to explore the store further.', to: 'efour15' },
      { text: "You're ready to explore something different.", to: 'efourx0' },
    ],
  },
  {
    id: 'efour15',
    next: 'efour16',
    auto: true,
    direction: 'outgoing',
    text: 'You want to explore the store further.',
  },
  {
    id: 'efour16',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={yen2text} alt="" />,
        to: 'efour17',
      },
      {
        image: (props) => <img {...props} src={yen3adverts} alt="" />,
        to: 'efour23',
      },
    ],
  },
  {
    id: 'efour17',
    next: 'efour18',
    auto: true,
    direction: 'outgoing',
    image: (props) => <img {...props} src={yen2text} alt="" />,
  },
  {
    id: 'efour18',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={yen2stories} alt="" />,
        to: 'efour19a0',
      },
      {
        image: (props) => <img {...props} src={yen2space} alt="" />,
        to: 'efour19a0',
      },
    ],
  },
  {
    id: 'efour19a0',
    next: 'efour19a1',
    auto: true,
    direction: 'outgoing',
    image: (props) => <img {...props} src={yen2stories} alt="" />,
  },
  {
    id: 'efour19a1',
    next: 'efour19a2',
    direction: 'incoming',
    text: 'The descriptions are small stories about everyday life.',
  },
  {
    id: 'efour19a2',
    next: 'efour19a3',
    direction: 'incoming',
    text: 'The objects prompting memories of people and places.',
  },
  {
    id: 'efour19a3',
    next: 'efour20',
    direction: 'incoming',
    text:
      'You consider your room for a moment. The objects within, the stories they hold.',
  },
  {
    id: 'efour19b0',
    next: 'efour19b1',
    auto: true,
    direction: 'outgoing',
    image: (props) => <img {...props} src={yen2space} alt="" />,
  },
  {
    id: 'efour19b1',
    next: 'efour19b2',
    direction: 'incoming',
    text: 'These locations come together to form a map of the seller’s life.',
  },
  {
    id: 'efour19b2',
    next: 'efour19b3',
    direction: 'incoming',
    text: 'The objects prompting memories of places and people.',
  },
  {
    id: 'efour19b3',
    next: 'efour20',
    direction: 'incoming',
    text:
      'You consider your room for a moment. The objects within, the places they come from, the places they transport you to.',
  },
  {
    id: 'efour20',
    direction: 'incoming',
    text: '',
    options: [
      { text: 'You want to explore the store further.', to: 'efour21' },
      { text: "You're ready to explore something different.", to: 'efourx0' },
    ],
  },
  {
    id: 'efour21',
    next: 'efour22',
    auto: true,
    direction: 'outgoing',
    text: 'You want to explore the store further.',
  },
  {
    id: 'efour22',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={yen1object} alt="" />,
        to: 'efour7',
      },
      {
        image: (props) => <img {...props} src={yen3adverts} alt="" />,
        to: 'efour23',
      },
    ],
  },
  {
    id: 'efour23',
    next: 'efour24',
    auto: true,
    direction: 'outgoing',
    image: (props) => <img {...props} src={yen3adverts} alt="" />,
  },
  {
    id: 'efour24',
    next: 'efour25',
    direction: 'incoming',
    text: 'Adverts.',
  },
  {
    id: 'efour25',
    next: 'efour26',
    direction: 'incoming',
    text: 'They follow you around the internet. They followed you here.',
  },
  {
    id: 'efour26',
    next: 'efour27',
    direction: 'incoming',
    text:
      'Amidst all these things that are important to the artist are the things that the internet desperately wants to make important to you.',
  },
  {
    id: 'efour27',
    next: 'efour28',
    direction: 'incoming',
    text: 'Do you want them, need them? Will you keep them?',
  },
  {
    id: 'efour28',
    direction: 'incoming',
    text: '',
    options: [
      { text: 'You want to explore the store further.', to: 'efour29' },
      { text: "You're ready to explore something different.", to: 'efourx0' },
    ],
  },
  {
    id: 'efour29',
    next: 'efour30',
    auto: true,
    direction: 'outgoing',
    text: 'You want to explore the store further.',
  },
  {
    id: 'efour30',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={yen1object} alt="" />,
        to: 'efour7',
      },
      {
        image: (props) => <img {...props} src={yen2text} alt="" />,
        to: 'efour17',
      },
    ],
  },
  {
    id: 'efourx0',
    next: 'efourx1',
    auto: true,
    direction: 'outgoing',
    text: "You're ready to explore something different.",
    externalEvent: 'playbedroom',
  },
  {
    id: 'efourx1',
    next: 'efourx2',
    direction: 'incoming',
    text:
      'After your time on Carousell, you view your space a little differently.',
    externalEvent: 'bookshelfdisappear',
  },
  {
    id: 'efourx2',
    next: 'efourx3',
    direction: 'incoming',
    text:
      'Everything in here is something you chose to buy or bring with you from elsewhere. And you choose to keep it every day.',
  },
  {
    id: 'efourx3',
    next: 'efourx4',
    direction: 'incoming',
    text: 'Each piece has its own story.',
  },
  {
    id: 'efourx4',
    next: 'efourx5',
    direction: 'incoming',
    text: 'The love letters, the records, the books, the video games.',
  },
  {
    id: 'efourx5',
    next: 'efourx6',
    direction: 'incoming',
    text:
      'Some to share – like a belated thank you for father, who gifted you the book. You send him a text with a picture of the note he wrote you.',
  },
  {
    id: 'efourx6',
    direction: 'incoming',
    text: 'Some to scribble into a journal.',
    externalEvent: 'openkhenghua',
  },
  {
    id: 'efour31',
    next: 'efour32',
    direction: 'incoming',
    text: 'Oh.',
    externalEvent: 'seaceilingappear',
  },
  {
    id: 'efour32',
    next: 'efour33',
    direction: 'incoming',
    text: 'Here we go again.',
  },
  {
    id: 'efour33',
    next: 'efour34',
    direction: 'incoming',
    text: 'This is... not your journal.',
  },
  {
    id: 'efour34',
    next: 'efour35',
    direction: 'incoming',
    text: 'A digital diary.',
    externalEvent: 'playcuration',
  },
  {
    id: 'efour35',
    next: 'efour36',
    direction: 'incoming',
    text:
      'It takes you back to the days that you and everyone you knew had a blog. And then came Facebook, Twitter, Snapchat, Instagram.',
  },
  {
    id: 'efour36',
    next: 'efour37',
    direction: 'incoming',
    text:
      'There are more ways now to connect than ever before. Regardless of time and distance.',
  },
  {
    id: 'efour37',
    direction: 'incoming',
    text: 'You remember the...',
    options: [
      { text: 'Videos.', to: 'efour38a0' },
      { text: 'Letters & Drawings.', to: 'efour38b0' },
      { text: 'Songs.', to: 'efour38c0' },
    ],
  },
  {
    id: 'efour38a0',
    next: 'efour38a1',
    auto: true,
    direction: 'outgoing',
    text: 'Videos.',
  },
  {
    id: 'efour38a1',
    next: 'efour38a2',
    direction: 'incoming',
    image: (props) => <img {...props} src={khengvideos1} alt="" />,
  },
  {
    id: 'efour38a2',
    next: 'efour38a3',
    direction: 'incoming',
    text: 'Meeting online, instead of sharing space.',
  },
  {
    id: 'efour38a3',
    next: 'efour38a4',
    direction: 'incoming',
    text:
      'You can find ways to be at the ‘same place’, at the ‘same time’ despite distance.',
  },
  {
    id: 'efour38a4',
    next: 'efour38a5',
    direction: 'incoming',
    text: 'Bittersweet and precious moments.',
  },
  {
    id: 'efour38a5',
    next: 'efour38a6',
    direction: 'incoming',
    image: (props) => <img {...props} src={khengvideos2} alt="" />,
  },
  {
    id: 'efour38a6',
    direction: 'incoming',
    text: '',
    options: [
      { text: 'Explore another part of the diary.', to: 'efour38a7' },
      { text: 'Close the diary.', to: 'efour39x' },
    ],
  },
  {
    id: 'efour38a7',
    next: 'efour38a8',
    auto: true,
    direction: 'outgoing',
    text: 'Explore another part of the diary.',
  },
  {
    id: 'efour38a8',
    direction: 'incoming',
    text: '',
    options: [
      { text: 'Letters & Drawings.', to: 'efour38b0' },
      { text: 'Songs.', to: 'efour38c0' },
    ],
  },
  {
    id: 'efour38b0',
    next: 'efour38b1',
    auto: true,
    direction: 'outgoing',
    text: 'Letters & Drawings.',
  },
  {
    id: 'efour38b1',
    next: 'efour38b2',
    direction: 'incoming',
    image: (props) => <img {...props} src={khengletters1} alt="" />,
  },
  {
    id: 'efour38b2',
    next: 'efour38b3',
    direction: 'incoming',
    text:
      'You reckon these are the spaces that the artists are in at the moment. Separated by several continents and seas.',
  },
  {
    id: 'efour38b3',
    next: 'efour38b4',
    direction: 'incoming',
    text:
      'Marking maps, writing letters, sending postcards, taking photographs.',
  },
  {
    id: 'efour38b4',
    next: 'efour38b5',
    direction: 'incoming',
    text:
      'All wonderful ways to mark the moments of our life and to send them across the seas.',
  },
  {
    id: 'efour38b5',
    next: 'efour38b6',
    direction: 'incoming',
    image: (props) => <img {...props} src={khengletters2} alt="" />,
  },
  {
    id: 'efour38b6',
    direction: 'incoming',
    text: '',
    options: [
      { text: 'Explore another part of the diary.', to: 'efour38b7' },
      { text: 'Close the diary.', to: 'efour39x' },
    ],
  },
  {
    id: 'efour38b7',
    next: 'efour38b8',
    auto: true,
    direction: 'outgoing',
    text: 'Explore another part of the diary.',
  },
  {
    id: 'efour38b8',
    direction: 'incoming',
    text: '',
    options: [
      { text: 'Videos.', to: 'efour38a0' },
      { text: 'Songs.', to: 'efour38c0' },
    ],
  },
  {
    id: 'efour38c0',
    next: 'efour38c1',
    auto: true,
    direction: 'outgoing',
    text: 'Songs.',
  },
  {
    id: 'efour38c1',
    next: 'efour38c2',
    direction: 'incoming',
    image: (props) => <img {...props} src={khengmusic1} alt="" />,
  },
  {
    id: 'efour38c2',
    next: 'efour38c3',
    direction: 'incoming',
    text: [
      'But I won’t deny I’m lonely here,',
      'Without you and how things used to be,',
      'And I’m feeling like I’m lost at sea. ',
      'Lost at sea.',
    ],
  },
  {
    id: 'efour38c3',
    next: 'efour38c4',
    direction: 'incoming',
    text: '~',
  },
  {
    id: 'efour38c4',
    next: 'efour38c5',
    direction: 'incoming',
    text: [
      'Today I’m feeling strong,',
      'Like nothing can go wrong,',
      'Like I’m born to sing this song,',
      'Yeah today I’m feeling strong.',
    ],
  },
  {
    id: 'efour38c5',
    next: 'efour39',
    direction: 'incoming',
    image: (props) => <img {...props} src={khengmusic2} alt="" />,
  },
  {
    id: 'efour39x',
    next: 'efour39',
    auto: true,
    direction: 'outgoing',
    text: 'Close the diary.',
  },
  {
    id: 'efour39',
    next: 'efour40',
    direction: 'incoming',
    text: 'The melody of Feeling Strong lingers in your mind.',
    externalEvent: 'sunrise',
  },
  {
    id: 'efour40',
    next: 'efour41',
    direction: 'incoming',
    text: 'You hum it as you scribble some of your thoughts into your journal.',
  },
  {
    id: 'efour41',
    next: 'efour42',
    direction: 'incoming',
    text:
      'Before you put it away, you flip through the pages, looking through the photographs, postcards and sketches stuck inside.',
  },
  {
    id: 'efour42',
    next: 'efour43',
    direction: 'incoming',
    text:
      'It was nice to see people bring together the stories of their lives, which are so often split up in tiny fragments across the internet. And give them form and shape – one unexpected, the other familiar.',
  },
  {
    id: 'efour43',
    next: 'efour44',
    direction: 'incoming',
    text:
      'Come to think of it, over the past few weeks, you’ve heard many stories.',
  },
  {
    id: 'efour44',
    next: 'efour45',
    direction: 'incoming',
    text: 'Some about the pandemic.',
  },
  {
    id: 'efour45',
    next: 'efour46',
    direction: 'incoming',
    text: 'Others about life or love or distance.',
  },
  {
    id: 'efour46',
    next: 'efour47',
    direction: 'incoming',
    text: 'About the ways we create space and care for ourselves.',
  },
  {
    id: 'efour47',
    next: 'efour48',
    direction: 'incoming',
    text: 'The ways we reach out to one another.',
  },
  {
    id: 'efour48',
    next: 'efour49',
    direction: 'incoming',
    text: 'All about life,',
  },
  {
    id: 'efour49',
    next: 'efour50',
    direction: 'incoming',
    text: 'lived day by day.',
  },
  {
    id: 'efour50',
    next: 'efour51',
    direction: 'incoming',
    text: '…',
  },
  {
    id: 'efour51',
    next: 'efour52',
    direction: 'incoming',
    text: 'And so,',
  },
  {
    id: 'efour52',
    next: 'efour53',
    direction: 'incoming',
    text: 'another day ends.',
  },
  {
    id: 'efour53',
    direction: 'incoming',
    text: 'And begins.',
    externalEvent: 'endefour',
  },
]

export default content
