import styled, { css } from 'styled-components'

const Slide = styled.div`
  background-color: black;
  color: white;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;

  animation: fadeIn ease 1s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }

  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`

const SlideText = styled.p`
  font-family: Cirka;
  font-size: 2em;
  color: white;
  position: fixed;
  bottom: 30px;
  width: 100vw;
  margin: 0 auto;
  text-align: center;
`

export default Slide

export { SlideText }
