import Window from '../Window'

const DYArt = (props) => (
  <Window {...props}>
    <iframe
      title="denise yeo"
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/videoseries?list=PLOzOz_cMUsdp9vODM6BiHHEZWI47n-076&autoplay=1&start=0"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </Window>
)

export default DYArt
