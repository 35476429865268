import styled, { css } from 'styled-components'

const Button = styled.button`
  background: white;
  border-radius: 8px;
  color: black;
  font-family: BarlowRegular;
  font-size: 1em;
  margin: 0.25em 0;
  padding: 0.5em 1em;
  border: 3px solid ${(props) => props.theme.colors.cello};
  text-align: left;
  width: 100%;
  transition: 0.5s;
  cursor: pointer;
  &:first-of-type {
    margin-top: 0.5em;
  }
  &:hover {
    background: ${(props) => props.theme.colors.cello};
    color: white;
    border: 3px solid white;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &:focus {
    outline: none;
  }

  ${({ direction }) =>
    direction === 'outgoing' &&
    css`
      border: 3px solid ${(props) => props.theme.colors.hotMagenta};
      &:hover {
        background: ${(props) => props.theme.colors.hotMagenta};
        color: white;
        border: 3px solid white;
      }
    `}
`

export default Button
