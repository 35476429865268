import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSound from 'use-sound'
import styled, { css } from 'styled-components'

import Scene1 from '../components/prologueScenes/scene1'
import Scene2 from '../components/prologueScenes/scene2'
import Scene3 from '../components/prologueScenes/scene3'
import Scene4 from '../components/prologueScenes/scene4'
import Scene5 from '../components/prologueScenes/scene5'
import Scene6 from '../components/prologueScenes/scene6'
import Scene7 from '../components/prologueScenes/scene7'
import Scene8 from '../components/prologueScenes/scene8'
import Scene9 from '../components/prologueScenes/scene9'
import Scene10 from '../components/prologueScenes/scene10'
import Scene11 from '../components/prologueScenes/scene11'
import Scene12 from '../components/prologueScenes/scene12'
import prologue from '../assets/sound/prologue.wav'

const Background = styled.div`
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
`

const CtrlBtn = styled.button`
  background: none;
  border: 1px solid white;
  border-radius: 999px;
  height: 50px;
  width: 50px;
  font-size: 2em;
  color: white;
  cursor: pointer;
  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`

export const PrevBtn = styled(CtrlBtn)`
  position: fixed;
  top: 50vh;
  left: 30px;
  &:focus {
    outline: none;
  }
`

export const NextBtn = styled(CtrlBtn)`
  position: fixed;
  top: 50vh;
  right: 30px;
  &:focus {
    outline: none;
  }
`

const Prologue = () => {
  const [slideId, setSlideId] = useState(1)
  const history = useHistory()
  const [play, { stop }] = useSound(prologue, { loop: true })

  useEffect(() => {
    play()
  }, [play])

  useEffect(() => stop, [stop])

  const handlePrev = () => setSlideId((prev) => (prev > 0 ? prev - 1 : prev))
  const handleNext = () =>
    setSlideId((prev) => {
      if (prev === 6) history.push('/ep1')
      else {
        return prev + 1
      }
    })

  let showPrev = slideId > 1
  let showNext = true

  switch (slideId) {
    case 3:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
      showPrev = false
      showNext = false
      break
    case 4:
    case 7:
      showPrev = false
      break
    default:
      break
  }

  return (
    <Background>
      <Scene1 slideId={slideId} />
      <Scene2 slideId={slideId} setSlideId={setSlideId} />
      <Scene3 slideId={slideId} />
      <Scene4 slideId={slideId} />
      <Scene5 slideId={slideId} />
      <Scene6 slideId={slideId} />
      <Scene7 slideId={slideId} setSlideId={setSlideId} />
      <Scene8 slideId={slideId} setSlideId={setSlideId} />
      <Scene9 slideId={slideId} setSlideId={setSlideId} />
      <Scene10 slideId={slideId} setSlideId={setSlideId} />
      <Scene11 slideId={slideId} setSlideId={setSlideId} />
      <Scene12 slideId={slideId} setSlideId={setSlideId} />

      <PrevBtn show={showPrev} onClick={handlePrev}>
        {'<'}
      </PrevBtn>
      <NextBtn show={showNext} onClick={handleNext}>
        {'>'}
      </NextBtn>
    </Background>
  )
}

export default Prologue
