import plasticbody from '../assets/img/plasticbody.png'
import socialdistancing from '../assets/img/socialdistancing.png'
import finland2 from '../assets/img/finland2.jpg'
import thetriumphofdeath from '../assets/img/thetriumphofdeath.jpg'
import detail1 from '../assets/img/detail1.jpeg'
import detail2 from '../assets/img/detail2.jpeg'
import detail3 from '../assets/img/detail3.jpeg'
import ethereal1 from '../assets/img/ethereal1.jpeg'
import ethereal2 from '../assets/img/ethereal2.jpeg'
import ethereal3 from '../assets/img/ethereal3.jpeg'
import grandworld1 from '../assets/img/grandworld1.jpeg'
import grandworld2 from '../assets/img/grandworld2.jpeg'
import grandworld3 from '../assets/img/grandworld3.jpeg'
import holiday1 from '../assets/img/holiday1.jpeg'
import holiday2 from '../assets/img/holiday2.jpeg'
import holiday3 from '../assets/img/holiday3.jpeg'
import respite1 from '../assets/img/respite1.jpeg'

const content = [
  {
    id: 'eone1',
    direction: 'incoming',
    text:
      "You've changed up the room over the past months, trying to make it liveable and workable.",
    next: 'eone2',
  },
  {
    id: 'eone2',
    direction: 'incoming',
    text:
      'Scattered across it are all the things you bought to comfort yourself.',
    next: 'eone3',
  },
  {
    id: 'eone3',
    direction: 'incoming',
    text: "It's a bit messy. But instead of cleaning it up, you...",
    externalEvent: 'makegamebookchoice',
  },
  {
    id: 'eone4b0',
    direction: 'outgoing',
    auto: true,
    text: 'Want to play a video game.',
    next: 'eone4b1',
  },
  {
    id: 'eone4b1',
    direction: 'incoming',
    text: 'Hmm.',
    next: 'eone4b2',
  },
  {
    id: 'eone4b2',
    direction: 'incoming',
    text: 'But the book... it has been sitting at your bedside forever.',
    next: 'eone4b3',
  },
  {
    id: 'eone4b3',
    direction: 'incoming',
    text: 'You should read it.',
    externalEvent: 'makebookchoice',
  },
  {
    id: 'eone5a0',
    direction: 'outgoing',
    auto: true,
    text: 'Decide to read.',
    next: 'eone5a1',
  },
  {
    id: 'eone5a1',
    direction: 'incoming',
    text: "You've...",
    options: [
      { text: 'Heard of the book.', to: 'eone6a0' },
      { text: 'Have no clue what the book is about.', to: 'eone6b0' },
    ],
  },
  {
    id: 'eone6a0',
    direction: 'outgoing',
    auto: true,
    text: 'Heard of the book.',
    next: 'eone6a1',
  },
  {
    id: 'eone6a1',
    direction: 'incoming',
    text: 'You know some guy named Camus wrote this book years ago.',
    next: 'eone6a2',
  },
  {
    id: 'eone6a2',
    direction: 'incoming',
    text: 'La Peste, The Plague. Hah, sounds familiar.',
    next: 'eone6a3',
  },
  {
    id: 'eone6a3',
    direction: 'incoming',
    text:
      'Beak mask and wide brim hat... creepy. A plague doctor in the nightmarish PPE of the past.',
    next: 'eone6a4',
  },
  {
    id: 'eone6a4',
    direction: 'incoming',
    text:
      'A podcast you listened to said the mask was stuffed with herbs and spices. It protected them from “bad air”, which  they thought caused the plague. ',
    next: 'eone6a5',
  },
  {
    id: 'eone6a5',
    direction: 'incoming',
    text: 'You wonder what kind of a plague Camus wrote about.',
    externalEvent: 'openbook',
  },
  {
    id: 'eone6b0',
    direction: 'outgoing',
    auto: true,
    text: 'Have no clue what the book is about.',
    next: 'eone6b1',
  },
  {
    id: 'eone6b1',
    direction: 'incoming',
    text:
      "It's a gift from your friend, who said it was a 'pandemic must-read'. It's called The Plague, so you get why.",
    next: 'eone6b2',
  },
  {
    id: 'eone6b2',
    direction: 'incoming',
    text:
      'The book explores different ways in which people respond to a plague - some considerate, some crafty. Sounds about right.',
    next: 'eone6b3',
  },
  {
    id: 'eone6b3',
    direction: 'incoming',
    text:
      'Your friend said that the book highlighted how every choice matters in a pandemic. And how bad management and selfishness kills more people than you can count.',
    next: 'eone6b4',
  },
  {
    id: 'eone6b4',
    direction: 'incoming',
    text: 'Sounds gloomy. But honest.',
    externalEvent: 'openbook',
  },
  {
    id: 'eone7',
    next: 'eone8',
    direction: 'incoming',
    text: 'You are trying to make sense of the artwork you just saw.',
    externalEvent: 'curationmusic',
  },
  {
    id: 'eone8',
    direction: 'incoming',
    text: [
      'The words!eone9 linger in your mind.',
      'The images,!eone12 you can still see them.',
      'You’re trying to make sense of it all.!eone15',
    ],
  },
  {
    id: 'eone9',
    direction: 'outgoing',
    auto: true,
    text: 'Words.',
    next: 'eone10',
  },
  {
    id: 'eone10',
    direction: 'incoming',
    text: [
      '“Everybody knows, that pestilences, have a way of recurring in the world”',
      '“They fancied themselves free,!eone11a0 and no one will ever be free, so long as there are pestilences”!eone11b0',
    ],
    textStyle: 'italic',
  },
  {
    id: 'eone11a0',
    direction: 'outgoing',
    auto: true,
    text: 'Free.',
    next: 'eone11a1',
  },
  {
    id: 'eone11a1',
    direction: 'incoming',
    text:
      'Freedom is a difficult word to define right now. We are only as free as the rules allow us to be.',
    next: 'eone11a2',
  },
  {
    id: 'eone11a2',
    direction: 'incoming',
    text:
      'Choices are constantly being made, so we can ride out the pandemic. Get back to ‘normal’.',
    next: 'eone11a4',
  },
  {
    id: 'eone11a4',
    direction: 'incoming',
    text: 'What comes after? How does ‘normal’ change?',
    next: 'eone11a5',
  },
  {
    id: 'eone11a5',
    direction: 'incoming',
    text: 'After the war and the Spanish Flu came the roaring 20s.',
    next: 'eone11a6',
  },
  {
    id: 'eone11a6',
    direction: 'incoming',
    text:
      'But the pandemic has its after-effects. And disease returns in new forms. That sobers you up.',
    next: 'eone11a7',
  },
  {
    id: 'eone11a7',
    direction: 'incoming',
    text:
      'Your nation has reached a point where the rules are shifting and these questions can be asked.',
    next: 'eone11a8',
  },
  {
    id: 'eone11a8',
    direction: 'incoming',
    text: 'But you know this isn’t true for everyone.',
    next: 'eone11a9',
  },
  {
    id: 'eone11a9',
    direction: 'incoming',
    text: 'Some of us are freer.',
    next: 'eone11x0',
  },
  {
    id: 'eone11b0',
    direction: 'outgoing',
    auto: true,
    text: 'Pestilences.',
    next: 'eone11b1',
  },
  {
    id: 'eone11b1',
    direction: 'incoming',
    text: 'Pestilence sounds like a medieval word.',
    next: 'eone11b2',
  },
  {
    id: 'eone11b2',
    direction: 'incoming',
    text: 'Simply put, it means disease.',
    next: 'eone11b3',
  },
  {
    id: 'eone11b3',
    direction: 'incoming',
    text:
      'Metaphorically, it is anything problematic that creeps across time and space.',
    next: 'eone11b4',
  },
  {
    id: 'eone11b4',
    direction: 'incoming',
    text:
      'Biblically, it could mean water turned to blood, frog, diseased livestock, hail, darkness for three days.',
    next: 'eone11b5',
  },
  {
    id: 'eone11b5',
    direction: 'incoming',
    text: "In another time, Camus' plague referred to fascism.",
    next: 'eone11b6',
  },
  {
    id: 'eone11b6',
    direction: 'incoming',
    text: "Right now, it's literal.",
    next: 'eone11b7',
  },
  {
    id: 'eone11b7',
    direction: 'incoming',
    textStyle: 'italic',
    text:
      '"we tell ourselves that pestilence is a mere bogy of the mind, a bad dream that will pass away..."',
    next: 'eone11b8',
  },
  {
    id: 'eone11b8',
    direction: 'incoming',
    text: "Isn't it a nightmare?",
    next: 'eone11b9',
  },
  {
    id: 'eone11b9',
    direction: 'incoming',
    text: 'A year that was at best, spent at home. At worst...',
    next: 'eone11b10',
  },
  {
    id: 'eone11b10',
    direction: 'incoming',
    text:
      "If it happens again, it can't be imagined as a dream. But a nightmare that must be held at bay.",
    next: 'eone11x0',
  },
  {
    id: 'eone11x0',
    direction: 'incoming',
    text: 'You...',
    options: [
      { text: 'Want to know more.', to: 'eone11x1' },
      { text: 'Want to stop. Enough reading.', to: 'eonez0' },
    ],
  },
  {
    id: 'eone11x1',
    direction: 'outgoing',
    auto: true,
    text: 'Want to know more.',
    next: 'eone11x2',
  },
  {
    id: 'eone11x2',
    direction: 'incoming',
    text: [
      'Go back to the words.!eone9',
      'OR',
      'The images,!eone12 you can still see them.',
    ],
  },
  {
    id: 'eonez0',
    direction: 'outgoing',
    auto: true,
    text: 'Want to stop. Enough reading.',
    next: 'eonez1',
    externalEvent: ['bgreset', 'bedroommusic'],
  },
  {
    id: 'eonez1',
    direction: 'incoming',
    text: 'That was intense. Your head spins. You feel slightly sick.',
    next: 'eonez2',
  },
  {
    id: 'eonez2',
    direction: 'incoming',
    text:
      "All those movies you've seen and games you've played about being stuck in the apocalypse. Train to Busan, Contagion, The Last of Us...",
    next: 'eonez3',
  },
  {
    id: 'eonez3',
    direction: 'incoming',
    text: 'They feel more real now.',
    next: 'eonez4',
  },
  {
    id: 'eonez4',
    direction: 'incoming',
    text:
      "It's funny. Now you know how you might react. And what to expect from others.",
    next: 'eonez5',
  },
  {
    id: 'eonez5',
    direction: 'incoming',
    text:
      'More than two million lives have been lost to this pandemic so far. And counting.',
    next: 'eonez6',
  },
  {
    id: 'eonez6',
    direction: 'incoming',
    text:
      'Although Singapore is under control, it seems any small slip-up could cause another outbreak.',
    next: 'eonez7',
  },
  {
    id: 'eonez7',
    direction: 'incoming',
    text: 'When will it end?',
    next: 'eonez8',
  },
  {
    id: 'eonez8',
    direction: 'incoming',
    text: 'No point thinking about it. Nothing that can be done.',
    next: 'eonez9',
  },
  {
    id: 'eonez9',
    direction: 'incoming',
    text:
      'You feel restless. You need something to take your mind off these things. Maybe a game?',
    externalEvent: 'makegamechoice',
  },
  {
    id: 'eone12',
    direction: 'outgoing',
    auto: true,
    text: 'Images.',
    next: 'eone13',
  },
  {
    id: 'eone13',
    direction: 'incoming',
    text:
      'You saw so many – bodies,!eone14a0 tightly packed or wrapped; artworks!eone14b0 – from past and present.',
  },
  {
    id: 'eone14a0',
    direction: 'outgoing',
    auto: true,
    text: 'Bodies.',
    next: 'eone14a1',
  },
  {
    id: 'eone14a1',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => (
          <img {...props} src={socialdistancing} alt="social distancing" />
        ),
        to: 'eone14aa0',
      },
      {
        image: (props) => (
          <img {...props} src={plasticbody} alt="plastic body" />
        ),
        to: 'eone14ab0',
      },
    ],
  },
  {
    id: 'eone14aa0',
    direction: 'outgoing',
    auto: true,
    image: (props) => (
      <img {...props} src={socialdistancing} alt="social distancing" />
    ),
    next: 'eone14aa1',
  },
  {
    id: 'eone14aa1',
    direction: 'incoming',
    text: 'The image bothers you.',
    next: 'eone14aa2',
  },
  {
    id: 'eone14aa2',
    direction: 'incoming',
    text: 'Maskless people in close contact, fighting for their freedom.',
    next: 'eone14aa3',
  },
  {
    id: 'eone14aa3',
    direction: 'incoming',
    text: 'The fear of exile, of distance, or the lack of freedom is high.',
    next: 'eone14aa4',
  },
  {
    id: 'eone14aa4',
    direction: 'incoming',
    text:
      'You wonder why distance matters so much when the cost of closeness is so high.',
    next: 'eone14aa5',
  },
  {
    id: 'eone14aa5',
    direction: 'incoming',
    text: 'But the streets are filling up, malls are getting busier.',
    next: 'eone14aa6',
  },
  {
    id: 'eone14aa6',
    direction: 'incoming',
    text: "Things are easing up here now, but they aren't elsewhere.",
    next: 'eone14aa7',
  },
  {
    id: 'eone14aa7',
    direction: 'incoming',
    text: 'You just hope this vaccine works out.',
    next: 'eone14aa8',
  },
  {
    id: 'eone14aa8',
    direction: 'incoming',
    text: 'And slowly we can bridge the distance between people and places.',
    next: 'eone14x0',
  },
  {
    id: 'eone14ab0',
    direction: 'outgoing',
    auto: true,

    image: (props) => <img {...props} src={plasticbody} alt="plastic body" />,
    next: 'eone14ab1',
  },
  {
    id: 'eone14ab1',
    direction: 'incoming',
    textStyle: 'italic',
    text: '"A hundred million corpses, broadcast throughout history,',
    next: 'eone14ab2',
  },
  {
    id: 'eone14ab2',
    direction: 'incoming',
    textStyle: 'italic',
    text: 'are no more than a puff of smoke, in the imagination."',
    next: 'eone14ab3',
  },
  {
    id: 'eone14ab3',
    direction: 'incoming',
    text: 'We have been counting bodies daily for ages now.',
    next: 'eone14ab4',
  },
  {
    id: 'eone14ab4',
    direction: 'incoming',
    text: 'People reduced to bodies. Bodies turned to statistics.',
    next: 'eone14ab5',
  },
  {
    id: 'eone14ab5',
    direction: 'incoming',
    text: 'They lose weight, meaning.',
    next: 'eone14ab6',
  },
  {
    id: 'eone14ab6',
    direction: 'incoming',
    text: 'The image is haunting.',
    next: 'eone14ab7',
  },
  {
    id: 'eone14ab7',
    direction: 'incoming',
    text:
      'A modern mummy. Preserved not for the afterlife, but to prevent contamination.',
    next: 'eone14ab8',
  },
  {
    id: 'eone14ab8',
    direction: 'incoming',
    text: 'The distance between us and a disease is a mask and some plastic.',
    next: 'eone14ab9',
  },
  {
    id: 'eone14ab9',
    direction: 'incoming',
    text:
      'Of all things, in Singapore, the pandemic is defined largely by distance.',
    next: 'eone14ab10',
  },
  {
    id: 'eone14ab10',
    direction: 'incoming',
    text: 'Elsewhere, it is also defined by loss.',
    next: 'eone14ab11',
  },
  {
    id: 'eone14ab11',
    direction: 'incoming',
    text: 'Not only of personal freedoms, but of life.',
    next: 'eone14x0',
  },
  {
    id: 'eone14b0',
    direction: 'outgoing',
    auto: true,
    text: 'Artworks.',
    next: 'eone14b1',
  },
  {
    id: 'eone14b1',
    direction: 'incoming',
    options: [
      {
        image: (props) => (
          <img {...props} src={thetriumphofdeath} alt="the triumph of death" />
        ),
        to: 'eone14ba0',
      },
      {
        image: (props) => <img {...props} src={finland2} alt="finland 2" />,
        to: 'eone14bb0',
      },
    ],
  },
  {
    id: 'eone14ba0',
    direction: 'outgoing',
    auto: true,
    image: (props) => (
      <img {...props} src={thetriumphofdeath} alt="the triumph of death" />
    ),
    next: 'eone14ba1',
  },
  {
    id: 'eone14ba1',
    direction: 'incoming',
    textWeight: 'bold',
    text: 'The Triumph of Death, Pieter Bruegel the Elder, 1562-1563.',
    next: 'eone14ba2',
  },
  {
    id: 'eone14ba2',
    direction: 'incoming',
    text: 'Fire burning in the distance, mess of bodies in the front.',
    next: 'eone14ba3',
  },
  {
    id: 'eone14ba3',
    direction: 'incoming',
    text: 'You spot … skeletons?',
    next: 'eone14ba4',
  },
  {
    id: 'eone14ba4',
    direction: 'incoming',
    text: 'They are causing quite a mess.',
    next: 'eone14ba5',
  },
  {
    id: 'eone14ba5',
    direction: 'incoming',
    text: 'Death leads this skeletal army, destroying earth.',
    next: 'eone14ba6',
  },
  {
    id: 'eone14ba6',
    direction: 'incoming',
    text: 'No god, no king has power here.',
    next: 'eone14ba7',
  },
  {
    id: 'eone14ba7',
    direction: 'incoming',
    text: 'See that patch of green at the very edge, towards the right?',
    next: 'eone14ba8',
  },
  {
    id: 'eone14ba8',
    direction: 'incoming',
    text: 'Lovers in their own world.',
    next: 'eone14ba9',
  },
  {
    id: 'eone14ba9',
    direction: 'incoming',
    text: 'Is his music loud enough to drown out the sounds of destruction?',
    next: 'eone14ba10',
  },
  {
    id: 'eone14ba10',
    direction: 'incoming',
    text: 'Is this moment of bliss worth it?',
    next: 'eone14ba11',
  },
  {
    id: 'eone14ba11',
    direction: 'incoming',
    text: 'This painting is chaotic. Human life is fragile.',
    next: 'eone14ba12',
  },
  {
    id: 'eone14ba12',
    direction: 'incoming',
    text: 'Even lovers in a bubble will soon be swallowed by the chaos.',
    next: 'eone14x0',
  },
  {
    id: 'eone14bb0',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={finland2} alt="finland 2" />,
    next: 'eone14bb1',
  },
  {
    id: 'eone14bb1',
    direction: 'incoming',
    textWeight: 'bold',
    text: 'Finland 2 (Helsinki City Art Museum), Spencer Tunick, 2002.',
    next: 'eone14bb2',
  },
  {
    id: 'eone14bb2',
    direction: 'incoming',
    text: 'Flesh blends into flesh.',
    next: 'eone14bb3',
  },
  {
    id: 'eone14bb3',
    direction: 'incoming',
    text: 'Bodies become one large mass. Or a wave sweeping through a space.',
    next: 'eone14bb4',
  },
  {
    id: 'eone14bb4',
    direction: 'incoming',
    text:
      'Tunick says his work is a form of protest. It attempts to destigmatise nudity.',
    next: 'eone14bb5',
  },
  {
    id: 'eone14bb5',
    direction: 'incoming',
    text:
      'He wants to encourage “respect [for] the human body as an art form.”',
    next: 'eone14bb6',
  },
  {
    id: 'eone14bb6',
    direction: 'incoming',
    text:
      'Here, used in tandem with Camus’ harrowing words, it reads differently.',
    next: 'eone14bb7',
  },
  {
    id: 'eone14bb7',
    direction: 'incoming',
    textStyle: 'italic',
    text:
      '“Ten thousand dead, made about five times the audience, in a biggish cinema.',
    next: 'eone14bb8',
  },
  {
    id: 'eone14bb8',
    direction: 'incoming',
    textStyle: 'italic',
    text: 'Yes, that was how it should be done.',
    next: 'eone14bb9',
  },
  {
    id: 'eone14bb9',
    direction: 'incoming',
    textStyle: 'italic',
    text:
      'You should collect the people, at the exits of five picture houses, you should lead them to a city square, and make them die in heaps,',
    next: 'eone14bb10',
  },
  {
    id: 'eone14bb10',
    direction: 'incoming',
    textStyle: 'italic',
    text: 'if you wanted to get a clear notion of what it means."',
    next: 'eone14bb11',
  },
  {
    id: 'eone14bb11',
    direction: 'incoming',
    text: 'Bodies are less abstract than numbers. They take up space.',
    next: 'eone14x0',
  },
  {
    id: 'eone14x0',
    direction: 'incoming',
    text: 'You...',
    options: [
      { text: 'Want to know more.', to: 'eone14x1' },
      { text: 'Want to stop. Enough reading.', to: 'eonez0' },
    ],
  },
  {
    id: 'eone14x1',
    direction: 'outgoing',
    auto: true,
    text: 'Want to know more.',
    next: 'eone14x2',
  },
  {
    id: 'eone14x2',
    direction: 'incoming',
    text: [
      'Go back to the images.!eone12',
      'OR',
      'The words!eone9 linger in your mind.',
    ],
  },
  {
    id: 'eone15',
    direction: 'outgoing',
    auto: true,
    text: 'All.',
    next: 'eone16',
  },
  {
    id: 'eone16',
    direction: 'incoming',
    text:
      'The artwork swims in your head, there was so much to pay attention to.',
    next: 'eone17',
  },
  {
    id: 'eone17',
    direction: 'incoming',
    text:
      'Some of it floated by too fast, other parts lingered a little too long, making you uneasy.',
    next: 'eone18',
  },
  {
    id: 'eone18',
    direction: 'incoming',
    text: 'It almost felt like...',
    options: [
      { text: 'A lecture.', to: 'eone19a0' },
      { text: 'A story.', to: 'eone19b0' },
      { text: 'The news.', to: 'eone19c0' },
    ],
  },
  {
    id: 'eone19a0',
    direction: 'outgoing',
    auto: true,
    text: 'A lecture.',
    next: 'eone19a1',
  },
  {
    id: 'eone19a1',
    direction: 'incoming',
    text:
      'You don’t think any of your professors would have presented something like this though.',
    next: 'eone19a2',
  },
  {
    id: 'eone19a2',
    direction: 'incoming',
    text:
      'It felt like a strange documentary. The narrator was providing tidbits of information, but was also rather performative.',
    next: 'eone19a3',
  },
  {
    id: 'eone19a3',
    direction: 'incoming',
    text:
      'Nevertheless, it forced you to consider the ways in which real and fictional, past and present accounts of the pandemic overlap.',
    next: 'eone19a4',
  },
  {
    id: 'eone19a4',
    direction: 'incoming',
    text: '...',
    next: 'eone19a5',
  },
  {
    id: 'eone19a5',
    direction: 'incoming',
    text: 'You want to unpack the work further.',
    next: 'eone19a6',
  },
  {
    id: 'eone19a6',
    direction: 'incoming',
    text:
      'It might be easier to consider one element first. And then slowly make sense of the rest from there.',
    next: 'eone19x',
  },
  {
    id: 'eone19b0',
    direction: 'outgoing',
    auto: true,
    text: 'A story.',
    next: 'eone19b1',
  },
  {
    id: 'eone19b1',
    direction: 'incoming',
    text:
      'You knew that the work was based on Camus’ book, that it was fiction.',
    next: 'eone19b2',
  },
  {
    id: 'eone19b2',
    direction: 'incoming',
    text: 'But it was so close to reality that it surprised you.',
    next: 'eone19b3',
  },
  {
    id: 'eone19b3',
    direction: 'incoming',
    text:
      'Throughout the work, you remembered things you’d seen happen in real life.',
    next: 'eone19b4',
  },
  {
    id: 'eone19b4',
    direction: 'incoming',
    text:
      'An image chosen by the artist - hotel room, newspaper article, would prompt a memory of recent events that were eerily close to Camus’ account.',
    next: 'eone19b5',
  },
  {
    id: 'eone19b5',
    direction: 'incoming',
    text:
      'You’re trying to untangle the relationship between all different elements of the work.',
    next: 'eone19b6',
  },
  {
    id: 'eone19b6',
    direction: 'incoming',
    text: 'It would help to focus on one first.',
    next: 'eone19x',
  },
  {
    id: 'eone19c0',
    direction: 'outgoing',
    auto: true,
    text: 'The news.',
    next: 'eone19c1',
  },
  {
    id: 'eone19c1',
    direction: 'incoming',
    text:
      'A story close to reality narrated by a calm, steady voice, accompanied with plenty of images and headlines.',
    next: 'eone19c2',
  },
  {
    id: 'eone19c2',
    direction: 'incoming',
    text: 'This almost felt like the news.',
    next: 'eone19c3',
  },
  {
    id: 'eone19c3',
    direction: 'incoming',
    text: 'It also reminded you a bit of your feed.',
    next: 'eone19c4',
  },
  {
    id: 'eone19c4',
    direction: 'incoming',
    text:
      'A mash-up of content from various sources. A story of the 2020 pandemic that borrows from past and present.',
    next: 'eone19c5',
  },
  {
    id: 'eone19c5',
    direction: 'incoming',
    text: 'You want to make sense of it.',
    next: 'eone19x',
  },
  {
    id: 'eone19x',
    direction: 'incoming',
    text: 'Start with the...',
    options: [
      { text: 'Words.', to: 'eone9' },
      { text: 'Images.', to: 'eone12' },
    ],
  },
  {
    id: 'eone20',
    direction: 'incoming',
    text: "You've...",
    options: [
      { text: 'Spent hundreds of hours on this game.', to: 'eone21a0' },
      {
        text: 'Always wished you could replay it for the first time again.',
        to: 'eone21b0',
      },
    ],
  },
  {
    id: 'eone21a0',
    direction: 'outgoing',
    auto: true,
    text: 'Spent hundreds of hours on this game.',
    next: 'eone21a1',
  },
  {
    id: 'eone21a1',
    direction: 'incoming',
    text: 'You can do a lot in Skyrim.',
    next: 'eone21a2',
  },
  {
    id: 'eone21a2',
    direction: 'incoming',
    text:
      "Since it came out in 2011, whenever you've had a bad day, it's where you go.",
    next: 'eone21a3',
  },
  {
    id: 'eone21a3',
    direction: 'incoming',
    text: 'You can do whatever you want in the game.',
    next: 'eone21a4',
  },
  {
    id: 'eone21a4',
    direction: 'incoming',
    text:
      'Climb mountains, explore caves and forests... and kill all the townsfolk.',
    next: 'eone21a5',
  },
  {
    id: 'eone21a5',
    direction: 'incoming',
    text: "It's pretty therapeutic.",
    externalEvent: 'opendeniseyap',
  },
  {
    id: 'eone21b0',
    direction: 'outgoing',
    auto: true,
    text: 'Always wished you could replay it for the first time again.',
    next: 'eone21b1',
  },
  {
    id: 'eone21b1',
    direction: 'incoming',
    text: 'You remember experiencing the game for the first time in 2011.',
    next: 'eone21b2',
  },
  {
    id: 'eone21b2',
    direction: 'incoming',
    text: 'The steep cliffs, lakes, forests, dragons and sprawling plains.',
    next: 'eone21b3',
  },
  {
    id: 'eone21b3',
    direction: 'incoming',
    text: 'You could pick a direction and keep running and running.',
    next: 'eone21b4',
  },
  {
    id: 'eone21b4',
    direction: 'incoming',
    text: 'You could decimate towns and kill as many people as you wanted.',
    next: 'eone21b5',
  },
  {
    id: 'eone21b5',
    direction: 'incoming',
    text:
      "There's comfort in riding through the same places over and over again.",
    next: 'eone21b6',
  },
  {
    id: 'eone21b6',
    direction: 'incoming',
    text:
      'But sometimes you dream of experiencing the magic of exploring Skyrim for the first time again.',
    externalEvent: 'opendeniseyap',
  },
  {
    id: 'eone22',
    direction: 'incoming',
    auto: true,
    text:
      'Well, that was a lot of exploring and wandering. Very little fighting...',
    next: 'eone23',
    externalEvent: 'curationmusic',
  },
  {
    id: 'eone23',
    direction: 'incoming',
    text: "And also, you didn't play the game!",
    next: 'eone24',
  },
  {
    id: 'eone24',
    direction: 'incoming',
    text:
      'Instead, you watched someone else play. Differently. Very differently to you.',
    next: 'eone25',
  },
  {
    id: 'eone25',
    direction: 'incoming',
    text: "You're trying to make sense of what you just saw.",
    next: 'eone26',
  },
  {
    id: 'eone26',
    direction: 'incoming',
    text: 'Was it...',
    options: [
      { text: 'A video game playthrough?', to: 'eone27a0' },
      { text: 'Something else?', to: 'eone27b0' },
    ],
  },
  {
    id: 'eone27a0',
    direction: 'outgoing',
    auto: true,
    text: 'A video game playthrough.',
    next: 'eone27a1',
  },
  {
    id: 'eone27a1',
    direction: 'incoming',
    text:
      "It was a bit like an out of body experience. You kept wanting to play the game, but couldn't.",
    next: 'eone27a2',
  },
  {
    id: 'eone27a2',
    direction: 'incoming',
    text:
      'You just watched someone else go through the motions. Familiar, but different.',
    next: 'eone27a3',
  },
  {
    id: 'eone27a3',
    direction: 'incoming',
    text:
      'At moments, you caught yourself calling out to them, wanting them to make a different choice.',
    next: 'eone27a4',
  },
  {
    id: 'eone27a4',
    direction: 'incoming',
    text:
      'People have different playstyles, you know that, obviously. But you hadn’t realised someone could play Skyrim so peacefully.',
    next: 'eone27a5',
  },
  {
    id: 'eone27a5',
    direction: 'incoming',
    text: 'It made you look at the game in an entirely different way.',
    next: 'eone27a6',
  },
  {
    id: 'eone27a6',
    direction: 'incoming',
    text:
      "For once, you weren't paying attention to the grand story and completing a million quests, but looking at...",
    options: [
      { text: 'The grand world around you.', to: 'eone28a0' },
      { text: 'The smallest of details.', to: 'eone28b0' },
    ],
  },
  {
    id: 'eone27b0',
    direction: 'outgoing',
    auto: true,
    text: 'Something else.',
    next: 'eone27b1',
  },
  {
    id: 'eone27b1',
    direction: 'incoming',
    text: 'It confuses you because you consider yourself a bit of a gamer.',
    next: 'eone27b2',
  },
  {
    id: 'eone27b2',
    direction: 'incoming',
    text: 'You know stuff like this exists.',
    next: 'eone27b3',
  },
  {
    id: 'eone27b3',
    direction: 'incoming',
    text:
      'Videos in which people play games and create tutorials. They showcase their skills, or fool around.',
    next: 'eone27b4',
  },
  {
    id: 'eone27b4',
    direction: 'incoming',
    text: 'But this was different.',
    next: 'eone27b5',
  },
  {
    id: 'eone27b5',
    direction: 'incoming',
    text:
      "But maybe, you shouldn't be asking yourself what it was, but how it made you feel?",
    next: 'eone27b6',
  },
  {
    id: 'eone27b6',
    direction: 'incoming',
    text: 'Hm.',
    next: 'eone27b7',
  },
  {
    id: 'eone27b7',
    direction: 'incoming',
    text:
      'Well, for once, instead of chasing a victory or a story, you found yourself looking at...',
    options: [
      { text: 'The grand world around you.', to: 'eone28a0' },
      { text: 'The smallest of details.', to: 'eone28b0' },
    ],
  },
  {
    id: 'eone28a0',
    direction: 'outgoing',
    auto: true,
    text: 'The grand world around you.',
    next: 'eone28a1',
  },
  {
    id: 'eone28a1',
    direction: 'incoming',
    text: 'Flan spent a lot of time walking.',
    next: 'eone28a2',
  },
  {
    id: 'eone28a2',
    direction: 'incoming',
    text:
      'At first, your eyes followed them. But soon, you started looking at everything else.',
    next: 'eone28a3',
  },
  {
    id: 'eone28a3',
    direction: 'incoming',
    image: (props) => <img {...props} src={grandworld1} alt="" />,
    next: 'eone28a4',
  },
  {
    id: 'eone28a4',
    direction: 'incoming',
    image: (props) => <img {...props} src={grandworld2} alt="" />,
    next: 'eone28a5',
  },
  {
    id: 'eone28a5',
    direction: 'incoming',
    image: (props) => <img {...props} src={grandworld3} alt="" />,
    next: 'eone28a6',
  },
  {
    id: 'eone28a6',
    direction: 'incoming',
    text:
      'You could take in the sights because Flan always kept a slow and steady pace.',
    next: 'eone28a7',
  },
  {
    id: 'eone28a7',
    direction: 'incoming',
    text: 'Even when the creepy creatures came crawling...',
    next: 'eone28a8',
  },
  {
    id: 'eone28a8',
    direction: 'incoming',
    text: "You don't like spiders. You'd have crushed them in an instant.",
    next: 'eone28a9',
  },
  {
    id: 'eone28a9',
    direction: 'incoming',
    text:
      'But Flan simply cast a spell, turned the creatures ethereal and walked away.',
    next: 'eone28a10',
  },
  {
    id: 'eone28a10',
    direction: 'incoming',
    text:
      "This made you laugh a little. How absurd to play a game which gives you supernatural-like abilities, and choose only to 'bump into' enemies and slowly walk away.",
    next: 'eone28a11',
  },
  {
    id: 'eone28a11',
    direction: 'incoming',

    image: (props) => <img {...props} src={ethereal1} alt="respite" />,
    next: 'eone28a12',
  },
  {
    id: 'eone28a12',
    direction: 'incoming',
    image: (props) => <img {...props} src={ethereal2} alt="respite" />,
    next: 'eone28a13',
  },
  {
    id: 'eone28a13',
    direction: 'incoming',
    image: (props) => <img {...props} src={ethereal3} alt="respite" />,
    next: 'eone28a17', // deletion of previous nodes
  },
  {
    id: 'eone28a17',
    direction: 'incoming',
    text:
      'In another video, Flan built a house. Again, slowly and steadily. In the last one, Flan slept. The sound of his sleeping was weirdly calming.',
    next: 'eone28a18',
  },
  {
    id: 'eone28a18',
    direction: 'incoming',
    text:
      'Time always passes quickly when you play games. But in these videos, it never speeds up, only slows down.',
    next: 'eone28a19',
  },
  {
    id: 'eone28a19',
    direction: 'incoming',
    text: 'When the pandemic first started, it felt like life came to a halt.',
    next: 'eone28a20',
  },
  {
    id: 'eone28a20',
    direction: 'incoming',
    text:
      'And once people got used to it, they were either finding ways to occupy themselves with increasingly absurd ways of killing time. Or trying to hold on to the slower pace of life. ',
    next: 'eone28a21',
  },
  {
    id: 'eone28a21',
    direction: 'incoming',
    text:
      'The desire to figure out what to do, how to be … the anxious feeling of just making sense of a world that seems increasingly complicated day by day … that still remains. ',
    next: 'eone28a22',
  },
  {
    id: 'eone28a22',
    direction: 'incoming',
    text: 'But for a moment, it all quiets down.',
    next: 'eone28a23',
  },
  {
    id: 'eone28a23',
    direction: 'incoming',
    text:
      'You just watch Flan move through a beautiful world. Hear the sounds of water, birds, breathing.',
    next: 'eone28a24',
  },
  {
    id: 'eone28a24',
    direction: 'incoming',
    text: 'You appreciate that you were given a moment of respite.',
    next: 'eone28a25',
  },
  {
    id: 'eone28a25',
    direction: 'incoming',
    image: (props) => <img {...props} src={respite1} alt="respite" />,
    next: 'eone29',
  },
  {
    id: 'eone28b0',
    direction: 'outgoing',
    auto: true,
    text: 'The smallest of details.',
    next: 'eone28b1',
  },
  {
    id: 'eone28b1',
    direction: 'incoming',
    text: 'Flan spent a lot of time walking. Pausing. Zooming in.',
    next: 'eone28b2',
  },
  {
    id: 'eone28b2',
    direction: 'incoming',
    text:
      "You've never noticed the textures of Skyrim so closely before. Or maybe you have, but forgot.",
    next: 'eone28b3',
  },
  {
    id: 'eone28b3',
    direction: 'incoming',
    image: (props) => <img {...props} src={detail1} alt="" />,
    next: 'eone28b4',
  },
  {
    id: 'eone28b4',
    direction: 'incoming',
    image: (props) => <img {...props} src={detail2} alt="" />,
    next: 'eone28b5',
  },
  {
    id: 'eone28b5',
    direction: 'incoming',
    image: (props) => <img {...props} src={detail3} alt="" />,
    next: 'eone28b6',
  },
  {
    id: 'eone28b6',
    direction: 'incoming',
    text:
      'The world building was spectacular. There was a lot to see, to hear. But there was always a lot to do as well. ',
    next: 'eone28b7',
  },
  {
    id: 'eone28b7',
    direction: 'incoming',
    text:
      'When you play Skyrim, you were always intent on completing quests or getting the best gear for your multiple characters. No time to slow down and enjoy the world itself. ',
    next: 'eone28b8',
  },
  {
    id: 'eone28b8',
    direction: 'incoming',
    text: 'A bit like life sometimes.',
    next: 'eone28b9',
  },
  {
    id: 'eone28b9',
    direction: 'incoming',
    text: 'The only time you stopped to smell the roses was on holidays.',
    next: 'eone28b10',
  },
  {
    id: 'eone28b10',
    direction: 'incoming',
    text:
      'This particular journey into the Skyrim world did take on the leisurely pace of a holiday.',
    next: 'eone28b11',
  },
  {
    id: 'eone28b11',
    direction: 'incoming',
    text: 'Just you, Flan (your proxy) and the world around you.',
    next: 'eone28b12',
  },
  {
    id: 'eone28b12',
    direction: 'incoming',
    text:
      'It helped that Skyrim felt so different from Singapore. A world with more than 2 seasons and vast, varied landscapes.',
    next: 'eone28b13',
  },
  {
    id: 'eone28b13',
    direction: 'incoming',
    text:
      'Via Flan, you walked from town to town. Built a home. Took a trip to the forge.',
    next: 'eone28b14',
  },
  {
    id: 'eone28b14',
    direction: 'incoming',
    text: 'All at a peaceful pace.',
    next: 'eone28b15',
  },
  {
    id: 'eone28b15',
    direction: 'incoming',
    image: (props) => <img {...props} src={holiday1} alt="" />,
    next: 'eone28b16',
  },
  {
    id: 'eone28b16',
    direction: 'incoming',
    image: (props) => <img {...props} src={holiday2} alt="" />,
    next: 'eone28b17',
  },
  {
    id: 'eone28b17',
    direction: 'incoming',
    image: (props) => <img {...props} src={holiday3} alt="" />,
    next: 'eone28b18',
  },
  {
    id: 'eone28b18',
    direction: 'incoming',
    text:
      'For a while, you lost yourself in that world. Watching flames flicker, steam dance through the air, water wall, a shield rotate.',
    next: 'eone28b19',
  },
  {
    id: 'eone28b19',
    direction: 'incoming',
    text:
      "Now, after it's over, you appreciate that you were given a moment of respite.",
    next: 'eone28a25',
  },
  {
    id: 'eone29',
    direction: 'incoming',
    text:
      'You return to the question of what exactly you had just experienced.',
    next: 'eone30',
  },
  {
    id: 'eone30',
    direction: 'incoming',
    text:
      'You imagine it could have been a video on YouTube or a livestream on Twitch.',
    next: 'eone31',
  },
  {
    id: 'eone31',
    direction: 'incoming',
    text:
      'Or projected in a gallery perhaps, with a little label to explain it.',
    next: 'eone32',
  },
  {
    id: 'eone32',
    direction: 'incoming',
    text:
      'You think about how these game videos could be like an improv performance. Someone choosing a new and unexpected way to approach a familiar world.',
    next: 'eone33',
  },
  {
    id: 'eone33',
    direction: 'incoming',
    text:
      'Then you think of some artworks you’ve seen that respond to or repurpose content from video games. Old material in new contexts. ',
    next: 'eone34',
  },
  {
    id: 'eone34',
    direction: 'incoming',
    text:
      "You decide it doesn't matter whether it's a gaming video or art. What matters is how it made you feel.",
    next: 'eone35',
  },
  {
    id: 'eone35',
    direction: 'incoming',
    text: 'You...',
    options: [
      { text: 'Like the intimacy of the experience.', to: 'eone36a0' },
      { text: 'Would prefer a little more action.', to: 'eone36b0' },
    ],
  },
  {
    id: 'eone36a0',
    direction: 'outgoing',
    auto: true,
    text: 'Like the intimacy of the experience.',
    next: 'eone36a1',
  },
  {
    id: 'eone36a1',
    direction: 'incoming',
    text: 'Watching the video from home. In your own time, at a slower pace.',
    next: 'eone36a2',
  },
  {
    id: 'eone36a2',
    direction: 'incoming',
    text: 'You enjoyed watching someone else play for a change.',
    next: 'eone36a3',
  },
  {
    id: 'eone36a3',
    direction: 'incoming',
    text:
      'The work offered quiet company. Similar to the kind of food ASMR videos you watch when you need to wind down.',
    next: 'eone36a4',
  },
  {
    id: 'eone36a4',
    direction: 'incoming',
    text: 'It’s one way to leave your worries behind for a little while.',
    next: 'eone37',
  },
  {
    id: 'eone36b0',
    direction: 'outgoing',
    auto: true,
    text: 'Would prefer a little more action.',
    next: 'eone36b1',
  },
  {
    id: 'eone36b1',
    direction: 'incoming',
    text: 'You would have loved to play the game yourself.',
    next: 'eone36b2',
  },
  {
    id: 'eone36b2',
    direction: 'incoming',
    text:
      'You enjoy practising and perfecting your skills. A good headshot, a well-executed stealth kill, these things bring you joy.',
    next: 'eone36b3',
  },
  {
    id: 'eone36b3',
    direction: 'incoming',
    text:
      'And, you can spend minutes or hours focusing on that, instead of your crowding thoughts.',
    next: 'eone36b4',
  },
  {
    id: 'eone36b4',
    direction: 'incoming',
    text: 'You find some peace in the draw of a bow.',
    next: 'eone37',
  },
  {
    id: 'eone37',
    direction: 'incoming',
    text: 'Today was a lot.',
    externalEvent: 'bgreset',
    next: 'eone38',
  },
  {
    id: 'eone38',
    direction: 'incoming',
    text:
      'Everything going on, it all still feels like a dream you have yet to wake from.',
    next: 'eone39',
  },
  {
    id: 'eone39',
    direction: 'incoming',
    text:
      'The news on the pandemic comes and goes in waves. You don’t know what will wash up next, if it will be good or bad.',
    next: 'eone40',
  },
  {
    id: 'eone40',
    direction: 'incoming',
    text: '...',
    next: 'eone41',
  },
  {
    id: 'eone41',
    direction: 'incoming',
    text: 'Time to sleep. But are you even awake?',
    next: 'eone42',
  },
  {
    id: 'eone42',
    direction: 'incoming',
    text: 'You’re tired. It’s time to lay down and rest.',
    next: 'eone43',
  },
  {
    id: 'eone43',
    direction: 'incoming',
    text: 'You set your alarm.',
    externalEvent: 'eoneend',
  },
]

export default content
