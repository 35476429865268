import Slide, { SlideText } from '../Slide'
import tv from '../../assets/img/prologue-tv.png'
import tvNegative from '../../assets/img/prologue-tv-negative.png'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

const StyledImg = styled.img`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 60vh;
`
const StyledMask = styled.img.attrs((props) => {
  let clipPath = ''
  for (let i = 0; i <= 10; i++) {
    clipPath += `
      ${(props.offset || 0) - 1000 + i * 350}px 0,
      ${(props.offset || 0) - 1000 - 200 + i * 350}px 100%,
      ${(props.offset || 0) - 1000 + i * 350}px 100%,
      ${(props.offset || 0) - 1000 + 200 + i * 350}px 0${i === 10 ? '' : ','}`
  }
  return {
    style: {
      clipPath: `polygon(${clipPath})`,
    },
  }
})`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 60vh;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Scene1 = ({ slideId }) => {
  const [offset, setOffset] = useState(0)
  useEffect(
    () =>
      setInterval(() => {
        setOffset((prev) => (prev < 350 * 3 ? prev + 0.5 : 0))
      }, 16),
    [],
  )

  if (slideId !== 4) return null
  return (
    <Slide>
      <Content>
        <StyledImg src={tv} />
        <StyledMask offset={offset} src={tvNegative} />
      </Content>
      <SlideText>
        If you sleep longer, maybe you'll wake up when the nightmare is over.
      </SlideText>
    </Slide>
  )
}

export default Scene1
