import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import Button from './Button'

const TextContent = styled.div`
  background: linear-gradient(
    0deg,
    rgba(43, 50, 255, 1) 0%,
    rgba(179, 181, 255, 1) 100%
  );
  border-radius: 3px;
  font-family: BarlowRegular;
  height: fit-content;
  font-size: 1.5em;
  margin: 5px 1em;
  padding: 0.5em 1em;
  width: 300px;
  text-align: left;
  font-style: ${(props) => props.textStyle || 'normal'};
  font-weight: ${(props) => props.textWeight || 'normal'};

  ${(props) =>
    props.direction === 'outgoing'
      ? css`
          background: linear-gradient(
            0deg,
            rgba(255, 0, 225, 1) 0%,
            rgba(255, 162, 241, 1) 100%
          );
          transform: translateX(100px);

          animation: fadeInOutgoing ease 0.5s;
          @keyframes fadeInOutgoing {
            0% {
              transform: translate(100px, 20px);
              opacity: 0;
            }
            100% {
              tranform: translate(100px);
              opacity: 1;
            }
          }
        `
      : css`
          animation: fadeIn ease 0.5s;
          @keyframes fadeIn {
            0% {
              transform: translate(0, 20px);
              opacity: 0;
            }
            100% {
              tranform: translate(0);
              opacity: 1;
            }
          }
        `}
`

const InlineButton = styled.button`
  background: white;
  border-radius: 8px;
  color: black;
  font-family: BarlowRegular;
  font-size: 1em;
  margin: 0 5px;
  padding: 5px;
  border: 3px solid ${(props) => props.theme.colors.cello};
  text-align: left;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.cello};
    color: white;
    border: 3px solid white;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &:focus {
    outline: none;
  }
`

const Text = ({ children, direction = 'incoming', textStyle, textWeight }) => {
  return (
    <TextContent
      textWeight={textWeight}
      textStyle={textStyle}
      direction={direction}
    >
      {children}
    </TextContent>
  )
}

const TextContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px;
  height: 800px;
  max-height: 75vh;
  overflow: hidden;
`

const NextIcon = styled.div`
  width: 100%;
  text-align: right;
  cursor: pointer;

  animation: bob 1s ease 0s infinite alternate;
  animation-duration: infinite;
  @keyframes bob {
    0% {
      transform: translate(0, 4px);
    }
    100% {
      tranform: translate(0);
    }
  }
`

const TextContainer = forwardRef(
  ({ triggerEvent, contentObj, finish }, ref) => {
    // FOR DEBUGGING PURPOSES
    // const [content, setContent] = useState([
    //   contentObj.find((node) => node.id === 'eone18'),
    // ])

    const [shownContent, setShownContent] = useState([contentObj[0]])

    const textsEndRef = useRef(null)

    const scrollToBottom = () => {
      try {
        textsEndRef.current.scrollIntoView({ behavior: 'smooth' })
      } catch (e) {
        //
      }
    }

    useImperativeHandle(ref, () => ({
      addNode,
      reset: () => setShownContent([contentObj[0]]),
    }))

    useEffect(() => setTimeout(scrollToBottom, 50), [shownContent])

    useEffect(() => {
      window.addEventListener('resize', () => {
        scrollToBottom()
      })
    }, [])

    const addNode = useCallback(
      (id) => {
        const newNode = contentObj.find((node) => node.id === id)
        if (!newNode) return setShownContent([contentObj[0]])
        if (newNode.auto) {
          const nextNode = contentObj.find((node) => node.id === newNode.next)
          setShownContent((prev) => [...prev, newNode])
          setTimeout(() => {
            setShownContent((prev) => [...prev, nextNode])
          }, 800)
        } else {
          setShownContent((prev) =>
            newNode.clear ? [newNode] : [...prev, newNode],
          )
        }
        if (newNode.externalEvent) {
          if (Array.isArray(newNode.externalEvent)) {
            newNode.externalEvent.forEach(triggerEvent)
          } else {
            triggerEvent(newNode.externalEvent)
          }
        }
      },
      [triggerEvent, contentObj],
    )

    const checkWord = (word, isLastNode) => {
      const separate = word.split('!')
      const isInternalLink = separate.length !== 1 && separate[1] !== ''

      const linkSeparate = word.split('#')
      const isExternalLink = linkSeparate.length !== 1 && linkSeparate[1] !== ''

      if (!isInternalLink && !isExternalLink) return `${word} `

      if (isExternalLink)
        return (
          <a href={linkSeparate[1]} target="_blank" rel="noreferrer">
            <InlineButton key={word}>{linkSeparate[0]}</InlineButton>
          </a>
        )

      return (
        <InlineButton
          key={word}
          onClick={() => {
            if (isLastNode) addNode(separate[1])
          }}
        >
          {separate[0]}
        </InlineButton>
      )
    }

    const checkSentence = (sentence, isLastNode) =>
      sentence
        ? sentence.split(' ').map((word) => checkWord(word, isLastNode))
        : sentence

    return (
      <TextContainerDiv>
        {shownContent.map(
          (
            {
              id,
              text,
              direction,
              options,
              next,
              auto,
              textStyle,
              textWeight,
              image,
            },
            i,
          ) => {
            const isLastNode = i + 1 === shownContent.length
            const StyledImage = image
              ? styled(image)`
                  width: 100%;
                `
              : null
            return (
              <Text
                key={id}
                direction={direction}
                textStyle={textStyle}
                textWeight={textWeight}
              >
                {image && <StyledImage onLoad={scrollToBottom} />}
                {Array.isArray(text)
                  ? text.map((line) => (
                      <p key={line}>{checkSentence(line, isLastNode)}</p>
                    ))
                  : checkSentence(text, isLastNode)}
                <div>
                  {options &&
                    options.map(({ text, image, to }) => {
                      const ImageOption = image
                        ? styled(image)`
                            width: 100%;
                          `
                        : null

                      return (
                        <Button
                          direction={direction}
                          key={text}
                          onClick={() => {
                            if (isLastNode) addNode(to)
                          }}
                        >
                          {image ? (
                            <ImageOption onLoad={scrollToBottom} />
                          ) : (
                            text
                          )}
                        </Button>
                      )
                    })}
                </div>

                {!options && isLastNode && next && !auto && (
                  <NextIcon
                    onClick={() => {
                      typeof next === 'function'
                        ? addNode(next(finish))
                        : addNode(next)
                    }}
                  >
                    ▼
                  </NextIcon>
                )}
              </Text>
            )
          },
        )}
        <div ref={textsEndRef} />
      </TextContainerDiv>
    )
  },
)

export default Text
export { Text, TextContainer }
