import bernygroup1 from '../assets/img/ep3/bernygroup1.jpg'
import bernygroup2 from '../assets/img/ep3/bernygroup2.jpg'
import bernygroup3 from '../assets/img/ep3/bernygroup3.jpg'
import bernygroup1a from '../assets/img/ep3/bernygroup1a.jpg'
import bernygroup1b from '../assets/img/ep3/bernygroup1b.jpg'
import bernygroup2a from '../assets/img/ep3/bernygroup2a.jpg'
import bernygroup2b from '../assets/img/ep3/bernygroup2b.jpg'
import bernygroup3a from '../assets/img/ep3/bernygroup3a.jpg'
import bernygroup3b from '../assets/img/ep3/bernygroup3b.jpg'
import keepingdistance from '../assets/img/ep3/01_KeepingDistance.jpg'
import intimacy from '../assets/img/ep3/12_Intimacy.jpg'
import alovestory2 from '../assets/img/ep3/11_ALoveStoryII.jpg'
import alovestory1 from '../assets/img/ep3/06_ALoveStoryI.jpg'
import touch from '../assets/img/ep3/02_Touch.jpg'
import thebox from '../assets/img/ep3/04_TheBox.jpg'
import thequeue from '../assets/img/ep3/07_TheQueue.jpg'
import trustfall from '../assets/img/ep3/09_TrustFall.jpg'
import confessions from '../assets/img/ep3/05_Confessions.jpg'
import bestrong from '../assets/img/ep3/10_BeStrong.jpg'
import atarmslength2 from '../assets/img/ep3/08_AtArmsLengthII.jpg'
import atarmslength1a from '../assets/img/ep3/03_AtArmsLengthI_a.jpg'
import lovelockdownsubhas from '../assets/img/ep3/lovelockdownsubhas.jpeg'
import lovelockdowntim from '../assets/img/ep3/lovelockdowntim.jpeg'

const content = [
  {
    id: 'ethree1',
    direction: 'incoming',
    text: 'You just woke up after a late night out with friends.',
    next: 'ethree2',
  },
  {
    id: 'ethree2',
    direction: 'incoming',
    text: 'It was nice to catch up with them and hear about their lives.',
    next: 'ethree3',
  },
  {
    id: 'ethree3',
    direction: 'incoming',
    text:
      'One of them was sharing his woes with the BTO system, another just got promoted, and the other finally proposed to his girlfriend (with great success).',
    next: 'ethree4',
  },
  {
    id: 'ethree4',
    direction: 'incoming',
    text: 'But when it came to you, there wasn’t anything to update.',
    next: 'ethree5',
  },
  {
    id: 'ethree5',
    direction: 'incoming',
    text: '...',
    next: 'ethree6',
  },
  {
    id: 'ethree6',
    direction: 'incoming',
    text: 'It’s raining. You decide to stay home.',
    next: 'ethree7',
  },
  {
    id: 'ethree7',
    direction: 'incoming',
    text: 'You could ...',
    options: [
      { text: 'Decorate your room.', to: 'ethree8' },
      { text: 'Put on some music.', to: 'ethree33' },
    ],
  },
  {
    id: 'ethree8',
    direction: 'outgoing',
    auto: true,
    text: 'Decorate your room.',
    next: 'ethree9',
  },
  {
    id: 'ethree9',
    direction: 'incoming',
    text: 'You’ve been meaning to put up some of your music posters.',
    next: 'ethree10',
  },
  {
    id: 'ethree10',
    direction: 'incoming',
    text: 'Guess it’s finally time to do it.',
    next: 'ethree11',
  },
  {
    id: 'ethree11',
    direction: 'incoming',
    text:
      'You check the distance between each spot twice over with a tape measure and a level.',
    next: 'ethree12',
  },
  {
    id: 'ethree12',
    next: 'ethree13',
    direction: 'incoming',
    text: 'Everything has to be perfectly straight, 10cm apart.',
  },
  {
    id: 'ethree13',
    direction: 'incoming',
    text: 'Measuring done. Time for tape.',
    externalEvent: 'clicktape',
  },
  {
    id: 'ethree14',
    next: 'ethree15',
    direction: 'incoming',
    text:
      'You think about how useful tape is. Ever since the lockdown, it’s been everywhere.',
  },
  {
    id: 'ethree15',
    next: 'ethree16',
    direction: 'incoming',
    text:
      'You remember an Instagram account that documents all these tape measures.',
  },
  {
    id: 'ethree16',
    direction: 'incoming',
    text: 'They tell people where they have to be, 1 metre apart.',
    externalEvent: 'openbernytan',
  },
  {
    id: 'ethree17',
    next: 'ethree18',
    direction: 'incoming',
    text:
      'You’re confused. Somehow you find yourself, yet again, dealing with an artwork.',
    externalEvent: 'bandposters',
  },
  {
    id: 'ethree18',
    next: 'ethree19',
    direction: 'incoming',
    text: 'Strange things have been happening in your bedroom.',
  },
  {
    id: 'ethree19',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={bernygroup1} alt="" />,
        to: 'ethree20',
      },
      {
        image: (props) => <img {...props} src={bernygroup2} alt="" />,
        to: 'ethree23',
      },
      {
        image: (props) => <img {...props} src={bernygroup3} alt="" />,
        to: 'ethree26',
      },
    ],
  },
  {
    id: 'ethree20',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup1} alt="" />,
    next: 'ethree21',
  },
  {
    id: 'ethree21',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={bernygroup1a} alt="" />,
        to: 'ethree22a0',
      },
      {
        image: (props) => <img {...props} src={bernygroup1b} alt="" />,
        to: 'ethree22b0',
      },
    ],
  },
  {
    id: 'ethree22a0',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup1a} alt="" />,
    next: 'ethree22a1',
  },
  {
    id: 'ethree22a1',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={keepingdistance} alt="" />,
        to: 'ethree22aa0',
      },
      {
        image: (props) => <img {...props} src={intimacy} alt="" />,
        to: 'ethree22ab0',
      },
    ],
  },
  {
    id: 'ethree22aa0',
    next: 'ethree22aa1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={keepingdistance} alt="" />,
  },
  {
    id: 'ethree22aa1',
    next: 'ethree22aa2',
    direction: 'incoming',
    text: ['i', '', 'keeping –– as one might keep going –– distance'],
    textWeight: 'bold',
  },
  {
    id: 'ethree22aa2',
    next: 'ethree22aa3',
    direction: 'incoming',
    text:
      'You think it funny, how a piece about keeping distance is accompanied by an image like this.',
  },
  {
    id: 'ethree22aa3',
    next: 'ethree29',
    direction: 'incoming',
    text: 'Two chairs, closer than humans are allowed to be in public.',
  },
  {
    id: 'ethree22ab0',
    next: 'ethree22ab1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={intimacy} alt="" />,
  },
  {
    id: 'ethree22ab1',
    next: 'ethree22ab2',
    direction: 'incoming',
    text: ['xii', '', 'an intimacy that can only be put into words'],
    textWeight: 'bold',
  },
  {
    id: 'ethree22ab2',
    next: 'ethree22ab3',
    direction: 'incoming',
    text: 'You feel like you’ve spent some time with someone new, yet again.',
  },
  {
    id: 'ethree22ab3',
    next: 'ethree29',
    direction: 'incoming',
    text:
      'At a distance, but close enough to have seen the world through their point of view for a moment in time.',
  },
  {
    id: 'ethree22b0',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup1b} alt="" />,
    next: 'ethree22b1',
  },
  {
    id: 'ethree22b1',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={alovestory2} alt="" />,
        to: 'ethree22ba0',
      },
      {
        image: (props) => <img {...props} src={alovestory1} alt="" />,
        to: 'ethree22bb0',
      },
    ],
  },
  {
    id: 'ethree22ba0',
    next: 'ethree22ba1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={alovestory2} alt="" />,
  },
  {
    id: 'ethree22ba1',
    next: 'ethree22ba2',
    direction: 'incoming',
    text: [
      'xii',
      '',
      'When she was still young, but when her unruly body had already outgrown the playthings of children her age, the swing had felt like freedom too.',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree22ba2',
    next: 'ethree22ba3',
    direction: 'incoming',
    text: 'This feels far too personal to respond to.',
  },
  {
    id: 'ethree22ba3',
    next: 'ethree29',
    direction: 'incoming',
    text: 'You read it quietly.',
  },
  {
    id: 'ethree22bb0',
    next: 'ethree22bb1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={alovestory1} alt="" />,
  },
  {
    id: 'ethree22bb1',
    next: 'ethree22ba2',
    direction: 'incoming',
    text: [
      'vi',
      '',
      'Perhaps it was the only sense in which she could allow herself to be vulnerable, stripped of any real consequences beyond the words that she put down on the page, that would never be uttered out loud.',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree23',
    next: 'ethree24',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup2} alt="" />,
  },
  {
    id: 'ethree24',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={bernygroup2a} alt="" />,
        to: 'ethree25a0',
      },
      {
        image: (props) => <img {...props} src={bernygroup2b} alt="" />,
        to: 'ethree25b0',
      },
    ],
  },
  {
    id: 'ethree25a0',
    next: 'ethree25a1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup2a} alt="" />,
  },
  {
    id: 'ethree25a1',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={touch} alt="" />,
        to: 'ethree25aa0',
      },
      {
        image: (props) => <img {...props} src={thebox} alt="" />,
        to: 'ethree25ab0',
      },
    ],
  },
  {
    id: 'ethree25aa0',
    next: 'ethree25aa1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={touch} alt="" />,
  },
  {
    id: 'ethree25aa1',
    next: 'ethree25aa2',
    direction: 'incoming',
    text: [
      'ii',
      '',
      'So it is odd, now, that the world is telling her not to touch, or be touched.',
      '',
      'Alright, she thinks to herself, this is easy. I’ve been doing this most of my life.',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree25aa2',
    next: 'ethree25aa3',
    direction: 'incoming',
    text: 'Unlike the author of this piece, you’ve always been a hugger.',
  },
  {
    id: 'ethree25aa3',
    next: 'ethree29',
    direction: 'incoming',
    text:
      'This distance is new to you. But you forget, it’s a choice others have made, under other circumstances.',
  },
  {
    id: 'ethree25ab0',
    next: 'ethree25ab1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={thebox} alt="" />,
  },
  {
    id: 'ethree25ab1',
    next: 'ethree25ab2',
    direction: 'incoming',
    text: [
      'iv',
      '',
      'A: But why? Why not just tell us? Why do we even have to sort out a conflict we can’t even remember?',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree25ab2',
    next: 'ethree29',
    direction: 'incoming',
    text: 'You imagine a happy ending for the pair trapped in a box.',
  },
  {
    id: 'ethree25b0',
    next: 'ethree25b1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup2b} alt="" />,
  },
  {
    id: 'ethree25b1',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={thequeue} alt="" />,
        to: 'ethree25ba0',
      },
      {
        image: (props) => <img {...props} src={trustfall} alt="" />,
        to: 'ethree25bb0',
      },
    ],
  },
  {
    id: 'ethree25ba0',
    next: 'ethree25ba1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={thequeue} alt="" />,
  },
  {
    id: 'ethree25ba1',
    next: 'ethree25ba2',
    direction: 'incoming',
    text: [
      'vii',
      '',
      'There are lines on the floor, regularly spaced, as far as the eye can see. At each line stands one person.',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree25ba2',
    next: 'ethree25ba3',
    direction: 'incoming',
    text:
      'You imagine these characters will see each other again. In this strange world where they queue every day.',
  },
  {
    id: 'ethree25ba3',
    next: 'ethree25ba4',
    direction: 'incoming',
    text: 'What the hell are they queuing for.',
  },
  {
    id: 'ethree25ba4',
    next: 'ethree29',
    direction: 'incoming',
    text:
      'You’ll never know though and so, the Singaporean in you remains unsatisfied.',
  },
  {
    id: 'ethree25bb0',
    next: 'ethree25bb1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={trustfall} alt="" />,
  },
  {
    id: 'ethree25bb1',
    next: 'ethree25bb2',
    direction: 'incoming',
    text: ['ix', '', 'Did you have any hesitation before falling?'],
    textWeight: 'bold',
  },
  {
    id: 'ethree25bb2',
    next: 'ethree25bb3',
    direction: 'incoming',
    text: 'You are thankful no one ever asked you to do a trust fall.',
  },
  {
    id: 'ethree25bb3',
    next: 'ethree29',
    direction: 'incoming',
    text: 'You don’t think anyone would be able to catch you either.',
  },
  {
    id: 'ethree26',
    next: 'ethree27',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup3} alt="" />,
  },
  {
    id: 'ethree27',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={bernygroup3a} alt="" />,
        to: 'ethree28a0',
      },
      {
        image: (props) => <img {...props} src={bernygroup3b} alt="" />,
        to: 'ethree28b0',
      },
    ],
  },
  {
    id: 'ethree28a0',
    next: 'ethree28a1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup3a} alt="" />,
  },
  {
    id: 'ethree28a1',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={confessions} alt="" />,
        to: 'ethree28aa0',
      },
      {
        image: (props) => <img {...props} src={bestrong} alt="" />,
        to: 'ethree28ab0',
      },
    ],
  },
  {
    id: 'ethree28aa0',
    next: 'ethree28aa1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={confessions} alt="" />,
  },
  {
    id: 'ethree28aa1',
    next: 'ethree28aa2',
    direction: 'incoming',
    text: [
      'v',
      '',
      'No worries, I switched out the word ‘confessional’ for the simpler ‘very personal’.',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree28aa2',
    next: 'ethree28aa3',
    direction: 'incoming',
    text: 'You wonder if this is a personal work. Or a work of fiction.',
  },
  {
    id: 'ethree28aa3',
    next: 'ethree28aa4',
    direction: 'incoming',
    text:
      'It doesn’t matter either way. You found yourself curious about not only what the author had to say, but how they would do so. Poem, prose, script and more.',
  },
  {
    id: 'ethree28aa4',
    next: 'ethree29',
    direction: 'incoming',
    text: 'What shape would the tape measures take next?',
  },
  {
    id: 'ethree28ab0',
    next: 'ethree28ab1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bestrong} alt="" />,
  },
  {
    id: 'ethree28ab1',
    next: 'ethree28ab2',
    direction: 'incoming',
    text: ['x', '', 'be strong'],
    textWeight: 'bold',
  },
  {
    id: 'ethree28ab2',
    next: 'ethree28ab3',
    direction: 'incoming',
    text:
      'Sometimes, you find yourself unsure of what to say. Cliches come naturally. Candy-sweet birthday messages and well-wishes, rote condolences.',
  },
  {
    id: 'ethree28ab3',
    next: 'ethree29',
    direction: 'incoming',
    text: 'What can you offer in place of platitudes?',
  },
  {
    id: 'ethree28b0',
    next: 'ethree28b1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={bernygroup3b} alt="" />,
  },
  {
    id: 'ethree28b1',
    direction: 'incoming',
    text: '',
    options: [
      {
        image: (props) => <img {...props} src={atarmslength2} alt="" />,
        to: 'ethree28ba0',
      },
      {
        image: (props) => <img {...props} src={atarmslength1a} alt="" />,
        to: 'ethree28bb0',
      },
    ],
  },
  {
    id: 'ethree28ba0',
    next: 'ethree28ba1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={atarmslength2} alt="" />,
  },
  {
    id: 'ethree28ba1',
    next: 'ethree28ba2',
    direction: 'incoming',
    text: [
      'viii',
      '',
      'An arm’s length, and the width of her shoulders. That is the distance she should keep.',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree28ba2',
    next: 'ethree28ba3',
    direction: 'incoming',
    text: 'This piece feels raw, real. And you feel awkward reading it.',
  },
  {
    id: 'ethree28ba3',
    next: 'ethree28ba4',
    direction: 'incoming',
    text:
      'Personal feelings pushed to the right, sandwiched by a measured text that discusses someone measuring themselves.',
  },
  {
    id: 'ethree28ba4',
    next: 'ethree28ba5',
    direction: 'incoming',
    text:
      'You idly wonder how long your arms are. What one metre is in the measurement of your body.',
  },
  {
    id: 'ethree28ba5',
    next: 'ethree29',
    direction: 'incoming',
    text:
      'Is this the right response, or a cruel one, given what was discussed?',
  },
  {
    id: 'ethree28bb0',
    next: 'ethree28bb1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={atarmslength1a} alt="" />,
  },
  {
    id: 'ethree28bb1',
    next: 'ethree28bb2',
    direction: 'incoming',
    text: [
      'iii',
      '',
      'Keep someone at arm’s length. It’s a phrase meant emotionally, metaphorically, aspirationally.',
    ],
    textWeight: 'bold',
  },
  {
    id: 'ethree28bb2',
    next: 'ethree28bb3',
    direction: 'incoming',
    text:
      'For a while, you thought this phrase ‘to keep someone at arm’s length’ was negative. A judgement about someone else.',
  },
  {
    id: 'ethree28bb3',
    next: 'ethree29',
    direction: 'incoming',
    text: 'But sometimes it’s just about what you want, what you need.',
  },
  {
    id: 'ethree29',
    direction: 'incoming',
    text: 'You...',
    options: [
      { text: 'Remember another vignette.', to: 'ethree29a' },
      { text: 'Want to do something else.', to: 'ethree29b' },
    ],
  },
  {
    id: 'ethree29a',
    direction: 'outgoing',
    auto: true,
    text: 'Remember another vignette.',
    next: 'ethree19',
  },
  {
    id: 'ethree29b',
    direction: 'outgoing',
    text: 'Want to do something else.',
    externalEvent: 'dosomethingelse',
  },
  {
    id: 'ethree30',
    next: 'ethree31',
    direction: 'incoming',
    text:
      'You notice you’re still holding on to the tape. You quickly put up your posters and admire your handiwork.',
  },
  {
    id: 'ethree31',
    next: 'ethree32',
    direction: 'incoming',
    text: 'It looks good.',
  },
  {
    id: 'ethree32',
    direction: 'incoming',
    text: 'It would be nice to play your favourite record by the band now.',
    externalEvent: 'openweish',
  },
  {
    id: 'ethree33',
    direction: 'outgoing',
    auto: true,
    text: 'Put on some music.',
    next: 'ethree34',
  },
  {
    id: 'ethree34',
    next: 'ethree35',
    direction: 'incoming',
    text:
      'It’s a rainy afternoon. Lounging in bed while listening to some jazz sounds heavenly.',
  },
  {
    id: 'ethree35',
    next: 'ethree36',
    direction: 'incoming',
    text: 'You haven’t touched your record player in a while.',
  },
  {
    id: 'ethree36',
    direction: 'incoming',
    text: 'Time to put that record on.',
    externalEvent: 'startrecordplayer',
  },
  {
    id: 'ethree37',
    next: 'ethree38',
    direction: 'incoming',
    text:
      'The music plays. You fall back into bed and wish for someone to cuddle with.',
  },
  {
    id: 'ethree38',
    next: 'ethree39',
    direction: 'incoming',
    text:
      'Meeting new people has been tough. You only have dating apps now. It’s not like you’re going to meet anyone while stuck in a queue.',
  },
  {
    id: 'ethree39',
    direction: 'incoming',
    text:
      'You hope that the person you fall in love with will enjoy the same music you do.',
    externalEvent: 'openweish',
  },
  {
    id: 'ethree40',
    next: 'ethree41',
    direction: 'incoming',
    text: 'Chuuuuuuuuuren, Chuuuuuuuren.',
    textStyle: 'italic',
    externalEvent: 'playlovelockdownmusic',
  },
  {
    id: 'ethree41',
    next: 'ethree42',
    direction: 'incoming',
    text: 'Who is that?',
  },
  {
    id: 'ethree42',
    next: 'ethree43',
    direction: 'incoming',
    text: 'Is this a hallucination?',
  },
  {
    id: 'ethree43',
    direction: 'incoming',
    text: 'It’s obviously about',
    options: [
      { text: 'Love.', to: 'ethree44a0' },
      { text: 'Music.', to: 'ethree44b0' },
    ],
  },
  {
    id: 'ethree44a0',
    direction: 'outgoing',
    auto: true,
    text: 'Love.',
    next: 'ethree44a1',
  },
  {
    id: 'ethree44a1',
    next: 'ethree44a2',
    direction: 'incoming',
    text: 'Or the hope of finding it.',
  },
  {
    id: 'ethree44a2',
    next: 'ethree44a3',
    direction: 'incoming',
    text: 'Via online dating. Urgh.',
  },
  {
    id: 'ethree44a3',
    next: 'ethree44a4',
    direction: 'incoming',
    text:
      'Apps are … not great. But they seem to be the only way to meet people now. Swipe and hope.',
  },
  {
    id: 'ethree44a4',
    next: 'ethree44a5',
    direction: 'incoming',
    text:
      'Music seemed to take the place of conversation. Two people with different styles, trying to find a connection, to sound good together.',
  },
  {
    id: 'ethree44a5',
    next: 'ethree45',
    direction: 'incoming',
    text: 'It makes sense. It’s just like dating.',
  },
  {
    id: 'ethree44b0',
    next: 'ethree44b1',
    direction: 'outgoing',
    auto: true,
    text: 'Music.',
  },
  {
    id: 'ethree44b1',
    next: 'ethree44b2',
    direction: 'incoming',
    text:
      'The song included diverse voices and instruments. Jumping across genres you didn’t expect would work together.',
  },
  {
    id: 'ethree44b2',
    next: 'ethree44b3',
    direction: 'incoming',
    text:
      'A little bit of classical, a little bit of rap. A little bit of … whispery singing?',
  },
  {
    id: 'ethree44b3',
    next: 'ethree44b4',
    direction: 'incoming',
    text: 'But it all sounded unexpectedly wonderful.',
  },
  {
    id: 'ethree44b4',
    next: 'ethree44b5',
    direction: 'incoming',
    text: 'In some way, it makes sense.',
  },
  {
    id: 'ethree44b5',
    next: 'ethree45',
    direction: 'incoming',
    text: 'Is love like that? All about unexpected connections.',
  },
  {
    id: 'ethree45',
    direction: 'incoming',
    text: "Churen's choice. You agree with it.",
    options: [
      { text: 'Yes.', to: 'ethree46a0' },
      { text: 'No.', to: 'ethree46b0' },
    ],
  },
  {
    id: 'ethree46a0',
    next: 'ethree46a1',
    direction: 'outgoing',
    auto: true,
    text: 'Yes.',
  },
  {
    id: 'ethree46a1',
    next: 'ethree46a2',
    direction: 'incoming',
    text: 'If these were the only choices, you’d go with Hell Low too.',
  },
  {
    id: 'ethree46a2',
    next: 'ethree46a3',
    direction: 'incoming',
    text:
      'Curiosity killed the cat. You wanna know why would someone write such sad lyrics.',
  },
  {
    id: 'ethree46a3',
    direction: 'incoming',
    text: [
      'No one asked.',
      'Nothing matters.',
      'In the end everything we do is futile.',
    ],
    next: (finish) => (finish ? 'ethree54' : 'ethree48'),
    externalEvent: 'secondevent',
    textStyle: 'italic',
  },
  {
    id: 'ethree46b0',
    next: 'ethree46b1',
    direction: 'outgoing',
    auto: true,
    text: 'No.',
  },
  {
    id: 'ethree46b1',
    next: 'ethree46b2',
    direction: 'incoming',
    text:
      'You’re not sure you’d have gone on a second date with someone whose zoom background is burning flames.',
  },
  {
    id: 'ethree46b2',
    next: 'ethree46b3',
    direction: 'incoming',
    text:
      'You wonder what their hidden room looks like. That can tell you a lot about a person.',
  },
  {
    id: 'ethree46b3',
    next: 'ethree46b4',
    direction: 'incoming',
    text: 'You suppose honesty is appealing.',
  },
  {
    id: 'ethree46b4',
    next: 'ethree46b5',
    direction: 'incoming',
    text: [
      'No one asked.',
      'Nothing matters.',
      'In the end everything we do is futile.',
    ],
    textStyle: 'italic',
  },
  {
    id: 'ethree46b5',
    next: 'ethree46b6',
    direction: 'incoming',
    text: 'Why was he on a dating app then?',
  },
  {
    id: 'ethree46b6',
    next: 'ethree46b7',
    direction: 'incoming',
    text: 'But hey, it might be fun to try and prove him wrong.',
  },
  {
    id: 'ethree46b7',
    direction: 'incoming',
    text: 'But you would have picked',
    options: [
      {
        image: (props) => <img {...props} src={lovelockdownsubhas} alt="" />,
        to: 'ethree47a0',
      },
      {
        image: (props) => <img {...props} src={lovelockdowntim} alt="" />,
        to: 'ethree47b0',
      },
    ],
  },
  {
    id: 'ethree47a0',
    next: 'ethree47a1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={lovelockdownsubhas} alt="" />,
  },
  {
    id: 'ethree47a1',
    next: 'ethree47a2',
    direction: 'incoming',
    text: [
      'I’m not that good at reading signs outside a periodic table',
      'But I see it in the stars, I see it clearly',
      'That you and me together it’s a Big Bang theory',
    ],
    textStyle: 'italic',
  },
  {
    id: 'ethree47a2',
    direction: 'incoming',
    text: 'You like someone with a sense of humour.',
    next: (finish) => (finish ? 'ethree54' : 'ethree48'),
    externalEvent: 'secondevent',
  },
  {
    id: 'ethree47b0',
    next: 'ethree47b1',
    direction: 'outgoing',
    auto: true,
    image: (props) => <img {...props} src={lovelockdowntim} alt="" />,
  },
  {
    id: 'ethree47b1',
    next: 'ethree47b2',
    direction: 'incoming',
    text: [
      'I just wanna know, if I could be the one for you',
      'If I could be the one for you',
    ],
    textStyle: 'italic',
  },
  {
    id: 'ethree47b2',
    direction: 'incoming',
    text: 'Who isn’t charmed by a bassist?',
    next: (finish) => (finish ? 'ethree54' : 'ethree48'),
    externalEvent: 'secondevent',
  },
  {
    id: 'ethree48',
    next: 'ethree49',
    direction: 'incoming',
    text:
      'The song lingers in your head. It’s a good one, but you don’t want to be stuck thinking about dating and longing for love.',
  },
  {
    id: 'ethree49',
    next: 'ethree50',
    direction: 'incoming',
    text: 'Distraction, you need distraction.',
  },
  {
    id: 'ethree50',
    next: 'ethree51',
    direction: 'incoming',
    text: 'Posters. Yes, you’ve been meaning to put them up. Right there.',
  },
  {
    id: 'ethree51',
    next: 'ethree52',
    direction: 'incoming',
    text:
      'You mark the spot, checking the distance between them twice over with a tape measure and a level.',
  },
  {
    id: 'ethree52',
    next: 'ethree53',
    direction: 'incoming',
    text: 'Everything has to be perfectly straight, 10cm apart.',
  },
  {
    id: 'ethree53',
    direction: 'incoming',
    text: 'Measuring done. Time for tape.',
    externalEvent: 'openbernytan',
  },
  {
    id: 'ethree54',
    next: 'ethree55',
    direction: 'incoming',
    text: 'Well, you didn’t expect to ruminate on distance and love today.',
  },
  {
    id: 'ethree55',
    next: 'ethree56',
    direction: 'incoming',
    text: 'And the things you saw were almost opposites.',
  },
  {
    id: 'ethree56',
    next: 'ethree57',
    direction: 'incoming',
    text:
      'One a series of quiet contemplations. The other, an attempt at musical connection.',
  },
  {
    id: 'ethree57',
    next: 'ethree58',
    direction: 'incoming',
    text: 'One about reaching out. The other, about keeping distance.',
  },
  {
    id: 'ethree58',
    direction: 'incoming',
    text: 'You consider what you want.',
    options: [
      { text: 'Reach out.', to: 'ethree59a0' },
      { text: 'Wait a while.', to: 'ethree59b0' },
    ],
  },
  {
    id: 'ethree59a0',
    next: 'ethree59a1',
    direction: 'outgoing',
    auto: true,
    text: 'Reach out.',
  },
  {
    id: 'ethree59a1',
    next: 'ethree59a2',
    direction: 'incoming',
    text: 'You’re ready to set up that dating profile again.',
  },
  {
    id: 'ethree59a2',
    next: 'ethree59a3',
    direction: 'incoming',
    text:
      'You grab your phone, put on your favourite playlist#http://open.spotify.com/playlist/6q07HQyLneYCmmdmW8Lz2J and settle down to swipe.',
  },
  {
    id: 'ethree59a3',
    direction: 'incoming',
    text: 'Who knows what might happen?',
    externalEvent: 'endethree1',
  },
  {
    id: 'ethree59b0',
    next: 'ethree59b1',
    direction: 'outgoing',
    auto: true,
    text: 'Wait a while.',
  },
  {
    id: 'ethree59b1',
    next: 'ethree59b2',
    direction: 'incoming',
    text:
      'You want to wait a while. To see how things play out in the world and try to enjoy the time you have to yourself.',
  },
  {
    id: 'ethree59b2',
    next: 'ethree59b3',
    direction: 'incoming',
    text:
      'And time with family and friends, with those who are already close to you.',
  },
  {
    id: 'ethree59b3',
    next: 'ethree59b4',
    direction: 'incoming',
    text:
      'You grab your phone, put on your favourite playlist#http://open.spotify.com/playlist/6q07HQyLneYCmmdmW8Lz2J and open up your group chats.',
  },
  {
    id: 'ethree59b4',
    direction: 'incoming',
    text:
      'It’s about time you met for your parents and friends for a meal again.',
    externalEvent: 'endethree2',
  },
]

export default content
