import { useHistory } from 'react-router'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import logo from '../assets/img/dac-logo.svg'
import background from '../assets/img/password-bg.png'

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: black;
`

const Logo = styled.img`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 200px;
`
const Bg = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const PasswordContainer = styled.div`
  background: black;
  border: 1px solid white;
  position: fixed;
  left: 50vw;
  bottom: 20vh;
  width: 50vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const InputHeader = styled.label`
  font-family: Roslindale;
  font-size: 3em;
  color: white;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
`
const InputSubheader = styled.label`
  font-family: Cirka;
  font-size: 1.2em;
  color: white;
  text-align: left;
  margin-bottom: 20px;
`

const InputContainer = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  background: black;
  margin-bottom: 20px;
`

const EpButton = styled.button`
  font-family: BarlowRegular;
  margin: 4px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;
  animation: fadeIn ease ${(props) => `${1 + props.i * 0.3}s`};

  &:hover {
    background: black;
    color: white;
    border: 1px solid white;
  }

  @keyframes fadeIn {
    0% {
      transform: translate(0, 40px);
      opacity: 0;
    }
    100% {
      tranform: translate(0);
      opacity: 1;
    }
  }
`

const Selection = () => {
  const history = useHistory()

  const handleClick = (url, category, label, action) => {
    ReactGA.event({ category, label, action })
    history.push(url)
  }

  return (
    <Container>
      <Bg src={background} />
      <Logo src={logo} />
      <PasswordContainer>
        <InputHeader>Start your next adventure.</InputHeader>
        <InputSubheader>
          All episodes of Days — and counting: The distance between us will be
          available online from 19 March - 30 April 2021. You are welcome to
          return to Sleeping Man’s bedroom at any time and revisit the artworks
          and episodes or catch those you missed.
          <br />
          <br />
          <i>E1: Reality & Rest</i> features works from Denise Yap and Lynn Lu.
          <br />
          <br />
          <i>E2: Home Alone</i> features a work from Ezzam Rahman.
          <br />
          <br />
          <i>E3: Distance Kept, Distance Bridged</i> features a work by Berny
          Tan and a collaborative work by Churen Li, Hell Low, Subhas, Tim De
          Cotta and weish.
          <br />
          <br />
          <i>E4: Working Through, Making Do</i>  features a work by Yen Phang
          and a collaborative work by Tan Kheng Hua, Bailey Wait, Lim Shi-An and
          Robert Wait.
        </InputSubheader>
        <InputContainer>
          <EpButton
            i={0}
            onClick={() =>
              handleClick(
                '/prologue',
                'Button (DAC S2)',
                'DAC S2 - Entered Episode 1',
                'Clicked to enter Episode 1 at start',
              )
            }
          >
            Episode One
          </EpButton>
          <EpButton
            i={1}
            onClick={() =>
              handleClick(
                '/ep2',
                'Button (DAC S2)',
                'DAC S2 - Entered Episode 2',
                'Clicked to enter Episode 2 at start',
              )
            }
          >
            Episode Two
          </EpButton>
          <EpButton
            i={2}
            onClick={() =>
              handleClick(
                '/ep3',
                'Button (DAC S2)',
                'DAC S2 - Entered Episode 3',
                'Clicked to enter Episode 3 at start',
              )
            }
          >
            Episode Three
          </EpButton>
          <EpButton
            i={3}
            onClick={() =>
              handleClick(
                '/ep4',
                'Button (DAC S2)',
                'DAC S2 - Entered Episode 4',
                'Clicked to enter Episode 4 at start',
              )
            }
          >
            Episode Four
          </EpButton>
        </InputContainer>
        <InputSubheader>
          If you find that your screen size is too small, zoom out within your
          browser to your preference for the best viewing experience.
          <br />
          For PC users, you may do so by using Ctrl key + "-".
          <br />
          For Mac users, you may do so by using Command key + "-"
        </InputSubheader>
      </PasswordContainer>
    </Container>
  )
}

export default Selection
