import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'
import useSound from 'use-sound'
import { TextContainer } from '../components/Text'
import Button from '../components/Button'
import LLArt from '../components/artworks/LL'
import DYArt from '../components/artworks/DY'
import MoreInfo from '../components/MoreInfo'

import ep1Content from '../content/ep1-content'
import bg from '../assets/img/e1-room-bg.jpg'
import bookgamechoicebg from '../assets/img/bookgamechoice.jpg'
import gamechoicebg from '../assets/img/gamechoice.jpg'
import bookbg from '../assets/img/book-bg.jpg'
import gamebg from '../assets/img/game-bg.png'
import alarmphone from '../assets/img/alarmphone.png'
import bedroom from '../assets/sound/bedroom1.wav'
import curation from '../assets/sound/curation1.wav'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
`
const ImageContainer = styled.div`
  position: fixed;
  width: 100vw;
`
const StyledImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const StyledImageAnim = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: opacityIn ease 1s;

  @keyframes opacityIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const Fixed = styled.div`
  position: fixed;
`

const StyledButton = styled(Button)`
  width: auto;
  font-size: 1.5em;
  background-color: ${(props) => props.theme.colors.hotMagenta};
  color: white;
  animation: fadeIn ease 0.5s;
`
const BookButton = styled(StyledButton)`
  position: fixed;
  top: 70%;
  left: 54%;
  width: 14%;
  text-align: center;
`

const GameButton = styled(StyledButton)`
  position: fixed;
  top: 67%;
  left: 30%;
  width: 14%;
  text-align: center;
`

const LaptopButton = styled.button`
  position: fixed;
  bottom: 0;
  right: 25%;
  width: 15%;
  height: 20%;
  background: white;
  opacity: 0;
  box-shadow: 60px -16px teal;
  transition: 0.5s;
  cursor: pointer;
  clip-path: polygon(
    40% 0,
    37% 53%,
    2% 69%,
    50% 100%,
    66% 100%,
    89% 78%,
    95% 10%,
    41% 0
  );

  &:hover {
    opacity: 0.5;
  }
`

const Ending = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  padding: 100px 0;

  animation: endingbg 10s;
  @keyframes endingbg {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const BigText = styled.p`
  width: 50vw;
  margin: 100px 25vw;
  text-align: center;
  font-family: Roslindale;
  font-size: 5em;
  color: white;
`

const PhoneImg = styled.img`
  position: fixed;
  height: 90vh;
  top: 5vh;
  left: 40vw;

  transform: translateY(-100vh) rotate(90deg);
  animation: alarm ease-in-out 7s;
  @keyframes alarm {
    0% {
      transform: translateX(-50vw) translateY(100vh) rotate(-90deg);
    }
    40% {
      transform: translateX(0) translateY(0) rotate(-1deg);
    }
    80% {
      transform: translateX(0) translateY(0) rotate(1deg);
    }
    100% {
      transform: translateY(-100vh) rotate(40deg);
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 700px;
  margin: 0 auto;
`

const FirstButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 80px;
  cursor: pointer;
  border: 1px solid white;
  background: none;
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const SecondButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 40px;
  cursor: pointer;
  border: none;
  background: ${(props) => props.theme.colors.darkPurple};
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`
const EpButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  background: ${(props) => props.theme.colors.darkPurple};
  color: white;
  cursor: pointer;
  border: none;
  padding: 20px;
  margin: 20px 4px;
  flex: 1;
  transition: 0.3s;

  animation: fadeIn ease ${(props) => `${1 + props.i * 0.3}s`};

  &:hover {
    background: white;
    color: black;
  }

  @keyframes fadeIn {
    0% {
      transform: translate(0, 40px);
      opacity: 0;
    }
    100% {
      tranform: translate(0);
      opacity: 1;
    }
  }
`

const EpisodeOne = () => {
  const textRef = useRef()
  const [bookText, setBookText] = useState('')
  const bookBtnCallbackRef = useRef()
  const [showLLArt, setShowLLArt] = useState(false)
  const [showDYArt, setShowDYArt] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const [showBookBg, setShowBookBg] = useState(false)
  const [showGameBg, setShowGameBg] = useState(false)
  const [showChoiceBg, setShowChoiceBg] = useState(false)
  const [showGameChoiceBg, setShowGameChoiceBg] = useState(false)

  const [ending, setEnding] = useState(false)

  const [gameText, setGameText] = useState('')
  const gameBtnCallbackRef = useRef()
  const [playBedroom, bedroomTrack] = useSound(bedroom, { loop: true })
  const [playCuration, curationTrack] = useSound(curation, { loop: true })

  const history = useHistory()

  useEffect(() => {
    bookBtnCallbackRef.current = () => {
      textRef.current.addNode('eone5a0')
      setBookText('')
      setGameText('')
      setShowChoiceBg(false)
      setShowBookBg(true)
    }
    gameBtnCallbackRef.current = () => {
      textRef.current.addNode('eone4b0')
      setBookText('')
      setGameText('')
      setShowChoiceBg(false)
    }
  }, [])

  useEffect(playBedroom, [playBedroom])

  const triggerEvent = (id) => {
    switch (id) {
      case 'curationmusic':
        bedroomTrack.stop()
        playCuration()
        break
      case 'bedroommusic':
        curationTrack.stop()
        playBedroom()
        break
      case 'makegamebookchoice':
        setBookText('Decide to read.')
        setGameText('Want to play a video game.')
        setShowChoiceBg(true)
        break
      case 'makebookchoice':
        setBookText('Decide to read.')
        break
      case 'openbook':
        setBookText('Open the book.')
        bookBtnCallbackRef.current = () => {
          setBookText('')
          setShowLLArt(true)
          bedroomTrack.stop()
          curationTrack.stop()
        }
        break
      case 'bgreset':
        setShowChoiceBg(false)
        setShowBookBg(false)
        setShowGameBg(false)
        break
      case 'makegamechoice':
        setGameText('Play a video game.')
        setShowGameChoiceBg(true)
        gameBtnCallbackRef.current = () => {
          setGameText('')
          setShowGameChoiceBg(false)
          setShowGameBg(true)
          textRef.current.addNode('eone20')
        }
        break
      case 'opendeniseyap':
        setGameText('Start the game.')
        gameBtnCallbackRef.current = () => {
          setShowDYArt(true)
          setGameText('')
          bedroomTrack.stop()
          curationTrack.stop()
        }
        break
      case 'eoneend':
        setEnding(true)
        break
      default:
        break
    }
  }

  const handleRestart = () => {
    history.push('/')
    bedroomTrack.stop()
    curationTrack.stop()
  }

  const handleEpClick = (url, category, label, action) => {
    ReactGA.event({ category, label, action })
    history.push(url)
  }

  const artworkIsOpen = showLLArt || showDYArt

  return (
    <Container>
      <ImageContainer>
        <StyledImage src={bg} />
        {showChoiceBg && <StyledImageAnim src={bookgamechoicebg} />}
        {showGameChoiceBg && <StyledImageAnim src={gamechoicebg} />}
        {showGameBg && <StyledImageAnim src={gamebg} />}
        {showBookBg && <StyledImageAnim src={bookbg} />}
        {Boolean(bookText) && (
          <BookButton direction="outgoing" onClick={bookBtnCallbackRef.current}>
            {bookText}
          </BookButton>
        )}
        {Boolean(gameText) && (
          <GameButton direction="outgoing" onClick={gameBtnCallbackRef.current}>
            {gameText}
          </GameButton>
        )}
        <LaptopButton
          onClick={() => {
            if (artworkIsOpen || ending) return
            setShowInfo(true)
          }}
        />
      </ImageContainer>
      <Fixed>
        <TextContainer
          contentObj={ep1Content}
          ref={textRef}
          triggerEvent={triggerEvent}
        />
      </Fixed>
      <MoreInfo show={showInfo} onClose={() => setShowInfo(false)} />
      <LLArt
        show={showLLArt}
        onClose={() => {
          setShowLLArt(false)
          textRef.current.addNode('eone7')
        }}
      />
      <DYArt
        show={showDYArt}
        onClose={() => {
          setShowDYArt(false)
          textRef.current.addNode('eone22')
        }}
      />

      {ending && (
        <Ending>
          <BigText>Next episode launches at 26/02/2021, 12:00</BigText>
          <Row>
            <FirstButton onClick={handleRestart}>RESTART</FirstButton>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4yBXfROmiLd0UjjdoIYoWwZEptel5sKUHnDhBhamEuQJUXg/viewform?usp=sf_link">
              <SecondButton>GIVE FEEDBACK</SecondButton>
            </a>
            <a href="https://ohopenhouse.give.asia/">
              <SecondButton>EXIT</SecondButton>
            </a>
          </Row>
          <Row>
            <EpButton
              i={1}
              onClick={() =>
                handleEpClick(
                  '/ep2',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 2 from another ep',
                  'Clicked to enter Episode 2 from end of an ep',
                )
              }
            >
              Episode Two
            </EpButton>
            <EpButton
              i={2}
              onClick={() =>
                handleEpClick(
                  '/ep3',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 3 from another ep',
                  'Clicked to enter Episode 3 from end of an ep',
                )
              }
            >
              Episode Three
            </EpButton>
            <EpButton
              i={3}
              onClick={() =>
                handleEpClick(
                  '/ep4',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 4 from another ep',
                  'Clicked to enter Episode 4 from end of an ep',
                )
              }
            >
              Episode Four
            </EpButton>
          </Row>
        </Ending>
      )}
      {ending && <PhoneImg src={alarmphone} />}
    </Container>
  )
}

export default EpisodeOne
