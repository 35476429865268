import { useState } from 'react'
import styled from 'styled-components'
import Window from '../Window'

const BackButton = styled.button`
  border: none;
  padding: 8px 20px;
  font-weight: bold;
  font-size: 1em;
  border-radius: 4px;
  margin-bottom: 5px;
  background: red;
  color: white;
  cursor: pointer;

  @media (min-width: 1440px) {
    transform: translateX(calc((96vw - 1440px) / 2 + 64px));
  }
  transform: translateX(64px);
`

const YenArt = (props) => {
  const [url, setUrl] = useState('https://www.carousell.sg/value.store/')
  const refresh = () => {
    setUrl('')
    setTimeout(() => setUrl('https://www.carousell.sg/value.store/'), 0)
  }
  return (
    <Window {...props}>
      <BackButton onClick={refresh}>Back</BackButton>
      <iframe
        style={{ background: 'white' }}
        title="yen phang"
        width="100%"
        height="95%"
        src={url}
        frameborder="0"
      ></iframe>
    </Window>
  )
}

export default YenArt
