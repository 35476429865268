import styled from 'styled-components'
import Slide from '../Slide'

const BigText = styled.p`
  text-align: center;
  font-family: Roslindale;
  font-size: 5em;
  color: white;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 30vh;
  width: 100vw;
`

const Scene6 = ({ slideId }) => {
  if (slideId !== 7) return null

  return (
    <Slide>
      <Content>
        <BigText>You have to get up.</BigText>
      </Content>
    </Slide>
  )
}

export default Scene6
