import styled from 'styled-components'
import door from '../../assets/img/prologue-realdoor.png'
import Slide, { SlideText } from '../Slide'
import { NextBtn } from '../../pages/Prologue'
import { useEffect, useState } from 'react'

const StyledImg = styled.img`
  position: fixed;
  top: 20vh;
  left: 25vw;
  width: 900px;
`

const Video = styled.video`
  z-index: 1;
  position: fixed;
  top: calc(20vh + 243px);
  left: calc(25vw + 289px);
  width: 169px;
  border-radius: 10px;
  height: 98px;
  transform: rotate3d(0, 1, 0, -0.15turn);
  clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);
`

const Scene7 = ({ slideId, setSlideId }) => {
  const [proceed, setProceed] = useState(false)
  useEffect(() => {
    if (slideId !== 12) return
    setTimeout(() => {
      setProceed(true)
    }, 35000)
  }, [slideId])

  if (slideId !== 12) return null

  return (
    <Slide>
      <Video
        onEnded={() => setProceed(true)}
        autoPlay
        src="/videos/prologue.mp4"
        type="video/mp4"
      />
      <StyledImg src={door} />

      <NextBtn show onClick={() => setSlideId(13)}>
        {'>'}
      </NextBtn>
      {proceed && <SlideText>It's all a bit much...</SlideText>}
    </Slide>
  )
}

export default Scene7
