import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: 1vh;
  left: 1vw;
  width: 98vw;
  height: 98vh;
  background: linear-gradient(
    0deg,
    rgba(43, 50, 255, 1) 0%,
    rgba(179, 181, 255, 1) 100%
  );

  animation: show ease 0.5s;
  @keyframes show {
    0% {
      transform: scale(0, 0);
      opacity: 0;
    }
    100% {
      tranform: scale(1, 1);
      opacity: 1;
    }
  }
`

const CloseBtn = styled.button`
  width: 15px;
  height: 15px;
  border: none;
  border-radius: 99px;
  background: red;
  margin: 10px 10px 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const Body = styled.div`
  width: 98%;
  height: 92vh;
  margin: 1%;
`

const Window = ({ children, show, onClose = () => {} }) => {
  if (!show) return null

  return (
    <Container>
      <CloseBtn onClick={onClose} />
      <Body>{children}</Body>
    </Container>
  )
}

export default Window
