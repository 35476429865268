import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import useSound from 'use-sound'
import { TextContainer } from '../components/Text'
import BernyArt from '../components/artworks/Berny'
import WeishArt from '../components/artworks/Weish'
import MoreInfo from '../components/MoreInfo'

import ep3Content from '../content/ep3-content'
import bg from '../assets/img/e3-room-bg.jpg'
import postersbg from '../assets/img/e3-posters-bg.jpg'
import Button from '../components/Button'

import bedroom from '../assets/sound/bedroom3.mp3'
import berny from '../assets/sound/berny.mp3'
import loveLockdown from '../assets/sound/love.mp3'
import { useHistory } from 'react-router'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
`
const ImageContainer = styled.div`
  position: fixed;
  width: 100vw;
`
const StyledImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledImageAnim = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: opacityIn ease 1s;

  @keyframes opacityIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const Fixed = styled.div`
  position: fixed;
`

const StyledButton = styled(Button)`
  width: auto;
  font-size: 1.5em;
  background-color: ${(props) => props.theme.colors.hotMagenta};
  color: white;
  animation: fadeIn ease 0.5s;
`
const TapeButton = styled(StyledButton)`
  position: fixed;
  top: 84%;
  left: 33%;
  width: 14%;
  text-align: center;
`
const MusicButton = styled(StyledButton)`
  position: fixed;
  top: 60%;
  left: 33%;
  width: 14%;
  text-align: center;
`

const LaptopButton = styled.button`
  position: fixed;
  bottom: 0;
  right: 25%;
  width: 15%;
  height: 20%;
  background: white;
  opacity: 0;
  box-shadow: 60px -16px teal;
  transition: 0.5s;
  cursor: pointer;
  clip-path: polygon(
    40% 0,
    37% 53%,
    2% 69%,
    50% 100%,
    66% 100%,
    89% 78%,
    95% 10%,
    41% 0
  );

  &:hover {
    opacity: 0.5;
  }
`

const EndingOne = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  padding: 200px 0;

  animation: endingbg1 7s;
  @keyframes endingbg1 {
    0% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const EndingFinal = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  padding: 200px 0;
  opacity: 0;
  animation-name: endingbg2;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-delay: 15s;

  @keyframes endingbg2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const BigText = styled.p`
  width: 50vw;
  margin: 0 25vw;
  text-align: center;
  font-family: Roslindale;
  font-size: 5em;
  color: white;
`
const BigTextFade = styled.p`
  width: 50vw;
  margin: 0 25vw;
  text-align: center;
  font-family: Roslindale;
  font-size: 4em;
  color: white;
  opacity: 0;
  animation-name: endingtext;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-delay: ${(props) => {
    switch (props.i) {
      case 0:
        return '6s'
      case 1:
        return '8s'
      case 2:
        return '10s'
      case 3:
        return '12s'
      default:
        return '0s'
    }
  }};
  @keyframes endingtext {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 700px;
  margin: 0 auto;
`

const FirstButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 80px;
  cursor: pointer;
  border: 1px solid white;
  background: none;
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const SecondButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 80px;
  margin: 0 10px;
  height: 120px;
  cursor: pointer;
  border: none;
  background: ${(props) => props.theme.colors.darkPurple};
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const EpButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  background: ${(props) => props.theme.colors.darkPurple};
  color: white;
  cursor: pointer;
  border: none;
  padding: 20px;
  margin: 20px 4px;
  flex: 1;
  transition: 0.3s;

  animation: fadeIn ease ${(props) => `${1 + props.i * 0.3}s`};

  &:hover {
    background: white;
    color: black;
  }

  @keyframes fadeIn {
    0% {
      transform: translate(0, 40px);
      opacity: 0;
    }
    100% {
      tranform: translate(0);
      opacity: 1;
    }
  }
`

const EpisodeThree = () => {
  const textRef = useRef()
  const [showInfo, setShowInfo] = useState(false)

  const [didSomethingElse, setDidSomethingElse] = useState(false)
  const [secondEvent, setSecondEvent] = useState(false)

  const [showPostersBg, setShowPostersBg] = useState(false)
  const [playBedroom, bedroomTrack] = useSound(bedroom, { loop: true })
  const [playBerny, bernyTrack] = useSound(berny, { loop: true })
  const [playLoveLockdown, loveLockdownTrack] = useSound(loveLockdown, {
    loop: true,
  })

  useEffect(playBedroom, [playBedroom])

  const switchBedroomMusic = () => {
    bernyTrack.stop()
    loveLockdownTrack.stop()
    playBedroom()
  }
  const switchBernyMusic = () => {
    bedroomTrack.stop()
    loveLockdownTrack.stop()
    playBerny()
  }
  const switchLoveLockdownMusic = () => {
    bernyTrack.stop()
    bedroomTrack.stop()
    playLoveLockdown()
  }

  const [showBernyArt, setShowBernyArt] = useState(false)
  const [tapeText, setTapeText] = useState('')
  const tapeBtnCallbackRef = useRef()
  useEffect(() => {
    tapeBtnCallbackRef.current = () => {
      textRef.current.addNode('ethree14')
      setTapeText('')
    }
  }, [])

  const [showWeishArt, setShowWeishArt] = useState(false)
  const [musicText, setMusicText] = useState('')
  const musicBtnCallbackRef = useRef()
  useEffect(() => {
    musicBtnCallbackRef.current = () => {
      textRef.current.addNode('ethree37')
      setMusicText('')
    }
  }, [])

  const [endingOne, setEndingOne] = useState(false)
  const [endingTwo, setEndingTwo] = useState(false)

  const history = useHistory()

  const triggerEvent = (id) => {
    switch (id) {
      case 'playlovelockdownmusic':
        switchLoveLockdownMusic()
        break
      case 'clicktape':
        setTapeText('Pick up the tape.')
        break
      case 'startrecordplayer':
        setMusicText('Start the record player')
        break
      case 'openbernytan':
        setTapeText('Put down the tape.')
        tapeBtnCallbackRef.current = () => {
          setTapeText('')
          setShowBernyArt(true)
        }
        break
      case 'dosomethingelse':
        switchBedroomMusic()
        setDidSomethingElse(true)
        if (secondEvent) {
          setTimeout(() => {
            textRef.current.addNode('ethree54')
          }, 800)
        } else {
          setTimeout(() => {
            textRef.current.addNode('ethree30')
          }, 800)
        }
        break
      case 'secondevent':
        setSecondEvent(true)
        break
      case 'openweish':
        setMusicText('Listen to music.')
        musicBtnCallbackRef.current = () => {
          setMusicText('')
          bedroomTrack.stop()
          loveLockdownTrack.stop()
          bernyTrack.stop()
          setShowWeishArt(true)
        }
        break
      case 'bandposters':
        setShowPostersBg(true)
        switchBernyMusic()
        break
      case 'endethree1':
        ReactGA.event({
          category: 'Completion of episode (DAC S2)',
          label: 'DAC S2 - Completed EP3',
          action: 'Reached the end of S2 EP3',
        })
        setEndingOne(true)
        break
      case 'endethree2':
        ReactGA.event({
          category: 'Completion of episode (DAC S2)',
          label: 'DAC S2 - Completed EP3',
          action: 'Reached the end of S2 EP3',
        })
        setEndingTwo(true)
        break
      default:
        break
    }
  }

  const handleRestart = () => {
    textRef.current.reset()
    ReactGA.event({
      category: 'Completion of episode (DAC S2)',
      label: 'DAC S2- Replayed EP3',
      action: 'Replay (EP3)',
    })
    setEndingOne(false)
    setEndingTwo(false)
    setShowInfo(false)
  }
  const handleReturn = () => {
    setEndingOne(false)
    setEndingTwo(false)
  }
  const handleExit = () => {
    ReactGA.event({
      category: 'Button (DAC S2)',
      label: 'DAC S2 - Exited at end',
      action: 'Clicked the Exit button at end of experience',
    })
  }
  const handleEpClick = (url, category, label, action) => {
    ReactGA.event({ category, label, action })
    history.push(url)
  }

  const finish = secondEvent && didSomethingElse

  return (
    <Container>
      <ImageContainer>
        <StyledImage src={bg} />
        {showPostersBg && <StyledImageAnim src={postersbg} />}
        {Boolean(tapeText) && (
          <TapeButton direction="outgoing" onClick={tapeBtnCallbackRef.current}>
            {tapeText}
          </TapeButton>
        )}
        {Boolean(musicText) && (
          <MusicButton
            direction="outgoing"
            onClick={musicBtnCallbackRef.current}
          >
            {musicText}
          </MusicButton>
        )}
        <LaptopButton
          onClick={() => {
            if (endingOne || showWeishArt || showBernyArt) return
            ReactGA.event({
              category: 'Click (DAC S2)',
              label: 'DAC S2 - Clicked laptop',
              action: 'Clicked S2 laptop',
            })
            setShowInfo(true)
          }}
        />
      </ImageContainer>
      <Fixed>
        <TextContainer
          contentObj={ep3Content}
          ref={textRef}
          triggerEvent={triggerEvent}
          finish={finish}
        />
      </Fixed>
      <MoreInfo show={showInfo} onClose={() => setShowInfo(false)} />

      <BernyArt
        show={showBernyArt}
        onClose={() => {
          setShowBernyArt(false)
          textRef.current.addNode('ethree17')
        }}
      />
      <WeishArt
        show={showWeishArt}
        onClose={() => {
          setShowWeishArt(false)
          playBedroom()
          textRef.current.addNode('ethree40')
        }}
      />

      {(endingOne || endingTwo) && (
        <>
          {endingOne && (
            <EndingOne>
              <BigTextFade i={0}>
                Wandering the streets all through the night
              </BigTextFade>
              <BigTextFade i={1}>
                Searching for the one to make me right
              </BigTextFade>
              <BigTextFade i={2}>
                Wondering if she's the shade of you
              </BigTextFade>
              <BigTextFade i={3}>
                And if so, should I try to see it through?
              </BigTextFade>
            </EndingOne>
          )}
          {endingTwo && (
            <EndingOne>
              <BigTextFade i={0}>Can we afford to be kinder</BigTextFade>
              <BigTextFade i={1}>To others and ourselves</BigTextFade>
              <BigTextFade i={2}>There's always room at the table</BigTextFade>
              <BigTextFade i={3}>And water from the well</BigTextFade>
            </EndingOne>
          )}
          <EndingFinal>
            <BigText>Next episode launches at 12/03/2021, 00:00</BigText>
            <Row>
              <FirstButton onClick={handleRestart}>RESTART</FirstButton>
              <FirstButton onClick={handleReturn}>BEDROOM</FirstButton>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4yBXfROmiLd0UjjdoIYoWwZEptel5sKUHnDhBhamEuQJUXg/viewform?usp=sf_link">
                <SecondButton>GIVE FEEDBACK</SecondButton>
              </a>
              <a href="https://ohopenhouse.give.asia/">
                <SecondButton onClick={handleExit}>EXIT</SecondButton>
              </a>
            </Row>
            <Row>
              <EpButton
                i={1}
                onClick={() =>
                  handleEpClick(
                    '/prologue',
                    'Button (DAC S2)',
                    'DAC S2 - Entered Episode 1 from another ep',
                    'Clicked to enter Episode 1 from end of an ep',
                  )
                }
              >
                Episode One
              </EpButton>
              <EpButton
                i={2}
                onClick={() =>
                  handleEpClick(
                    '/ep2',
                    'Button (DAC S2)',
                    'DAC S2 - Entered Episode 2 from another ep',
                    'Clicked to enter Episode 2 from end of an ep',
                  )
                }
              >
                Episode Two
              </EpButton>
              <EpButton
                i={3}
                onClick={() =>
                  handleEpClick(
                    '/ep4',
                    'Button (DAC S2)',
                    'DAC S2 - Entered Episode 4 from another ep',
                    'Clicked to enter Episode 4 from end of an ep',
                  )
                }
              >
                Episode Four
              </EpButton>
            </Row>
          </EndingFinal>
        </>
      )}
    </Container>
  )
}

export default EpisodeThree
