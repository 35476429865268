import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import couch from '../../assets/img/prologue-realcouch.png'
import Slide, { SlideText } from '../Slide'

const Button = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 20px 60px;
  cursor: pointer;
  border: 1px solid white;
  background: none;
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const BedroomBtn = styled(Button)`
  position: fixed;
  top: 20vh;
  left: 25vw;
`
const StyledImg = styled.img`
  position: fixed;
  top: 20vh;
  width: 900px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Scene7 = ({ slideId, setSlideId }) => {
  const history = useHistory()
  if (slideId !== 13) return null

  return (
    <Slide>
      <Content>
        <StyledImg src={couch} />
      </Content>
      <BedroomBtn onClick={() => history.push('/ep1')}>
        Head to the bedroom.
      </BedroomBtn>

      <SlideText>You...</SlideText>
    </Slide>
  )
}

export default Scene7
