import styled from 'styled-components'
import logo from '../assets/img/dac-logo.svg'
import background from '../assets/img/password-bg.png'

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: black;
`

const Logo = styled.img`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 200px;
`
const Bg = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const PasswordContainer = styled.div`
  background: black;
  border: 1px solid white;
  position: fixed;
  left: 50vw;
  bottom: 20vh;
  width: 50vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  animation: fadeIn ease 1s;
  @keyframes fadeIn {
    0% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      tranform: translate(0);
      opacity: 1;
    }
  }
`

const InputHeader = styled.label`
  font-family: Roslindale;
  font-size: 3em;
  color: white;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
`
const InputSubheader = styled.label`
  font-family: Cirka;
  font-size: 1.2em;
  color: white;
  text-align: left;
  margin-bottom: 20px;
`

const InputContainer = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  background: black;
  margin-bottom: 20px;
  border: 1px solid white;
`

const PasswordInput = styled.input`
  flex: 1;
  font-size: 1.5em;
  padding: 0 10px;
  border: none;
  background: none;
  color: white;
  display: flex;
  align-items: center;

  &::placeholder {
    font-size: 0.8em;
    font-family: Cirka;
    color: white;
  }
  &:focus {
    outline: none;
  }
`

const SubmitBtn = styled.button`
  font-size: 1.2em;
  background: none;
  border: none;
  width: 60px;
  cursor: pointer;
  color: white;
  transition: 0.5s;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scaleX(1.6) translateX(-7px);
  }
`

const Login = ({ password, setPassword, handleAuth }) => (
  <Container>
    <Bg src={background} />
    <Logo src={logo} />
    <PasswordContainer>
      <InputHeader>Start your next adventure.</InputHeader>
      <InputSubheader>
        All episodes of Days — and counting: The distance between us will be
        available online from 19 March - 30 April 2021. You are welcome to
        return to Sleeping Man’s bedroom at any time and revisit the artworks
        and episodes or catch those you missed.
        <br />
        <br />
        <i>E1: Reality & Rest</i> features works from Denise Yap and Lynn Lu.
        <br />
        <br />
        <i>E2: Home Alone</i> features a work from Ezzam Rahman.
        <br />
        <br />
        <i>E3: Distance Kept, Distance Bridged</i> features a work by Berny Tan
        and a collaborative work by Churen Li, Hell Low, Subhas, Tim De Cotta
        and weish.
        <br />
        <br />
        <i>E4: Working Through, Making Do</i>  features a work by Yen Phang and
        a collaborative work by Tan Kheng Hua, Bailey Wait, Lim Shi-An and
        Robert Wait.
      </InputSubheader>
      <InputContainer>
        <PasswordInput
          placeholder="ENTER PASSWORD HERE"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></PasswordInput>
        <SubmitBtn onClick={handleAuth}>{'⟶'}</SubmitBtn>
      </InputContainer>
      <InputSubheader>
        If you find that your screen size is too small, zoom out within your
        browser to your preference for the best viewing experience.
        <br />
        For PC users, you may do so by using Ctrl key + "-".
        <br />
        For Mac users, you may do so by using Command key + "-"
      </InputSubheader>
    </PasswordContainer>
  </Container>
)

export default Login
