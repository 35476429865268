import Window from '../Window'

const WeishArt = (props) => (
  <Window {...props}>
    <iframe
      title="weish"
      width="100%"
      height="100%"
      style={{ border: 'none' }}
      src="https://player.vimeo.com/video/504319163?autoplay=1"
    ></iframe>
  </Window>
)

export default WeishArt
