const content = [
  {
    id: 'etwo1',
    direction: 'incoming',
    text: 'You find yourself in your room after a long day.',
    next: 'etwo2',
  },
  {
    id: 'etwo2',
    direction: 'incoming',
    text: 'Work, eat, nap, work, work out, eat.',
    next: 'etwo3',
  },
  {
    id: 'etwo3',
    direction: 'incoming',
    text:
      'You spent it all at home. This place you’ve lived in for about a year now.',
    next: 'etwo4',
  },
  {
    id: 'etwo4',
    direction: 'incoming',
    text:
      'Getting the house was a major milestone. An entire space to call your own now, not just a room.',
    next: 'etwo5',
  },
  {
    id: 'etwo5',
    direction: 'incoming',
    text: 'It’s spacious - living room, bedroom, kitchen.',
    next: 'etwo6',
  },
  {
    id: 'etwo6',
    direction: 'incoming',
    text:
      'For a little while, the living room was your favourite space. Wonderful for game nights and dinner parties.',
    next: 'etwo7',
  },
  {
    id: 'etwo7',
    direction: 'incoming',
    text:
      'Now, the dining table is used as much for work as it is for gatherings.',
    next: 'etwo8',
  },
  {
    id: 'etwo8',
    direction: 'incoming',
    text: '…',
    next: 'etwo9',
  },
  {
    id: 'etwo9',
    direction: 'incoming',
    text: 'Today, no one is coming over, and you have little work to do.',
    next: 'etwo10',
  },
  {
    id: 'etwo10',
    direction: 'incoming',
    text: 'So you look for something to do … or … eat?',
    options: [
      { text: 'Eat.', to: 'etwo12' },
      { text: 'Do.', to: 'etwo23' },
    ],
  },
  {
    id: 'etwo12',
    direction: 'outgoing',
    text: 'Eat.',
    auto: true,
    next: 'etwo13',
  },
  {
    id: 'etwo13',
    direction: 'incoming',
    text: 'Love letters. Why are those in your room?',
    next: 'etwo14',
  },
  {
    id: 'etwo14',
    direction: 'incoming',
    text: "You promised yourself you wouldn't leave snacks around anymore.",
    next: 'etwo15',
  },
  {
    id: 'etwo15',
    direction: 'incoming',
    text: 'But hmm, you could do with a snack.',
    externalEvent: 'eatloveletters',
  },
  {
    id: 'etwo16',
    direction: 'incoming',
    text: 'Yum.',
    next: 'etwo17',
  },
  {
    id: 'etwo17',
    direction: 'incoming',
    text: 'These love letters, made by your Popo, are your favourite snacks.',
    next: 'etwo18',
  },
  {
    id: 'etwo18',
    direction: 'incoming',
    text:
      'You swear she has a secret ingredient that makes her version the best. But you haven’t figured out what it is yet.',
    next: 'etwo19',
  },
  {
    id: 'etwo19',
    direction: 'incoming',
    text:
      'You used to love visiting her home as a child. Just because you knew she would have made these love letters.',
    next: 'etwo20',
  },
  {
    id: 'etwo20',
    direction: 'incoming',
    text:
      'She never needed a special occasion to make them. Your visit was reason enough.',
    next: 'etwo21',
  },
  {
    id: 'etwo21',
    direction: 'incoming',
    text: 'Now, when you visit, she gives you some to take home with you.',
    next: 'etwo22',
  },
  {
    id: 'etwo22',
    direction: 'incoming',
    text: '…',
    next: 'etwo24',
  },
  {
    id: 'etwo23',
    direction: 'outgoing',
    text: 'Do.',
    auto: true,
    next: 'etwo24',
  },
  {
    id: 'etwo24',
    direction: 'incoming',
    text: 'Bzzz bzzz',
    next: 'etwo25',
  },
  {
    id: 'etwo25',
    direction: 'incoming',
    text: 'A flash of white light.',
    next: 'etwo26',
  },
  {
    id: 'etwo26',
    direction: 'incoming',
    text: 'Who could that be?',
    next: 'etwo27',
  },
  {
    id: 'etwo27',
    direction: 'incoming',
    text: 'Oh, it’s Daniel.',
    externalEvent: 'openphone',
  },

  // PHONE CONVERSATION

  {
    id: 'etwo37a',
    direction: 'incoming',
    text: 'You send the last text message and wait for a reply.',
    next: 'etwo38a',
  },
  {
    id: 'etwo38a',
    direction: 'incoming',
    text: 'Your friend takes a little while. Your mind wanders.',
    next: 'etwo39a',
  },
  {
    id: 'etwo39a',
    direction: 'incoming',
    text: 'You decide to...',
    options: [
      { text: 'Send another message.', to: 'etwo40aa' },
      { text: 'Wrap up the conversation.', to: 'etwo40ab' },
    ],
  },
  {
    id: 'etwo40aa',
    direction: 'outgoing',
    text: 'Send another message.',
    externalEvent: 'phone-etwo41',
  },
  {
    id: 'etwo40ab',
    direction: 'outgoing',
    text: 'Wrap up the conversation',
    externalEvent: 'phone-etwoxa0',
  },

  // PHONE CONVERSATION

  {
    id: 'etwo37b',
    direction: 'incoming',
    text: 'You send the last text message and wait for a reply.',
    next: 'etwo38b',
  },
  {
    id: 'etwo38b',
    direction: 'incoming',
    text: 'Your friend takes a little while. Your mind wanders.',
    next: 'etwo39b',
  },
  {
    id: 'etwo39b',
    direction: 'incoming',
    text: 'You decide to...',
    options: [
      { text: 'Send another message.', to: 'etwo40ba' },
      { text: 'Wrap up the conversation.', to: 'etwo40bb' },
    ],
  },
  {
    id: 'etwo40ba',
    direction: 'outgoing',
    text: 'Send another message.',
    externalEvent: 'phone-etwo43',
  },
  {
    id: 'etwo40bb',
    direction: 'outgoing',
    text: 'Wrap up the conversation.',
    externalEvent: 'phone-etwoxb0',
  },

  // PHONE STUFF

  {
    id: 'etwoy0',
    direction: 'incoming',
    text:
      'You consider this last message, deciding to respond in a little while.',
    next: 'etwoy1',
  },
  {
    id: 'etwoy1',
    direction: 'incoming',
    text: 'You need a little time to process the work and the conversation.',
    next: 'etwoy2',
  },
  {
    id: 'etwoy2',
    direction: 'incoming',
    text: '…',
    next: 'etwoy3',
  },
  {
    id: 'etwoy3',
    direction: 'incoming',
    text:
      'Your mind wanders, and you find yourself thinking instead about how good it was to chat with Daniel again.',
    next: 'etwoz0',
  },
  {
    id: 'etwoz0',
    direction: 'incoming',
    text:
      'All this talk about loneliness and distance reminds you that it’s been a while since you’ve really met up with your friends.',
    next: 'etwoz1',
    externalEvent: 'playbedroommusic',
  },
  {
    id: 'etwoz1',
    direction: 'incoming',
    text:
      'Between the pandemic and your freelance life, it got harder to coordinate meetups.',
    next: 'etwoz2',
  },
  {
    id: 'etwoz2',
    direction: 'incoming',
    text:
      'You did a couple of zoom game nights and chats, but they only get so far. Zoom dates were worse. Super awkward.',
    next: 'etwoz3',
  },
  {
    id: 'etwoz3',
    direction: 'incoming',
    text: 'You miss being out and about with other people.',
    next: 'etwoz4',
  },
  {
    id: 'etwoz4',
    direction: 'incoming',
    text:
      'Things aren’t ‘normal’ yet, but you really should take advantage of the fact that you can go out. And just make that date with your friends.',
    next: 'etwoz5',
  },
  {
    id: 'etwoz5',
    direction: 'incoming',
    text: 'BZZ BZZZ.',
    externalEvent: 'phone-etwoz6',
  },
]

export default content
