import Slide, { SlideText } from '../Slide'
import clock from '../../assets/img/prologue-clock.png'
import clockNegative from '../../assets/img/prologue-clock-negative.png'
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'

const StyledImg = styled.img`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 60vh;
`
const StyledMask = styled.img.attrs((props) => {
  let clipPath = ''
  for (let i = 0; i <= 20; i++) {
    clipPath += `
      ${(props.offset || 0) - 1000 + i * 200}px 0,
      ${(props.offset || 0) - 1000 - (props.widen || 0) + i * 200}px 100%,
      ${(props.offset || 0) - 1000 + i * 200}px 100%,
      ${(props.offset || 0) - 1000 + (props.widen || 0) + i * 200}px 0${
      i === 20 ? '' : ','
    }`
  }
  return {
    style: {
      clipPath: `polygon(${clipPath})`,
    },
  }
})`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 60vh;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Scene5 = ({ slideId }) => {
  const [offset, setOffset] = useState(0)
  const [widen, setWiden] = useState(0)
  const direction = useRef(true)
  useEffect(
    () =>
      setInterval(() => {
        setOffset((prev) => (prev < 200 * 5 ? prev + 0.5 : 0))
        setWiden((prev) => {
          if (prev > 200) direction.current = false
          if (prev < 0) direction.current = true
          return direction.current ? prev + 0.05 : prev - 0.05
        })
      }, 16),
    [],
  )

  if (slideId !== 6) return null

  return (
    <Slide>
      <Content>
        <StyledImg src={clock} />
        <StyledMask offset={offset} widen={widen} src={clockNegative} />
      </Content>
      <SlideText>Time passes, you fall deeply asleep.</SlideText>
    </Slide>
  )
}

export default Scene5
