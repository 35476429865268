import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from 'react'
import styled, { css } from 'styled-components'
import phonebg from '../assets/img/e2-phone.png'
import artbg from '../assets/img/e2-phone-bg.png'
import phoneContent from '../content/ep2-phone'

const Container = styled.div`
  position: fixed;
  height: 90vh;
  top: 5vh;
  left: 40vw;

  animation: phoneIn ease-in-out 3s;
  @keyframes phoneIn {
    0% {
      transform: translateX(-50vw) translateY(100vh) rotate(-90deg);
    }
    100% {
      transform: translateX(0) translateY(0) rotate(0deg);
    }
  }
`
const PhoneImg = styled.img`
  height: 100%;
  pointer-events: none;
`
const PhoneTextContainer = styled.div`
  position: absolute;
  height: 69.5%;
  top: 16%;
  width: 80%;
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const PhoneText = styled.div`
  padding: 10px;
  margin: 7px 0;
  width: 250px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.direction === 'outgoing' ? '#00B8E6' : 'white'};
  align-self: ${(props) =>
    props.direction === 'outgoing' ? 'flex-end' : 'flex-start'};

  ${(props) =>
    props.styling === 'link' &&
    css`
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    `}

  animation: messageIn 0.5s;
  @keyframes messageIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const ChoiceBtn = styled.button`
  font-family: BarlowRegular;
  margin: 4px 0;
  padding: 4px 5px;
  background: white;
  border: 1px solid #00b8e6;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;

  &:hover {
    background: #00b8e6;
    color: white;
    border: 1px solid white;
  }
`

const EzzamContainer = styled.div`
  position: absolute;
  top: 8%;
  margin: 0 8%;
  height: 84%;
  width: 84%;
  background: white;
  border: none;

  animation: ezzamIn 1s;
  @keyframes ezzamIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
const PhoneFiller = styled.div`
  z-index: -1;
  position: absolute;
  top: 3%;
  margin: 0 6%;
  height: 94%;
  width: 88%;
  border-radius: 24px;
  background: white;
`
const CloseBtn = styled.button`
  position: fixed;
  top: 0;
  right: 20px;
  font-size: 8em;
  color: white;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`

const Phone = forwardRef(
  (
    { show, mainAddNode, stopBedroomMusic, playCurationMusic, endEpisode },
    ref,
  ) => {
    const [shownContent, setShownContent] = useState([])
    const [showEzzamArt, setShowEzzamArt] = useState(false)

    useEffect(() => {
      if (show) {
        setTimeout(() => addNode('etwo28'), 3000)
      } else {
        setShownContent([])
      }
      // eslint-disable-next-line
    }, [show])

    const textsEndRef = useRef(null)
    const scrollToBottom = () => {
      try {
        textsEndRef.current.scrollIntoView({ behavior: 'smooth' })
      } catch (e) {
        //
      }
    }
    useEffect(() => setTimeout(scrollToBottom, 50), [shownContent])

    const addNode = useCallback(
      (id) => {
        const triggerEvent = (event) => {
          switch (event) {
            case 'playcurationmusic':
              playCurationMusic()
              break
            case 'main-etwo37a':
              mainAddNode('etwo37a')
              break
            case 'main-etwo37b':
              mainAddNode('etwo37b')
              break
            case 'main-etwoy0':
              mainAddNode('etwoy0')
              break
            case 'main-etwoz0':
              mainAddNode('etwoz0')
              break
            case 'endetwo':
              endEpisode()
              break
            default:
              break
          }
        }

        const newNode = phoneContent.find((node) => node.id === id)
        if (!newNode) return setShownContent([phoneContent[0]])
        setShownContent((prev) => [...prev, newNode])
        const nextNode = phoneContent.find((node) => node.id === newNode.next)
        if (newNode.externalEvent) {
          setTimeout(() => {
            triggerEvent(newNode.externalEvent)
          }, 3000)
        }
        if (!nextNode) return
        setTimeout(() => {
          addNode(nextNode.id)
        }, Math.round(Math.random() * 2000) + 2000)
      },
      [mainAddNode, playCurationMusic, endEpisode],
    )

    useImperativeHandle(ref, () => ({
      addNode,
      reset: () => setShownContent([]),
    }))

    const handleCloseArt = () => {
      setShowEzzamArt(false)
      setTimeout(() => {
        addNode('etwo33')
      }, 1000)
    }

    if (!show) return null

    return (
      <Container>
        <link rel="preload" as="image" href={artbg}></link>
        {showEzzamArt ? (
          <>
            <PhoneImg preload src={artbg} />
            <EzzamContainer>
              <iframe
                title="ezzam"
                width="100%"
                height="100%"
                frameBorder="none"
                src="https://ohopenhouse.online/ezzam-draft-20210220/"
              ></iframe>
            </EzzamContainer>
            <PhoneFiller />
            <CloseBtn onClick={handleCloseArt}>×</CloseBtn>
          </>
        ) : (
          <>
            <PhoneImg src={phonebg} />
            <PhoneTextContainer>
              {shownContent.map((node, i) => {
                const isLastNode =
                  node.id === shownContent[shownContent.length - 1].id
                return (
                  <PhoneText
                    key={node.text + i}
                    styling={node.style}
                    direction={node.direction}
                    onClick={() => {
                      if (node.id === 'etwo32' && isLastNode) {
                        stopBedroomMusic()
                        setShowEzzamArt(true)
                      }
                    }}
                  >
                    {node.text}
                    <br />
                    {node.options &&
                      isLastNode &&
                      node.options.map((opt) => (
                        <>
                          <ChoiceBtn
                            key={opt.text}
                            onClick={() => addNode(opt.to)}
                          >
                            {opt.text}
                          </ChoiceBtn>
                          <br />
                        </>
                      ))}
                  </PhoneText>
                )
              })}
              <div ref={textsEndRef} />
            </PhoneTextContainer>
          </>
        )}
      </Container>
    )
  },
)

export default Phone
