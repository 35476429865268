import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { ThemeProvider } from 'styled-components'
import Prologue from './pages/Prologue'
import EpisodeOne from './pages/ep1'
import EpisodeTwo from './pages/ep2'
import EpisodeThree from './pages/ep3'
import EpisodeFour from './pages/ep4'
import GlobalStyle, { theme } from './globalStyle'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './components/Login'
import Selection from './components/Selection'

ReactGA.initialize('UA-57911784-3')

const App = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    window.location.replace(
      'https://ohopenhouse.online/daysandcounting/about.html',
    )
  }

  useEffect(() => {
    ReactGA.pageview('/')
  }, [])

  const [auth, setAuth] = useState(false)
  const [password, setPassword] = useState('')

  const handleAuth = () => {
    if (password === 'congrats you found this') setAuth(true)
    if (password === 'w4keUp305') {
      setAuth(true)
      ReactGA.event({
        category: 'Button (DAC S2)',
        label: 'DAC S2 - Successful password entry',
        action: 'Entered S2 experience with correct password',
      })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {auth ? (
        <Router>
          <Switch>
            <Route exact path="/">
              <Selection />
            </Route>
            <Route exact path="/prologue">
              <Prologue />
            </Route>
            <Route exact path="/ep1">
              <EpisodeOne />
            </Route>
            <Route exact path="/ep2">
              <EpisodeTwo />
            </Route>
            <Route exact path="/ep3">
              <EpisodeThree />
            </Route>
            <Route exact path="/ep4">
              <EpisodeFour />
            </Route>
          </Switch>
        </Router>
      ) : (
        <Login
          password={password}
          setPassword={setPassword}
          handleAuth={handleAuth}
        />
      )}
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default App
