const phoneContent = [
  {
    id: 'etwo28',
    direction: 'incoming',
    text: 'Hey, I thought you would like this. Since you like art haha.',
    next: 'etwo29',
  },
  {
    id: 'etwo29',
    direction: 'incoming',
    text: 'And also since you have your own house lol.',
    next: 'etwo30',
  },
  {
    id: 'etwo30',
    direction: 'incoming',
    text:
      'Quite nice to explore and click through things. Also trying to understand who these strangers are haha.',
    next: 'etwo31',
  },
  {
    id: 'etwo31',
    direction: 'incoming',
    text: 'Text me back once you’ve seen it!',
    next: 'etwo32',
  },
  {
    id: 'etwo32',
    direction: 'incoming',
    text: 'Link',
    style: 'link',
  },
  {
    id: 'etwo33',
    direction: 'outgoing',
    text: "Hey, it's pretty cool.",
    next: 'etwo34',
    externalEvent: 'playcurationmusic',
  },
  {
    id: 'etwo34',
    direction: 'incoming',
    text: 'Glad you had some time to look at it. What did you think?',
    next: 'etwo35',
  },
  {
    id: 'etwo35',
    direction: 'outgoing',
    text: 'Hmm.',
    options: [
      {
        text: 'I felt like I was getting to know someone new.',
        to: 'etwo36a0',
      },
      {
        text:
          'I felt super weird, like I was intruding upon the lives of strangers. ',
        to: 'etwo36b0',
      },
    ],
  },
  {
    id: 'etwo36a0',
    direction: 'outgoing',
    text: 'I felt like I was getting to know someone new.',
    next: 'etwo36a1',
  },
  {
    id: 'etwo36a1',
    direction: 'incoming',
    text: 'Like how?',
    next: 'etwo36a2',
  },
  {
    id: 'etwo36a2',
    direction: 'outgoing',
    text: 'You know how you get to know someone...',
    options: [
      {
        text: 'Slowly, over the course of many conversations online?',
        to: 'etwo36aa0',
      },
      {
        text: 'Intimately, during a long afternoon spent at their home?',
        to: 'etwo36ab0',
      },
    ],
  },
  {
    id: 'etwo36aa0',
    direction: 'outgoing',
    text: 'Slowly, over the course of many conversations online.',
    next: 'etwo36aa1',
  },
  {
    id: 'etwo36aa1',
    direction: 'outgoing',
    text:
      'You know when you have a long text convo with someone. And you keep sending things back and forth. Videos, photos, random links.',
    next: 'etwo36aa2',
  },
  {
    id: 'etwo36aa2',
    direction: 'outgoing',
    text: 'Sharing anything and everything. And learning about each other.',
    next: 'etwo36aa3',
  },
  {
    id: 'etwo36aa3',
    direction: 'outgoing',
    text: 'It felt like that.',
    next: 'etwo36aa4',
  },
  {
    id: 'etwo36aa4',
    direction: 'incoming',
    text: "Hahaha. That's interesting, pls tell me more!",
    next: 'etwo36aa5',
  },
  {
    id: 'etwo36aa5',
    direction: 'outgoing',
    text:
      'You know how these convos just jump all over the place. And you find out all these random things about someone.',
    next: 'etwo36aa6',
  },
  {
    id: 'etwo36aa6',
    direction: 'outgoing',
    text:
      'I felt like the work was a bit like that. Jump here, jump there. From photo to video to different websites suddenly. ',
    next: 'etwo36aa7',
  },
  {
    id: 'etwo36aa7',
    direction: 'outgoing',
    text: 'Each time you learn something new, but never their whole story.',
    next: 'etwo36aa8',
  },
  {
    id: 'etwo36aa8',
    direction: 'outgoing',
    text:
      'But this is also like life, I guess. When you text your friends, it’s always chaotic. You’re sharing stuff, part of 3 convos at once, everyone has different ideas and feelings.',
    next: 'etwo36aa9',
  },
  {
    id: 'etwo36aa9',
    direction: 'outgoing',
    text:
      'And then you just kinda pick and choose which thread you wanna follow.',
    externalEvent: 'main-etwo37a',
  },
  {
    id: 'etwo36ab0',
    direction: 'outgoing',
    text: 'Intimately, during a long afternoon spent at their home?',
    next: 'etwo36ab1',
  },
  {
    id: 'etwo36ab1',
    direction: 'outgoing',
    text: 'I love going to people’s houses lol.',
    next: 'etwo36ab2',
  },
  {
    id: 'etwo36ab2',
    direction: 'outgoing',
    text: 'It’s like a level-up in your friendship.',
    next: 'etwo36ab3',
  },
  {
    id: 'etwo36ab3',
    direction: 'outgoing',
    text:
      'And you learn about them through how their house looks like, or the things they keep.',
    next: 'etwo36ab4',
  },
  {
    id: 'etwo36ab4',
    direction: 'outgoing',
    text: 'It felt like that.',
    next: 'etwo36ab5',
  },
  {
    id: 'etwo36ab5',
    direction: 'incoming',
    text: "Hahaha. That's interesting, pls tell me more!",
    next: 'etwo36ab6',
  },
  {
    id: 'etwo36ab6',
    direction: 'outgoing',
    text:
      'Because so much of the work focused on these people’s spaces, it felt like I was ‘visiting’ them.',
    next: 'etwo36ab7',
  },
  {
    id: 'etwo36ab7',
    direction: 'outgoing',
    text:
      "Did you see the videos, the ones in which you get a glimpse of all these strangers' lives?",
    next: 'etwo36ab8',
  },
  {
    id: 'etwo36ab8',
    direction: 'outgoing',
    text:
      'It’s the kind of thing that you wouldn’t see unless you spend time with them in their house. Quite intimate.',
    next: 'etwo36ab9',
  },
  {
    id: 'etwo36ab9',
    direction: 'outgoing',
    text:
      'I mean I know I’m not there … but for a moment, it feels like you’re just sharing time and space with them.',
    externalEvent: 'main-etwo37a',
  },

  // SOME MAIN STUFF

  {
    id: 'etwo36b0',
    direction: 'outgoing',
    text:
      'I felt super weird, like I was intruding upon the lives of strangers.',
    next: 'etwo36b1',
  },
  {
    id: 'etwo36b1',
    direction: 'incoming',
    text: 'Lol. Why did you put it that way? ',
    next: 'etwo36b2',
  },
  {
    id: 'etwo36b2',
    direction: 'outgoing',
    text: 'Hmm...',
    options: [
      {
        text: 'Just the intimacy of it all.',
        to: 'etwo36ba0',
      },
      {
        text: 'The voyeurism of it all.',
        to: 'etwo36bb0',
      },
    ],
  },
  {
    id: 'etwo36ba0',
    direction: 'outgoing',
    text: 'Just the intimacy of it all.',
    next: 'etwo36ba1',
  },
  {
    id: 'etwo36ba1',
    direction: 'outgoing',
    text: "Idek who these people are and yet, I've seen their bedrooms.",
    next: 'etwo36ba2',
  },
  {
    id: 'etwo36ba2',
    direction: 'incoming',
    text: 'I felt like the intimacy was part of the point tho.',
    next: 'etwo36ba3',
  },
  {
    id: 'etwo36ba3',
    direction: 'incoming',
    text: 'That we are supposed to share their space.',
    next: 'etwo36ba4',
  },
  {
    id: 'etwo36ba4',
    direction: 'incoming',
    text: 'Find out more about them. Even if it is only for a moment.',
    next: 'etwo36bz',
  },
  {
    id: 'etwo36bb0',
    direction: 'outgoing',
    text: 'The voyeurism of it all.',
    next: 'etwo36bb1',
  },
  {
    id: 'etwo36bb1',
    direction: 'outgoing',
    text: 'Idek who these people are and yet, I’ve seen their bedrooms.',
    next: 'etwo36bb2',
  },
  {
    id: 'etwo36bb2',
    direction: 'incoming',
    text: 'But perhaps that was part of the point.',
    next: 'etwo36bb3',
  },
  {
    id: 'etwo36bb3',
    direction: 'incoming',
    text: 'To allow us to connect with someone new and hear their stories.',
    next: 'etwo36bz',
  },
  {
    id: 'etwo36bz',
    direction: 'outgoing',
    text: 'I know what you mean but',
    options: [
      {
        text:
          'We never really met on equal terms. They are still strangers to me.',
        to: 'etwo36bza',
      },
      {
        text:
          'Still feels odd because it was rather a vulnerable position for them to be in.',
        to: 'etwo36bzb',
      },
    ],
  },
  {
    id: 'etwo36bza',
    direction: 'outgoing',
    text: 'We never really met on equal terms. They are still strangers to me.',
    externalEvent: 'main-etwo37b',
  },
  {
    id: 'etwo36bzb',
    direction: 'outgoing',
    text:
      'Still feels odd because it was rather a vulnerable position for them to be in.',
    externalEvent: 'main-etwo37b',
  },

  // MAIN STUFF

  {
    id: 'etwo41',
    direction: 'outgoing',
    text: 'I liked the photographs.',
    options: [
      {
        text: 'The colourful ones.',
        to: 'etwo42a0',
      },
      {
        text: 'The black and white ones.',
        to: 'etwo42b0',
      },
    ],
  },
  {
    id: 'etwo42a0',
    direction: 'outgoing',
    text: 'The colourful ones.',
    next: 'etwo42a1',
  },
  {
    id: 'etwo42a1',
    direction: 'outgoing',
    text: 'With men holding up things lol.',
    next: 'etwo42a2',
  },
  {
    id: 'etwo42a2',
    direction: 'outgoing',
    text: 'I wonder why they chose what they chose?',
    next: 'etwo42a3',
  },
  {
    id: 'etwo42a3',
    direction: 'outgoing',
    text: 'I noticed the..',
    options: [
      {
        text: 'Sentimental things first.',
        to: 'etwo42aa0',
      },
      {
        text: 'Useful things first.',
        to: 'etwo42ab0',
      },
    ],
  },
  {
    id: 'etwo42aa0',
    direction: 'outgoing',
    text: 'Sentimental things first.',
    next: 'etwo42aa1',
  },
  {
    id: 'etwo42aa1',
    direction: 'outgoing',
    text: 'I saw them as something beloved.',
    next: 'etwo42aa2',
  },
  {
    id: 'etwo42aa2',
    direction: 'outgoing',
    text: 'The stuffed toys, the old photograph, the cat.',
    next: 'etwo42aa3',
  },
  {
    id: 'etwo42aa3',
    direction: 'outgoing',
    text: 'They must offer comfort, joy, a happy memory.',
    next: 'etwo42aa4',
  },
  {
    id: 'etwo42aa4',
    direction: 'incoming',
    text:
      'I saw them differently. Maybe because I noticed the useful things first.',
    next: 'etwo42aa5',
  },
  {
    id: 'etwo42aa5',
    direction: 'incoming',
    text: 'The toothbrush. Essential oils. Knife.',
    next: 'etwo42aa6',
  },
  {
    id: 'etwo42aa6',
    direction: 'incoming',
    text:
      'Maybe our experience being stuck at home as we wait out the pandemic affected this too.',
    next: 'etwo42aa7',
  },
  {
    id: 'etwo42aa7',
    direction: 'incoming',
    text:
      'There is only room for so much, and the things we surround ourselves matter.',
    externalEvent: 'main-etwoy0',
  },
  //

  {
    id: 'etwo42ab0',
    direction: 'outgoing',
    text: 'Useful things first.',
    next: 'etwo42ab1',
  },
  {
    id: 'etwo42ab1',
    direction: 'outgoing',
    text: 'I noticed the toothbrush first. Then essential oils, a book.',
    next: 'etwo42ab2',
  },
  {
    id: 'etwo42ab2',
    direction: 'outgoing',
    text: 'Stuff that’s part of their everyday routines.',
    next: 'etwo42ab3',
  },
  {
    id: 'etwo42ab3',
    direction: 'outgoing',
    text:
      'There’s someone who held up essential oils. Not one, not two, but many. I think he had a diffuser in his room.',
    next: 'etwo42ab4',
  },
  {
    id: 'etwo42ab4',
    direction: 'outgoing',
    text: 'Perhaps it’s the thing that helps him get through the day lol.',
    next: 'etwo42ab5',
  },
  {
    id: 'etwo42ab5',
    direction: 'incoming',
    text: 'I noticed the stuffed toys, the old photograph, and the cat.',
    next: 'etwo42ab6',
  },
  {
    id: 'etwo42ab6',
    direction: 'incoming',
    text: 'And it made me think that they were holding up something beloved.',
    next: 'etwo42ab7',
  },
  {
    id: 'etwo42ab7',
    direction: 'incoming',
    text: 'Something that makes home feel like home.',
    externalEvent: 'main-etwoy0',
  },
  //

  {
    id: 'etwo42b0',
    direction: 'outgoing',
    text: 'The black and white ones.',
    next: 'etwo42b1',
  },
  {
    id: 'etwo42b1',
    direction: 'outgoing',
    text: 'The ones that you only find after you click something.',
    next: 'etwo42b2',
  },
  {
    id: 'etwo42b2',
    direction: 'outgoing',
    text: 'They changed the atmosphere of the work a bit.',
    next: 'etwo42b3',
  },
  {
    id: 'etwo42b3',
    direction: 'outgoing',
    text: 'I remember the ones accompanied by',
    options: [
      {
        text: 'Text.',
        to: 'etwo42ba0',
      },
      {
        text: 'Audio.',
        to: 'etwo42bb0',
      },
    ],
  },
  {
    id: 'etwo42ba0',
    direction: 'outgoing',
    text: 'Text.',
    next: 'etwo42ba1',
  },
  {
    id: 'etwo42ba1',
    direction: 'outgoing',
    text: 'The photos looked like stills from the videos.',
    next: 'etwo42ba2',
  },
  {
    id: 'etwo42ba2',
    direction: 'outgoing',
    text:
      'But the colouring makes such a difference. In black and white, the photos seemed more intimate somehow?',
    next: 'etwo42ba3',
  },
  {
    id: 'etwo42ba3',
    direction: 'outgoing',
    text:
      'The worlds accompanying them felt like confessions. About the reality of being single, the struggles. It made everything a little more sad.',
    next: 'etwo42ba4',
  },
  {
    id: 'etwo42ba4',
    direction: 'incoming',
    text: 'It was sombre for sure. But I also saw strength.',
    next: 'etwo42ba5',
  },
  {
    id: 'etwo42ba5',
    direction: 'incoming',
    text:
      'They almost seemed to be demanding space to exist as singles, on their own terms and free of their troubles.',
    next: 'etwo42ba6',
  },
  {
    id: 'etwo42ba6',
    direction: 'outgoing',
    text: 'There was this one statement that stuck with me.',
    next: 'etwo42ba7',
  },
  {
    id: 'etwo42ba7',
    direction: 'outgoing',
    text:
      '"Just because I am single you appointed me to be the main provider."',
    next: 'etwo42ba8',
  },
  {
    id: 'etwo42ba8',
    direction: 'outgoing',
    text:
      'It was rather resigned. As if they knew not much could change. Being single doesn’t mean being free or independent.',
    next: 'etwo42ba9',
  },
  {
    id: 'etwo42ba9',
    direction: 'outgoing',
    text:
      'I’m lucky it’s different for me. Even though I still have to provide for my parents, I live a pretty independent life.',
    next: 'etwo42ba10',
  },
  {
    id: 'etwo42ba10',
    direction: 'incoming',
    text:
      'But even if there is sorrow, there is a lot of strength and comfort you can find just by reaching out. By sharing your story.',
    next: 'etwo42ba11',
  },
  {
    id: 'etwo42ba11',
    direction: 'incoming',
    text: 'Being heard, being seen … that can mean a lot.',
    externalEvent: 'main-etwoy0',
  },
  {
    id: 'etwo42bb0',
    direction: 'outgoing',
    text: 'Audio.',
    next: 'etwo42bb1',
  },
  {
    id: 'etwo42bb1',
    direction: 'outgoing',
    text: 'These images were sombre. Melancholic.',
    next: 'etwo42bb2',
  },
  {
    id: 'etwo42bb2',
    direction: 'outgoing',
    text: 'The music was darker, heavier too.',
    next: 'etwo42bb3',
  },
  {
    id: 'etwo42bb3',
    direction: 'outgoing',
    text: 'I wonder why.',
    next: 'etwo42bb4',
  },
  {
    id: 'etwo42bb4',
    direction: 'incoming',
    text:
      'Sometimes, you see a hint of a home in these pictures. But it’s dark and bare.',
    next: 'etwo42bb5',
  },
  {
    id: 'etwo42bb5',
    direction: 'incoming',
    text:
      'In one photo, I think the shadowy figure looks out longingly at the HDBs in the distance.',
    next: 'etwo42bb6',
  },
  {
    id: 'etwo42bb6',
    direction: 'outgoing',
    text:
      'Maybe they were leaving their home behind. And already longing for another?',
    next: 'etwo42bb7',
  },
  {
    id: 'etwo42bb7',
    direction: 'outgoing',
    text:
      'I can’t imagine what it feels like to leave a space you built for yourself behind.',
    next: 'etwo42bb8',
  },
  {
    id: 'etwo42bb8',
    direction: 'outgoing',
    text: 'I don’t want to imagine it.',
    next: 'etwo42bb9',
  },
  {
    id: 'etwo42bb9',
    direction: 'incoming',
    text: 'It’s bittersweet.',
    next: 'etwo42bb10',
  },
  {
    id: 'etwo42bb10',
    direction: 'incoming',
    text: 'Even if there is loss, there is the possibility of hope.',
    next: 'etwo42bb11',
  },
  {
    id: 'etwo42bb11',
    direction: 'incoming',
    text: 'A new place, a new life?',
    externalEvent: 'main-etwoy0',
  },
  //

  {
    id: 'etwo43',
    direction: 'outgoing',
    text:
      'It might feel different, if we only saw their homes and objects. But...',
    options: [
      {
        text: 'Reading their words...',
        to: 'etwo44a0',
      },
      {
        text: 'Seeing the photographs...',
        to: 'etwo44b0',
      },
    ],
  },
  {
    id: 'etwo44a0',
    direction: 'outgoing',
    text: 'Reading their words...',
    next: 'etwo44a1',
  },
  {
    id: 'etwo44a1',
    direction: 'outgoing',
    text: 'You know how some talked about distance and loneliness?',
    next: 'etwo44a2',
  },
  {
    id: 'etwo44a2',
    direction: 'outgoing',
    text: 'It felt like there’s something more to it.',
    next: 'etwo44a3',
  },
  {
    id: 'etwo44a3',
    direction: 'incoming',
    text: 'Oh. I get what you mean.',
    next: 'etwo44a4',
  },
  {
    id: 'etwo44a4',
    direction: 'incoming',
    text: 'The work really changes when you start clicking around.',
    next: 'etwo44a5',
  },
  {
    id: 'etwo44a5',
    direction: 'outgoing',
    text: 'Ya exactly.',
    next: 'etwo44a6',
  },
  {
    id: 'etwo44a6',
    direction: 'outgoing',
    text:
      'Anyway, the photos, they felt like confessions. About the reality of living alone.',
    next: 'etwo42ba4',
  },
  {
    id: 'etwo44b0',
    direction: 'outgoing',
    text: 'Seeing the photographs...',
    next: 'etwo44b1',
  },
  {
    id: 'etwo44b1',
    direction: 'outgoing',
    text: 'Some in black and white, others accompanied by music...',
    next: 'etwo44b2',
  },
  {
    id: 'etwo44b2',
    direction: 'outgoing',
    text: "It felt like there's something more to it.",
    next: 'etwo44b3',
  },
  {
    id: 'etwo44b3',
    direction: 'incoming',
    text: 'Oh. I get what you mean.',
    next: 'etwo44b4',
  },
  {
    id: 'etwo44b4',
    direction: 'incoming',
    text: 'The work really changes when you start clicking around.',
    next: 'etwo44b5',
  },
  {
    id: 'etwo44b5',
    direction: 'outgoing',
    text: 'Ya exactly.',
    next: 'etwo44b6',
  },
  {
    id: 'etwo44b6',
    direction: 'outgoing',
    text:
      'Anyway, the photos with music, they were so melancholic. Sapped of all the colour you see on the main page.',
    next: 'etwo42bb4',
  },

  // MAIN

  {
    id: 'etwoxa0',
    direction: 'outgoing',
    text: 'Ya but also, I noticed that some themes crept up again and again.',
    next: 'etwoxa1',
  },
  {
    id: 'etwoxa1',
    direction: 'outgoing',
    text:
      'The idea of ‘home’ is central to the work. But the work explored ‘loneliness’ and ‘distance.’ ',
    next: 'etwoxa2',
  },
  {
    id: 'etwoxa2',
    direction: 'incoming',
    text:
      'That’s also why I sent it to you cause I feel like you would relate. Living alone, and during a pandemic too.',
    next: 'etwoxa3',
  },
  {
    id: 'etwoxa3',
    direction: 'incoming',
    text: 'And I always thought it’d be so nice to have my own house!',
    next: 'etwoxa4',
  },
  {
    id: 'etwoxa4',
    direction: 'incoming',
    text: 'But I guess it isn’t all fun and games.',
    next: 'etwoxa5',
  },
  {
    id: 'etwoxa5',
    direction: 'incoming',
    text:
      'Not even just being alone, but also the grind of getting and maintaining a home (I laughed sadly when a certain housing-related website popped up).',
    next: 'etwoxa6',
  },
  {
    id: 'etwoxa6',
    direction: 'incoming',
    text: 'But still, someday!!! 😭',
    next: 'etwoxa7',
  },
  {
    id: 'etwoxa7',
    direction: 'outgoing',
    text: 'It’s hard. Getting a house, keeping it, living alone.',
    next: 'etwoxa8',
  },
  {
    id: 'etwoxa8',
    direction: 'outgoing',
    text:
      'People think everything is straightforward, especially in Singapore.',
    next: 'etwoxa9',
  },
  {
    id: 'etwoxa9',
    direction: 'outgoing',
    text:
      'We have a good public housing system, and we’ll all eventually get a home. And that’s true for most of us, but not all.',
    next: 'etwoxa10',
  },
  {
    id: 'etwoxa10',
    direction: 'outgoing',
    text:
      'Sometimes, it’s not so simple. And I appreciated that the work acknowledged that.',
    next: 'etwoxa11',
  },
  {
    id: 'etwoxa11',
    direction: 'outgoing',
    text: 'So many different people and spaces. Each with their own story.',
    externalEvent: 'main-etwoz0',
  },
  {
    id: 'etwoxb0',
    direction: 'outgoing',
    text:
      'It’s strange how you approached it so differently from me. But hey, that’s art.',
    next: 'etwoxb1',
  },
  {
    id: 'etwoxb1',
    direction: 'incoming',
    text: 'I’m glad I sent you the work. Nice to chat with you again!',
    next: 'etwoxb2',
  },
  {
    id: 'etwoxb2',
    direction: 'outgoing',
    text:
      'Hahaha. I mean apart from making me feel a bit weird, it was still interesting to explore.',
    next: 'etwoxb3',
  },
  {
    id: 'etwoxb3',
    direction: 'outgoing',
    text: 'I’m single, I have my own home. The work is rather relatable.',
    next: 'etwoxb4',
  },
  {
    id: 'etwoxb4',
    direction: 'outgoing',
    text: 'Especially all the stuff about loneliness and distance haha.',
    next: 'etwoxb5',
  },
  {
    id: 'etwoxb5',
    direction: 'outgoing',
    text:
      'Life is already hard enough without the pandemic raging on. And it’s lonelier. Still is a bit.',
    next: 'etwoxb6',
  },
  {
    id: 'etwoxb6',
    direction: 'outgoing',
    text: 'Like there’s no joy in having my own home now lol.',
    next: 'etwoxb7',
  },
  {
    id: 'etwoxb7',
    direction: 'outgoing',
    text:
      'I got this space so that I could have my own space. But now… it’s just lonely.',
    next: 'etwoxb8',
  },
  {
    id: 'etwoxb8',
    direction: 'incoming',
    text: 'Understatement lol.',
    next: 'etwoxb9',
  },
  {
    id: 'etwoxb9',
    direction: 'incoming',
    text:
      'Plus you’re quite distanced from everyone and everything (literally because your house is so ulu!!! jk)',
    next: 'etwoxb10',
  },
  {
    id: 'etwoxb10',
    direction: 'incoming',
    text:
      'It’s funny because I used to want more alone time. But I didn’t think about how being alone could get lonely. It’s nice to have company too I guess.',
    next: 'etwoxb11',
  },
  {
    id: 'etwoxb11',
    direction: 'incoming',
    text:
      'And we’re lucky to be able to still meet up and spend time with each other, under these circumstances.',
    next: 'etwoxb12',
  },
  {
    id: 'etwoxb12',
    direction: 'incoming',
    text:
      'And also realising that maintaining a house is also hard la. All that admin. I laughed when the link to a certain housing-related website came up.',
    next: 'etwoxb13',
  },
  {
    id: 'etwoxb13',
    direction: 'outgoing',
    text:
      'People think that all this is straightforward, especially in Singapore.',
    next: 'etwoxb14',
  },
  {
    id: 'etwoxb14',
    direction: 'outgoing',
    text:
      'We have good systems in place. Everyone can get house, power and all that easily. And I am lucky, many people are. But not all.',
    next: 'etwoxb15',
  },
  {
    id: 'etwoxb15',
    direction: 'outgoing',
    text:
      'Sometimes, it’s not so simple. And I appreciated that the work acknowledged that.',
    next: 'etwoxb16',
  },
  {
    id: 'etwoxb16',
    direction: 'outgoing',
    text: 'So many different people and spaces, each with their own story.',
    externalEvent: 'main-etwoz0',
  },
  //

  {
    id: 'etwoz6',
    direction: 'incoming',
    text:
      'Anyway, I feel like there is a lot more we can discuss about this work. And also just catch up on life. Let’s meet up soon!',
    next: 'etwoz7',
  },
  {
    id: 'etwoz7',
    direction: 'outgoing',
    text: 'Yes, I was just thinking about this. Would be nice to get dinner.',
    next: 'etwoz8',
  },
  {
    id: 'etwoz8',
    direction: 'incoming',
    text:
      'Ok! Are you free on 5th March? Let’s go for k bbq, it’s been so long.',
    next: 'etwoz9',
  },
  {
    id: 'etwoz9',
    direction: 'outgoing',
    text:
      'Sounds great. 7pm at our fave place? Looking forward to catching up.',
    next: 'etwoz10',
  },
  {
    id: 'etwoz10',
    direction: 'incoming',
    text: 'That works. See you soon!',
    externalEvent: 'endetwo',
  },
]

export default phoneContent
