import { createGlobalStyle } from 'styled-components'
import BarlowMedium from './assets/fonts/Barlow-Medium.otf'
import BarlowMediumItalic from './assets/fonts/Barlow-MediumItalic.otf'
import BarlowRegular from './assets/fonts/Barlow-Regular.otf'
import BarlowSemiBold from './assets/fonts/Barlow-SemiBold.otf'
import Cirka from './assets/fonts/Cirka-Variable.ttf'
import Roslindale from './assets/fonts/RoslindaleDisplayCondensed-Medium.otf'

export const theme = {
  colors: {
    blueGradientStart: '#B3B5FF',
    blueGradientEnd: '#2B32FF',
    redGradientStart: '#FFA2F1',
    redGradientEnd: '#FF00E1',
    cello: '#364BD6',
    hotMagenta: '#FF00D4',
    darkPurple: '#6b586a',
  },
  device: {
    mobile: `(max-width: 768px)`,
  },
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: BarlowMedium;
    src: url(${BarlowMedium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: BarlowMediumItalic;
    src: url(${BarlowMediumItalic}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: BarlowRegular;
    src: url(${BarlowRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: BarlowSemiBold;
    src: url(${BarlowSemiBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: Cirka;
    src: url(${Cirka}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: Roslindale;
    src: url(${Roslindale}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  html, body {
    font-family: BarlowRegular;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  body::-webkit-scrollbar { 
    display: none;
  }
`

export default GlobalStyle
