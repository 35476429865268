import Slide from '../Slide'
import cat from '../../assets/img/prologue-cat.png'
import catNegative from '../../assets/img/prologue-cat-negative.png'
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'

const StyledImg = styled.img`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 70vh;
`
const StyledMask = styled.img.attrs((props) => {
  const clipPath = `
    ${props.offset || 0}px 0,
    ${(props.offset || 0) - 200 - props.widen / 2}px 100%,
    ${(props.offset || 0) + props.widen / 2}px 100%,
    ${(props.offset || 0) + 100}px 0`
  return {
    style: {
      clipPath: `polygon(${clipPath})`,
    },
  }
})`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 70vh;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Scene1 = ({ slideId }) => {
  const [offset, setOffset] = useState(400)
  const [widen, setWiden] = useState(100)
  const direction = useRef(true)
  useEffect(
    () =>
      setInterval(() => {
        setOffset((prev) => (prev < 1200 ? prev + 0.3 : -100))
        setWiden((prev) => {
          if (prev > 150) direction.current = false
          if (prev < 0) direction.current = true
          return direction.current ? prev + 0.6 : prev - 0.6
        })
      }, 16),
    [],
  )

  if (slideId !== 5) return null

  return (
    <Slide>
      <Content>
        <StyledImg src={cat} />
        <StyledMask widen={widen} offset={offset} src={catNegative} />
      </Content>
    </Slide>
  )
}

export default Scene1
