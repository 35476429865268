import styled from 'styled-components'
import door from '../../assets/img/prologue-realdoor.png'
import Slide, { SlideText } from '../Slide'

const Button = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 20px 60px;
  cursor: pointer;
  border: 1px solid white;
  background: none;
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const LeaveBtn = styled(Button)`
  position: fixed;
  top: 20vh;
  left: 58vw;
`
const StayBtn = styled(Button)`
  position: fixed;
  top: 30vh;
  left: 35vw;
`

const StyledImg = styled.img`
  position: fixed;
  top: 20vh;
  width: 900px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Scene7 = ({ slideId, setSlideId }) => {
  if (slideId !== 8) return null

  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const cbDate = new Date(2020, 3, 7) // month starts at indexed 0
  cbDate.setUTCHours(8)
  const todayDate = new Date()
  todayDate.setUTCHours(8)
  const day = Math.round(Math.abs((cbDate - todayDate) / oneDay))

  return (
    <Slide>
      <Content>
        <StyledImg src={door} />
      </Content>

      <StayBtn onClick={() => setSlideId(10)}>Stay at home.</StayBtn>
      <LeaveBtn onClick={() => setSlideId(9)}>Leave the house.</LeaveBtn>

      <SlideText>
        The laws have changed. The circuit breaker is over, but the virus
        remains. You can do more now than you could {day} days ago. You want
        to...
      </SlideText>
    </Slide>
  )
}

export default Scene7
