import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import useSound from 'use-sound'
import { TextContainer } from '../components/Text'
import Button from '../components/Button'
import YenArt from '../components/artworks/Yen'
import KhengArt from '../components/artworks/Kheng'
import MoreInfo from '../components/MoreInfo'

import ep4Content from '../content/ep4-content'
import bg from '../assets/img/ep4/ep4-bg.jpg'
import shelfBg from '../assets/img/ep4/ep4-shelf-bg.jpg'
import ceilingBg from '../assets/img/ep4/ep4-ceiling-bg.jpg'
import sunriseBg from '../assets/img/ep4/ep4-sunrise-bg.jpg'
import bedroom from '../assets/sound/bedroom4.mp3'
import curation from '../assets/sound/curation4.mp3'
import sunrise from '../assets/sound/sunrise.mp3'
import { useHistory } from 'react-router'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
`
const ImageContainer = styled.div`
  position: fixed;
  width: 100vw;
`
const StyledImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const StyledImageAnim = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: opacityIn ease ${(props) => props.fade || 1}s;

  @keyframes opacityIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const Fixed = styled.div`
  position: fixed;
`

const StyledButton = styled(Button)`
  width: auto;
  font-size: 1.5em;
  background-color: ${(props) => props.theme.colors.hotMagenta};
  color: white;
  animation: fadeIn ease 0.5s;
`
const BookButton = styled(StyledButton)`
  position: fixed;
  top: 65%;
  left: 49%;
  width: 14%;
  text-align: center;
`

const JournoButton = styled(StyledButton)`
  position: fixed;
  bottom: 10%;
  left: 15%;
  width: 14%;
  text-align: center;
`

const LaptopButton = styled.button`
  position: fixed;
  bottom: 0;
  right: 25%;
  width: 15%;
  height: 20%;
  background: white;
  opacity: 0;
  box-shadow: 60px -16px teal;
  transition: 0.5s;
  cursor: pointer;
  clip-path: polygon(
    40% 0,
    37% 53%,
    2% 69%,
    50% 100%,
    66% 100%,
    89% 78%,
    95% 10%,
    41% 0
  );

  &:hover {
    opacity: 0.5;
  }
`

const Ending = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  padding: 100px 0;

  animation: endingbg 4s;
  @keyframes endingbg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const BigText = styled.p`
  width: 80vw;
  margin: 50px 10vw;
  text-align: center;
  font-family: Roslindale;
  font-size: 3em;
  color: white;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 700px;
  margin: 0 auto;
`

const FirstButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 80px;
  cursor: pointer;
  border: 1px solid white;
  background: none;
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const SecondButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  height: 150px;
  color: white;
  padding: 30px 80px;
  border: none;
  margin: 0 10px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.darkPurple};
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const EpButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  background: ${(props) => props.theme.colors.darkPurple};
  color: white;
  cursor: pointer;
  border: none;
  padding: 20px;
  margin: 20px 4px;
  flex: 1;
  transition: 0.3s;

  animation: fadeIn ease ${(props) => `${1 + props.i * 0.3}s`};

  &:hover {
    background: white;
    color: black;
  }

  @keyframes fadeIn {
    0% {
      transform: translate(0, 40px);
      opacity: 0;
    }
    100% {
      tranform: translate(0);
      opacity: 1;
    }
  }
`

const EpisodeFour = () => {
  const textRef = useRef()
  const [bookText, setBookText] = useState('')
  const bookBtnCallbackRef = useRef()
  const [showYenArt, setShowYenArt] = useState(false)
  const [showKhengArt, setShowKhengArt] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const [showShelfBg, setShowShelfBg] = useState(false)
  const [showCeilingBg, setShowCeilingBg] = useState(false)
  const [showSunriseBg, setShowSunriseBg] = useState(false)
  const [ending, setEnding] = useState(false)

  const [journalText, setJournalText] = useState('')
  const journalBtnCallbackRef = useRef()

  const [playBedroom, bedroomTrack] = useSound(bedroom, { loop: true })
  const [playCuration, curationTrack] = useSound(curation, { loop: true })
  const [playSunrise, sunriseTrack] = useSound(sunrise, { loop: true })

  const history = useHistory()

  useEffect(() => {
    bookBtnCallbackRef.current = () => {
      textRef.current.addNode('efour4b0')
      setBookText('')
      setJournalText('')
    }
    journalBtnCallbackRef.current = () => {
      textRef.current.addNode('efour4a0')
      setBookText('')
      setJournalText('')
    }
  }, [])

  useEffect(playBedroom, [playBedroom])

  const triggerEvent = (id) => {
    switch (id) {
      case 'curationmusic':
        bedroomTrack.stop()
        playCuration()
        break
      case 'bedroommusic':
        curationTrack.stop()
        playBedroom()
        break
      case 'makejournobookchoice':
        setBookText('Notice the stack of books.')
        setJournalText('Notice the journal.')
        break
      case 'makebookchoice':
        setBookText('Notice the stack of books.')
        break
      case 'bookshelfappear':
        setShowShelfBg(true)
        break
      case 'bookshelfdisappear':
        setShowShelfBg(false)
        break
      case 'seaceilingappear':
        setShowCeilingBg(true)
        break
      case 'sunrise':
        setShowCeilingBg(false)
        bedroomTrack.stop()
        curationTrack.stop()
        playSunrise()
        break
      case 'playcuration':
        bedroomTrack.stop()
        sunriseTrack.stop()
        playCuration()
        break
      case 'playbedroom':
        curationTrack.stop()
        sunriseTrack.stop()
        playBedroom()
        break
      case 'openyenphang':
        setTimeout(() => {
          setShowYenArt(true)
          bedroomTrack.stop()
          curationTrack.stop()
        }, 2000)
        break
      case 'openkhenghua':
        setJournalText('Write in journal.')
        journalBtnCallbackRef.current = () => {
          setShowKhengArt(true)
          setJournalText('')
          bedroomTrack.stop()
          curationTrack.stop()
        }
        break
      case 'endefour':
        ReactGA.event({
          category: 'Completion of episode (DAC S2)',
          label: 'DAC S2 - Completed EP4',
          action: 'Reached the end of S2 EP4',
        })
        setTimeout(() => setShowSunriseBg(true), 3000)
        setTimeout(() => setEnding(true), 8000)
        break
      default:
        break
    }
  }

  const handleRestart = () => {
    textRef.current.reset()
    ReactGA.event({
      category: 'Completion of episode (DAC S2)',
      label: 'DAC S2- Replayed EP4',
      action: 'Replay (EP4)',
    })
    bedroomTrack.stop()
    curationTrack.stop()
    setEnding(false)
    setShowShelfBg(false)
    setShowCeilingBg(false)
    setShowSunriseBg(false)
    playBedroom()
    curationTrack.stop()
    sunriseTrack.stop()
    setShowInfo(false)
  }
  const handleReturn = () => {
    setEnding(false)
  }
  const handleExit = () => {
    ReactGA.event({
      category: 'Button (DAC S2)',
      label: 'DAC S2 - Exited at end',
      action: 'Clicked the Exit button at end of experience',
    })
  }
  const handleEpClick = (url, category, label, action) => {
    ReactGA.event({ category, label, action })
    history.push(url)
  }

  const artworkIsOpen = showYenArt || showKhengArt

  return (
    <Container>
      <ImageContainer>
        <StyledImage src={bg} />
        {showShelfBg && <StyledImageAnim src={shelfBg} />}
        {showCeilingBg && <StyledImageAnim src={ceilingBg} />}
        {showSunriseBg && <StyledImageAnim fade={3} src={sunriseBg} />}
        {Boolean(bookText) && (
          <BookButton direction="outgoing" onClick={bookBtnCallbackRef.current}>
            {bookText}
          </BookButton>
        )}
        {Boolean(journalText) && (
          <JournoButton
            direction="outgoing"
            onClick={journalBtnCallbackRef.current}
          >
            {journalText}
          </JournoButton>
        )}
        <LaptopButton
          onClick={() => {
            if (artworkIsOpen || ending || showSunriseBg) return
            ReactGA.event({
              category: 'Click (DAC S2)',
              label: 'DAC S2 - Clicked laptop',
              action: 'Clicked S2 laptop',
            })
            setShowInfo(true)
          }}
        />
      </ImageContainer>
      <Fixed>
        <TextContainer
          contentObj={ep4Content}
          ref={textRef}
          triggerEvent={triggerEvent}
        />
      </Fixed>
      <MoreInfo show={showInfo} onClose={() => setShowInfo(false)} />
      <YenArt
        show={showYenArt}
        onClose={() => {
          setShowYenArt(false)
          textRef.current.addNode('efour5')
        }}
      />
      <KhengArt
        show={showKhengArt}
        onClose={() => {
          setShowKhengArt(false)
          textRef.current.addNode('efour31')
        }}
      />

      {ending && (
        <Ending>
          <BigText>
            All episodes of Days –– and counting: The distance between us will
            be available online from 19 March - 30 April 2021.
          </BigText>
          <BigText>
            You are welcome to return to Sleeping Man’s bedroom at any time and
            revisit the artworks and episodes, or catch those you missed.
          </BigText>
          <Row>
            <FirstButton onClick={handleRestart}>RESTART</FirstButton>
            <FirstButton onClick={handleReturn}>BEDROOM</FirstButton>

            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4yBXfROmiLd0UjjdoIYoWwZEptel5sKUHnDhBhamEuQJUXg/viewform?usp=sf_link">
              <SecondButton>GIVE FEEDBACK</SecondButton>
            </a>
            <a href="https://ohopenhouse.give.asia/">
              <SecondButton onClick={handleExit}>EXIT</SecondButton>
            </a>
            <a href="https://dac.ohopenhouse.online/seasons.html">
              <SecondButton>SEE EPISODES</SecondButton>
            </a>
          </Row>

          <Row>
            <EpButton
              i={1}
              onClick={() =>
                handleEpClick(
                  '/prologue',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 1 from another ep',
                  'Clicked to enter Episode 1 from end of an ep',
                )
              }
            >
              Episode One
            </EpButton>
            <EpButton
              i={2}
              onClick={() =>
                handleEpClick(
                  '/ep2',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 2 from another ep',
                  'Clicked to enter Episode 2 from end of an ep',
                )
              }
            >
              Episode Two
            </EpButton>
            <EpButton
              i={3}
              onClick={() =>
                handleEpClick(
                  '/ep3',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 3 from another ep',
                  'Clicked to enter Episode 3 from end of an ep',
                )
              }
            >
              Episode Three
            </EpButton>
          </Row>
        </Ending>
      )}
    </Container>
  )
}

export default EpisodeFour
