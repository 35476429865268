import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ArtistInfo, ArtworkInfo, EpisodeInfo } from './ArtistInfo'

const FolderIcon = styled.div`
  height: 40px;
  width: 50px;
  margin-right: 20px;
  background: ${(props) => (props.active ? '#EBBC46' : '#4E98ED')};
  clip-path: polygon(0 0, 30% 0, 30% 15%, 100% 15%, 100% 100%, 0 100%);
  box-shadow: 5px 5px black;
  transition: 0.3s;
`
const FolderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const Folder = ({ name, active }) => {
  return (
    <FolderContainer>
      <FolderIcon active={active} />
      {name}
    </FolderContainer>
  )
}

const NotFoundContainer = styled.div`
  width: 500px;
  height: 300px;
  position: absolute;
  top: 30%;
  left: 40%;
  background: #DEE0F6;
  animation: notFoundIn ease 0.5s;

  @keyframes notFoundIn {
    0% {
      opacity: 0;
      transform:  scaleX(0) scaleY(0);
    }
    100% {
      opacity: 1;
      transform: scaleX(1) scaleY(1);
    }
`
const SubWindowHeader = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ccc;
  border-bottom: 1px solid blue;
`
const NotFoundContent = styled.div`
  height: 80%;
  width: 100%;
  padding-top: 10%;
  background: #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
const HeaderText = styled.p`
  font-weight: bold;
  flex: 1;
  text-align: center;
  margin: 0 auto;
`
const CloseBtn = styled.button`
  background: #ccc;
  font-size: 3em;
  color: blue;
  border: none;
  border-left: 1px solid blue;
  height: 100%;
  width: 50px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: white;
    background: blue;
  }
  &:focus {
    outline: none;
  }
`
const OkayBtn = styled.button`
  background: blue;
  color: white;
  width: 80px;
  height: 30px;
  border: none;
  box-shadow: 5px 5px black;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: blue;
    background: white;
    box-shadow: 7px 7px black;
  }
`
const QIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 2em;
  height: 50px;
  width: 50px;
  background-color: ${(props) => props.theme.colors.hotMagenta};
  border-radius: 99px;
`

const NotFound = ({ show, onClose }) =>
  show ? (
    <NotFoundContainer>
      <SubWindowHeader>
        <HeaderText></HeaderText>
        <CloseBtn onClick={onClose}>×</CloseBtn>
      </SubWindowHeader>
      <NotFoundContent>
        <QIcon>?</QIcon>
        <p>PAGE COMING SOON</p>
        <OkayBtn onClick={onClose}>OK</OkayBtn>
      </NotFoundContent>
    </NotFoundContainer>
  ) : null

const SubWindowContainer = styled.div`
  width: 60%;
  height: 60%;
  position: absolute;
  top: 15%;
  left: 15%;
  background #DEE0F6;
  animation: artistInfoIn ease 0.5s;

  @keyframes artistInfoIn {
    0% {
      opacity: 0;
      transform:  scaleX(0) scaleY(0);
    }
    100% {
      opacity: 1;
      transform: scaleX(1) scaleY(1);
    }
`
const SubWindowbody = styled.div`
  height: calc(100% - 51px);
  overflow-y: scroll;
  padding: 0 20px;
`
const SubWindow = ({ show, onClose, headerText, children }) =>
  show ? (
    <SubWindowContainer>
      <SubWindowHeader>
        <HeaderText>{headerText}</HeaderText>
        <CloseBtn onClick={onClose}>×</CloseBtn>
      </SubWindowHeader>
      <SubWindowbody>{children}</SubWindowbody>
    </SubWindowContainer>
  ) : null

const Container = styled.div`
  position: fixed;
  top: 1vh;
  left: 1vw;
  width: 98vw;
  height: 98vh;
  display: flex;
  flex-direction: column;
  background: #3b3d5f;

  animation: show ease 0.5s;
  @keyframes show {
    0% {
      transform: scale(0, 0);
      opacity: 0;
    }
    100% {
      tranform: scale(1, 1);
      opacity: 1;
    }
  }
`

const Header = styled.div`
  width: 100%;
  height: 30px;
  background: #b1c1fc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Time = styled.div`
  padding: 0 20px;
`
const ExitBtn = styled.button`
  height: 100%;
  border: none;
  background: ${(props) => props.theme.colors.hotMagenta};
  color: white;
  padding: 0 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.hotMagenta};
    background: white;
  }
  &:focus {
    outline: none;
  }
`

const Body = styled.div`
  width: 100%;
  flex: 1;
  background-size: 40px 40px;
  background-image: linear-gradient(to right, #666 1px, transparent 1px),
    linear-gradient(to bottom, #666 1px, transparent 1px);
`

const TabRow = styled.div`
  width: 100%;
  height: 50px;
  background: #171c47;
  border-bottom: 3px solid #b1c1fc;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`
const StyledTab = styled.div`
  width: 200px;
  height: 30px;
  background: #b1c1fc;
  border-radius: 12px 12px 0 0;
  padding: 0 20px 5px;
  margin: 0 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  filter: brightness(${(props) => (props.active ? '100%' : '50%')});
  transition: 0.3s;
`

const tabs = {
  artists: 'artists',
  artworks: 'artworks',
  episodes: 'episodes',
  credits: 'credits',
}

const Tab = ({ onClick, label, active }) => (
  <StyledTab onClick={onClick} active={active}>
    <span>{label}</span>
    <span>{active ? '●' : '◦'}</span>
  </StyledTab>
)

const InfoHeaderRow = styled.div`
  width: 94%;
  padding: 0 3%;
  display: flex;
  flex-direction: row;
`
const InfoListHeader = styled.p`
  flex: ${(props) => props.flex || 1};
  color: white;
  text-decoration: underline;
`
const StyledArtistRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3%;
  cursor: pointer;
  animation-duration: 0.5s;
  animation-name: rowFadeIn;
  @keyframes rowFadeIn {
    0% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      tranform: translate(0);
      opacity: 1;
    }
  }
`
const ArtistName = styled.p`
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
`
const ArtistDate = styled.p`
  flex: 1;
  color: white;
  padding: 0 15px;
`
const ArtistType = styled.p`
  flex: 1;
  color: white;
`

const ListRow = ({ name, date, type, onClick, active }) => {
  const [hover, setHover] = useState(false)

  return (
    <StyledArtistRow
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ArtistName>
        <Folder name={name} active={active || hover} />
      </ArtistName>
      <ArtistDate>{date}</ArtistDate>
      <ArtistType>{type}</ArtistType>
    </StyledArtistRow>
  )
}

const ArtistsSection = ({ show }) => {
  const [show404, setShow404] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [artistName, setArtistName] = useState('')
  const artists = [
    {
      name: 'Denise Yap',
      date: '19 February 2021',
      type: 'Video Game Vlogs',
    },
    {
      name: 'Lynn Lu',
      date: '19 February 2021',
      type: 'Lecture Performance',
    },
    {
      name: 'Ezzam Rahman',
      date: '26 February 2021',
      type: 'Mobile Visual Gallery',
    },
    {
      name: 'Berny Tan',
      date: '5 March 2021',
      type: 'Online Vignettes',
    },
    {
      name: 'Churen Li, Hell Low, Subhas Nair, Tim De Cotta, Weish',
      date: '5 March 2021',
      type: 'Music Video',
    },
    {
      name: 'Bailey Wait, Lim Shi-An, Robert Wait, Tan Kheng Hua',
      date: '12 March 2021',
      type: 'Online Diary',
    },
    {
      name: 'Yen Phang',
      date: '12 March 2021',
      type: 'E-commerce store',
    },
  ]

  useEffect(() => {
    if (!show) {
      setShow404(false)
      setShowInfo(false)
      setArtistName('')
    }
  }, [show])

  if (!show) return null
  return (
    <div>
      <NotFound
        show={show404}
        onClose={() => {
          setArtistName('')
          setShow404(false)
        }}
      />
      <SubWindow
        show={showInfo}
        onClose={() => {
          setArtistName('')
          setShowInfo(false)
        }}
        headerText={artistName.toUpperCase()}
      >
        <ArtistInfo artist={artistName} />
      </SubWindow>
      <InfoHeaderRow>
        <InfoListHeader flex={3}>ARTIST NAME</InfoListHeader>
        <InfoListHeader flex={1}>EPISODE RELEASE</InfoListHeader>
        <InfoListHeader flex={1}>ARTWORK</InfoListHeader>
      </InfoHeaderRow>
      {artists.map((artist) => (
        <ListRow
          active={artistName === artist.name}
          onClick={() => {
            setShow404(false)
            setShowInfo(false)
            setArtistName(artist.name)
            if (artist.show404) return setTimeout(() => setShow404(true), 0)
            setTimeout(() => setShowInfo(true), 0)
          }}
          name={artist.name}
          date={artist.date}
          type={artist.type}
          key={artist.name}
        />
      ))}
    </div>
  )
}

const ArtworksSection = ({ show }) => {
  const [show404, setShow404] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [artworkName, setArtworkName] = useState('')
  const artworks = [
    {
      name: 'Flan’s Glog',
      createdBy: 'Denise Yap',
      releaseDate: '19 February 2021',
    },
    {
      name: 'La Peste',
      createdBy: 'Lynn Lu',
      releaseDate: '19 February 2021',
    },
    {
      name: "you're not alone in all this, you're not alone i promise",
      createdBy: 'Ezzam Rahman',
      releaseDate: '26 February 2021',
    },
    {
      name: 'Keeping Distance',
      createdBy: 'Berny Tan',
      releaseDate: '5 March 2021',
    },
    {
      name: 'Love Lockdown',
      createdBy: 'Churen Li, Hell Low, Subhas Nair, Tim De Cotta, Weish',
      releaseDate: '5 March 2021',
    },
    {
      name: 'Miles To Go Before We Sleep, Miles To Go Before We Wake',
      createdBy: 'Bailey Wait, Lim Shi-An, Robert Wait, Tan Kheng Hua',
      releaseDate: '12 March 2021',
    },
    {
      name: 'Value Store',
      createdBy: 'Yen Phang',
      releaseDate: '12 March 2021',
    },
  ]

  useEffect(() => {
    if (!show) {
      setShow404(false)
      setShowInfo(false)
      setArtworkName('')
    }
  }, [show])

  if (!show) return null
  return (
    <div>
      <NotFound
        show={show404}
        onClose={() => {
          setArtworkName('')
          setShow404(false)
        }}
      />
      <SubWindow
        show={showInfo}
        onClose={() => {
          setArtworkName('')
          setShowInfo(false)
        }}
        headerText={artworkName.toUpperCase()}
      >
        <ArtworkInfo name={artworkName} />
      </SubWindow>
      <InfoHeaderRow>
        <InfoListHeader flex={3}>ARTWORKS</InfoListHeader>
        <InfoListHeader flex={1}>CREATED BY</InfoListHeader>
        <InfoListHeader flex={1}>DATE CREATED</InfoListHeader>
      </InfoHeaderRow>
      {artworks.map((artwork) => (
        <ListRow
          active={artworkName === artwork.name}
          onClick={() => {
            setShow404(false)
            setShowInfo(false)
            setArtworkName(artwork.name)
            if (artwork.show404) return setTimeout(() => setShow404(true), 0)
            setTimeout(() => setShowInfo(true), 0)
          }}
          name={artwork.name}
          date={artwork.createdBy}
          type={artwork.releaseDate}
          key={artwork.name}
        />
      ))}
    </div>
  )
}

const EpisodesSection = ({ show }) => {
  const [show404, setShow404] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [episodeName, setEpisodeName] = useState('')
  const episodes = [
    {
      name: 'E1: Reality & Rest',
      featuring: 'Denise Yap, Lynn Lu',
      releaseDate: '19 February 2021',
    },
    {
      name: 'E2: Home Alone',
      featuring: 'Ezzam Rahman',
      releaseDate: '26 February 2021',
    },
    {
      name: 'E3: Distance Kept, Distance Bridged',
      featuring:
        'Berny Tan, Churen Li, Hell Low, Subhas Nair, Tim De Cotta, Weish',
      releaseDate: '5 March 2021',
    },
    {
      name: 'E4: Working Through, Making Do',
      featuring:
        'Bailey Wait, Lim Shi-An, Robert Wait, Tan Kheng Hua, Yen Phang',
      releaseDate: '12 March 2021',
    },
  ]

  useEffect(() => {
    if (!show) {
      setShow404(false)
      setShowInfo(false)
      setEpisodeName('')
    }
  }, [show])

  if (!show) return null
  return (
    <div>
      <NotFound
        show={show404}
        onClose={() => {
          setEpisodeName('')
          setShow404(false)
        }}
      />
      <SubWindow
        show={showInfo}
        onClose={() => {
          setEpisodeName('')
          setShowInfo(false)
        }}
        headerText={episodeName.toUpperCase()}
      >
        <EpisodeInfo name={episodeName} />
      </SubWindow>
      <InfoHeaderRow>
        <InfoListHeader flex={3}>EPISODES</InfoListHeader>
        <InfoListHeader flex={1}>FEATURING</InfoListHeader>
        <InfoListHeader flex={1}>DATE RELEASED</InfoListHeader>
      </InfoHeaderRow>
      {episodes.map((episode) => (
        <ListRow
          active={episodeName === episode.name}
          onClick={() => {
            setShow404(false)
            setShowInfo(false)
            setEpisodeName(episode.name)
            if (episode.show404) return setTimeout(() => setShow404(true), 0)
            setTimeout(() => setShowInfo(true), 0)
          }}
          name={episode.name}
          date={episode.featuring}
          type={episode.releaseDate}
          key={episode.name}
        />
      ))}
    </div>
  )
}

const CreditsWindow = styled.div`
  width: 60%;
  height: 70vh;
  background #DEE0F6;
  padding: 0 20px;
  box-shadow: 10px 10px black;
  border: 2px solid #364BD6;
  overflow-y: scroll;
  animation: creditsIn ease 0.7s;

  @keyframes creditsIn {
    0% {
      opacity: 0;
      transform:  translateY(50px);
    }
    100% {
      opacity: 1;
    }
`
const CreditsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2vh;
`
const CreditsHeader = styled.h2`
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #364bd6;
`
const Subheader = styled.strong`
  margin-bottom: -10px;
  text-decoration: underline;
`

const CreditsSection = ({ show }) => {
  if (!show) return null
  return (
    <CreditsContainer>
      <CreditsWindow>
        <CreditsHeader>CREDITS</CreditsHeader>
        <h1>
          OH! would love to thank the following people for supporting and
          believing in us all these years: BinjaiTree, Deutsche Bank, Ginga
          Petroleum.
        </h1>
        <h2>Experience Development</h2>
        <Subheader>Graphic Designer</Subheader>
        <p>Factory</p>

        <Subheader>Web Developer</Subheader>
        <p>Keith Chia</p>

        <Subheader>Sound Designer</Subheader>
        <p>Nat Norland</p>

        <Subheader>Writers</Subheader>
        <p>
          Carissa Low <br />
          Kirti Upadhyaya
        </p>
        <h2>Presented by OH! Open House</h2>
        <Subheader>Board members</Subheader>
        <p>
          Alan Oei
          <br />
          Linda Neo
          <br />
          Lisa Robins
          <br />
          Oliver Bettin
          <br />
          Philip Morgan
          <br />
          Ryan Su
          <br />
          Seng Yu Jin
          <br />
          Tan Kheng Hua
          <br />
          Tan Sue Yen
        </p>

        <Subheader>Executive Director</Subheader>
        <p>Alan Oei</p>

        <Subheader>Assistant Director</Subheader>
        <p>Lim Su Pei</p>

        <Subheader>Assistant Curator</Subheader>
        <p>Kirti Upadhyaya</p>

        <Subheader>Community & Partnerships Manager</Subheader>
        <p>Tang Hui Shi</p>

        <Subheader>Marcomms Manager</Subheader>
        <p>Carissa Low</p>

        <Subheader>Marcomms Trainee</Subheader>
        <p>Marvin Lee</p>
      </CreditsWindow>
    </CreditsContainer>
  )
}

const Window = ({ show, onClose = () => {} }) => {
  const [time, setTime] = useState(new Date().toLocaleTimeString())
  const [tab, setTab] = useState(tabs.artists)

  const day = new Date().toLocaleDateString('us-en', { weekday: 'short' })

  useEffect(() => {
    setInterval(() => {
      setTime(new Date().toLocaleTimeString())
    }, 1000)
  }, [])

  if (!show) return null
  return (
    <Container>
      <Header>
        <Time>
          {day} {time}
        </Time>
        <ExitBtn onClick={onClose}>EXIT SCREEN</ExitBtn>
      </Header>
      <TabRow>
        <Tab
          onClick={() => setTab(tabs.artists)}
          label="Artists"
          active={tab === tabs.artists}
        />
        <Tab
          onClick={() => setTab(tabs.artworks)}
          label="Artworks"
          active={tab === tabs.artworks}
        />
        <Tab
          onClick={() => setTab(tabs.episodes)}
          label="Episodes"
          active={tab === tabs.episodes}
        />
        <Tab
          onClick={() => setTab(tabs.credits)}
          label="Credits"
          active={tab === tabs.credits}
        />
      </TabRow>

      <Body>
        <ArtistsSection show={tab === tabs.artists} />
        <ArtworksSection show={tab === tabs.artworks} />
        <EpisodesSection show={tab === tabs.episodes} />
        <CreditsSection show={tab === tabs.credits} />
      </Body>
    </Container>
  )
}

export default Window
