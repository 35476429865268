import styled from 'styled-components'
import deniseProfile from '../assets/img/deniseyap.jpg'
import denise1 from '../assets/img/denise-artworkinfo1.jpeg'
import denise2 from '../assets/img/denise-artworkinfo2.jpeg'
import denise3 from '../assets/img/denise-artworkinfo3.jpeg'
import denise4 from '../assets/img/denise-artworkinfo4.jpeg'

import lynnProfile from '../assets/img/lynnlu.jpg'
import lynn1 from '../assets/img/lynn-artworkinfo1.png'
import lynn2 from '../assets/img/lynn-artworkinfo2.png'
import lynn3 from '../assets/img/lynn-artworkinfo3.png'
import lynn4 from '../assets/img/lynn-artworkinfo4.png'

import ezzamProfile from '../assets/img/ezzam.jpg'
import ezzam1 from '../assets/img/ezzam-artworkinfo1.png'
import ezzam2 from '../assets/img/ezzam-artworkinfo2.png'
import ezzam3 from '../assets/img/ezzam-artworkinfo3.png'
import ezzam4 from '../assets/img/ezzam-artworkinfo4.png'

import bernyProfile from '../assets/img/berny.png'
import berny1 from '../assets/img/berny-artworkinfo1.png'
import berny2 from '../assets/img/berny-artworkinfo2.png'
import berny3 from '../assets/img/berny-artworkinfo3.png'
import berny4 from '../assets/img/berny-artworkinfo4.png'

import weishProfile from '../assets/img/weish.jpg'
import hellowProfile from '../assets/img/hellow.jpg'
import churenProfile from '../assets/img/churen.jpg'
import timdecottaProfile from '../assets/img/timdecotta.jpg'
import subhasProfile from '../assets/img/subhas.jpg'
import lovelockdown1 from '../assets/img/lovelockdown-artworkinfo1.png'
import lovelockdown2 from '../assets/img/lovelockdown-artworkinfo2.png'
import lovelockdown3 from '../assets/img/lovelockdown-artworkinfo3.png'
import lovelockdown4 from '../assets/img/lovelockdown-artworkinfo4.png'

import khengProfile from '../assets/img/kheng.jpg'
import robProfile from '../assets/img/rob.jpg'
import shianProfile from '../assets/img/shian.jpg'
import khengvideos1 from '../assets/img/ep4/kheng-videos1.jpg'
import khengmusic2 from '../assets/img/ep4/kheng-music2.jpg'

import yenProfile from '../assets/img/yen.jpeg'
import yenlistings from '../assets/img/ep4/yen-listings.png'

const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
`
const ArtworkImage = styled.img`
  width: 20vw;
  align-self: flex-start;
`

const ProfileImage = styled.img`
  position: fixed;
  height: 170px;
  bottom: calc(10vh + ${({ i = 0 }) => i * 170}px);
  right: 10vw;
  border: 3px solid white;
  animation: profileIn ease 1s;

  @keyframes profileIn {
    0% {
      opacity: 0;
      transform: scaleX(0) scaleY(0);
    }
    50% {
      opacity: 0;
      transform: scaleX(0) scaleY(0);
    }
    100% {
      opacity: 1;
      transform: scaleX(1) scaleY(1);
    }

`

const LLInfo = () => (
  <>
    <ProfileImage src={lynnProfile} />
    <h3>ABOUT THE ARTIST</h3>
    <p>
      <strong>Lynn Lu</strong> is an independent artist, researcher and curator.
      Lu’s multidisciplinary practice revolves around participation and
      collaboration, context and site-specificity, and the poetics of absurdity.
    </p>

    <p>
      La Peste also features photographs by <strong>Annie Kwan</strong> and
      <strong>Gavin Maughfling</strong>.
    </p>

    <p>-</p>
    <p>
      <strong>Why does the human body interest you so much?</strong>
    </p>
    <p>
      For many years, I was absorbed in a kind of meditation called Vipassana,
      which the historical Buddha himself developed in order to perceive the
      nature of reality through first-hand bodily experience. We only concretely
      own the knowledge of something when we personally experience it to be true
      and consistent relative to our understanding.
    </p>

    <p>
      I remain attracted to the sentient body’s potential to be a vehicle of
      ‘true cognition’. By extension, I am curious about experiences activated
      by the live presence and active involvement of audiences, as well as about
      the meaning(s) we make of our ‘mutual doing’ that arises directly from our
      immediate, sensory, and reciprocal interactions."
    </p>

    <p>
      <strong>Books you’d recommend right now?</strong>
    </p>
    <p>
      "Parasite Rex: Inside the bizarre world of nature’s most dangerous
      creature by Carl Zimmer (2000), in which we humans play host to myriad
      other marvellously terrifying organisms that make the coronavirus look
      like a pussycat.
    </p>

    <p>
      Species of Spaces and other pieces by George Perec (1974), an enthralling
      reassessment of domestic space – where we’ve all been cocooned through
      endless lockdowns – and a contemplation of the ways in which we experience
      them and form memories of them.
    </p>

    <p>
      Phenomenology of Illness by Havi Carel (2016). Contra the tendency to
      think of illness only as something to get through, Carel proposes that
      illness might act as a philosophical tool, whereby the ill person –
      distanced via pathology from usual routines – uncovers previously
      unnoticed aspects of human existence."
    </p>
  </>
)

const DYInfo = () => (
  <>
    <ProfileImage src={deniseProfile} />
    <h3>ABOUT THE ARTIST</h3>
    <p>
      <strong>Denise Yap </strong>
      draws on different sources of information to build new, plausible worlds.
      Their work explores alternative forms of kinship and all the entanglements
      (and embarrassments) of the human condition.
    </p>

    <p>Denise would like to thank Bethesda for making Skyrim.</p>

    <p>-</p>

    <strong>Why use video games to make art?</strong>
    <p>
      “I use them to imagine alternative ways of being since the digital world
      allows more space for experimentations and speculations than our physical
      world.”
    </p>

    <strong>What do you love about video games?</strong>
    <p>
      “I grew up in a traditional family, going to catholic school, going to
      church and attending sunday school. You can imagine as a queer child, my
      immediate physical surroundings could neither understand nor support me.
      Some were even hostile towards people like me.
    </p>

    <p>
      I was introduced to video games via the computer. Because I had no credit
      card, I played games that were free which tend to be MMORPGs (Massively
      Multiplayer Online Role-Playing Games). One of which was a video game with
      intensive character customisation tools that I immediately fell in love
      with. This particular game mechanic was and continues to be an outlet for
      self-experimentation and self-discovery.
    </p>

    <p>
      Sometimes, I would meet others although they were from very distant
      countries, they were in a similar situation as me. And it occurred to me
      that I related to a black text on white background chat bubble more than
      any physical interaction I had in real life. Thus, my interest in video
      games surged when I realised I could, for the first time, connect with
      someone else.”
    </p>
  </>
)

const EzzamInfo = () => (
  <>
    <ProfileImage src={ezzamProfile} />
    <h3>ABOUT THE ARTIST</h3>
    <p>
      <strong>Ezzam Rahman</strong> is a multi-disciplinary artist. Formally
      trained as a sculptor, he now creates installations and performances,
      using the body as subject and medium. Ezzam investigates the human
      condition, with a particular focus on marginality, absence/presence and
      impermanence.
    </p>

    <p>
      you’re not all alone in this, you’re not alone i promise also features
      sound by <strong>Andy Yang</strong>.
    </p>

    <p>-</p>

    <strong>How has the pandemic affected your practice?</strong>
    <p>
      “As a multidisciplinary artist who practices performance art, I definitely
      miss the ‘performance arena / space’ where I get to present my work and
      get immediate response from my audiences in real time - real space.
    </p>

    <p>
      I miss presenting artworks physically, I miss the gallery space. There was
      a sudden urgency for artists to move their practise online the past year.”
    </p>

    <strong>
      As a sculptor that works with found objects, what was difficult to bring
      your practise online?
    </strong>
    <p>
      “For me digital presentation is relatively new to me, yes I do have
      experiences in presenting my works digitally but this cycle definitely was
      a challenge. ... I still think that presenting works digitally is harder
      because we are constantly online either working or for entertainment.”
    </p>

    <p>
      “While everything shifts online, the audience (including myself) is
      constantly bombarded with visuals and we have ‘web fatique’ from working
      on the computer for hours… Hence the difficulty for me is to create
      something that is worthwhile for my audience to invest their time on.”"
    </p>
  </>
)

const BernyInfo = () => (
  <>
    <ProfileImage src={bernyProfile} />
    <p>
      <strong>Berny Tan</strong> is an artist, curator, and writer. Her
      interdisciplinary practise explores the tensions that arise when one
      applies systems to – and unearths systems in – intangible personal
      experiences. In doing so, she challenges the binary between the rational
      and emotional.
    </p>

    <p>-</p>

    <p>Describe your practice.</p>

    <p>
      “As an artist, I pivot between different mediums. I interweave embroidery,
      drawing, installation, graphic design, and writing. My strategies also
      often reflect a fundamental interest in language as it is read, written,
      and spoken by myself. I see language itself as a system through which my
      subjectivities can be simultaneously articulated and encoded.”
    </p>

    <p>How has the pandemic affected your practice?</p>

    <p>
      “The most obvious effect is that I created the Instagram account{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/tape_measures/"
      >
        @tape_measures
      </a>
      , which functions as a visual record of safe distancing markers in
      Singapore during the COVID-19 pandemic, focusing particularly on the use
      of tape. I’m still not sure where to position it within my practice, but
      there are resonances between this project and my artistic concerns —
      @tape_measures is an archive of the subjectivities that manifest within
      this given system.”
    </p>
  </>
)

const ChurenEtAlInfo = () => (
  <>
    <ProfileImage src={churenProfile} i={0} />
    <ProfileImage src={hellowProfile} i={1} />
    <ProfileImage src={subhasProfile} i={2} />
    <ProfileImage src={timdecottaProfile} i={3} />
    <ProfileImage src={weishProfile} i={4} />
    <p>
      <strong>Churen Li</strong> is an internationally acclaimed Singaporean
      pianist. She is an active recitalist, orchestral soloist and an avid
      chamber musician. She is currently an artist fellow at Yong Siew Toh
      Conservatory of Music (NUS) and part-time academic faculty at Yale-NUS.
    </p>

    <p>
      <strong>Hell Low</strong> is a singer-songwriter whose music is steeped in
      melancholia. LIke words in a diary, his lyrics are a mirror to a soul
      drawn into despondency by the rituals of living and dying in Singapore.
    </p>

    <p>
      <strong>Subhas</strong> is a rap/hip-hop artist who seeks to share
      perspectives of the world around him and engage people through his music
      and lyricism. Subhas believes active allyship is crucial in dismantling
      systems of oppression and uses his writing as a tool to provoke dialogue.
    </p>

    <p>
      <strong>Tim De Cotta</strong> is a vocalist, bassist, songwriter and
      producer who believes in keeping his art pure. Having played with various
      bands, he constantly gets his hands dirty in musical diversity; pushing
      the boundaries in writing and production. He is a highly sought-after
      sessionist and has also started arts curation agencies Getai Group and
      Warrior Productions.
    </p>

    <p>
      <strong>weish</strong> is a composer, musician, writer, and Associate
      Artist with Checkpoint Theatre. Her work spans diverse genres and
      disciplines, from stage to sound art.
    </p>

    <p>-</p>

    <p>
      Could you describe the musical process of the work? What were some
      inspirations?
    </p>

    <p>
      “Conceptualising and shaping the work was an ever-evolving conversation
      within the whole team – deciding how abstract or literal the sonic
      conversation would be, how the vastly different genres of music could
      blend or interact in a non-kental way, each artist’s comfort level with
      modes of performance they weren’t otherwise used to, etc. Everyone made
      the whole process really fun though, and gave a part of themselves I was
      privileged to witness.”
    </p>

    <p>
      In the spirit of Love Lockdown, could you recommend 3 songs about love.
      Maybe people can listen to them whilst scrolling dating apps.
    </p>

    <p>
      Churen:
      <br />
      “Someday My Prince will Come” - Bill Evans Trio
      <br />
      “Merry-Go-Round of Life” - Joe Hisaishi
      <br />
      “Prelude and Liebestod” from Tristan and Isolde - Richard Wagner
    </p>

    <p>
      Hell Low:
      <br />
      “To be alone with you” - Sufjan Stevens
      <br />
      “Don't know how to keep loving you” - Julia Jacklin
      <br />
      “Your boyfriend's gun” - Palehound
    </p>

    <p>
      Subhas:
      <br />
      “Choices (Yup)” - E-40
      <br />
      “WAP” - Cardi B, Megan Thee Stallion
      <br />
      “Link Up” - Subhas w/NyaLi
    </p>

    <p>
      Tim de Cotta:
      <br />
      “She Needs Me” - Marvin Gaye
      <br />
      “Reunion” - Maxwell
      <br />
      “143” - Musiq Soulchild
    </p>

    <p>
      Weish:
      <br />
      “first day of my life” - Bright Eyes
      <br />
      “Night and day” - Frank Sinatra’s version (originally sung by Cole Porter)
      <br />
      “Nakamarra” - Hiatus Kaiyote
    </p>

    <p>
      You can listen to these songs via{' '}
      <a
        h
        target="_blank"
        rel="noreferrer"
        href="https://open.spotify.com/playlist/6KA3fGy7a1p1YqopCeTf9e?si=d0d7a7eb1b5d4696"
      >
        the Love Lockdown playlist on Spotify
      </a>
      .
    </p>
  </>
)

const WaitEtAlInfo = () => (
  <>
    <ProfileImage src={khengProfile} i={0} />
    <ProfileImage src={robProfile} i={1} />
    <ProfileImage src={shianProfile} i={2} />
    <p>
      <strong>Tan Kheng Hua</strong> is an award-winning actor/producer from
      Singapore.
    </p>

    <p>
      <strong>Robert Wait</strong> is a composer, songwriter, producer, and
      audio engineer living in Los Angeles, California.
    </p>

    <p>
      <strong>Lim Shi-An</strong> is an actress and designer. Lim has
      professional experience as a graphic designer and a passion for singing.
    </p>

    <p>
      <strong>Bailey Wait</strong> is a photographer currently based in
      Brooklyn, New York. She uses photography to capture the liquid nature of
      light.
    </p>

    <p>-</p>

    <p>
      <strong>
        What was it like to collaborate on this project as a group?
      </strong>
    </p>
    <p>
      Kheng: "I have worked with Shi-An a lot, and in many different capacities
      – as actors, as producer and designer, as a general sounding platform. I
      have never worked with Rob. And we have never worked together as a trio.
      So this is a first. My main joy was to see them create. I have always
      loved their creations. My main difficulty was the time and geographical
      difference, and how sometimes, I enjoy their company so much, I just don’t
      feel like working and just want to play instead."
    </p>

    <p>
      Shi-An: "We work really well together and have a good working dynamic
      despite having to communicate completely virtually for now."
    </p>

    <p>
      Rob: "I’ve been lucky enough to have had Shi-An sing a few of my songs in
      the past, and this project also brought the joy of hearing Kheng sing too.
      I’m a big fan of all of the talents they bring to everything they do, from
      musical, to dramatic, to visual. It was fun to dream up and complete this
      site together. I was also pleased that we all felt my daughter Bailey’s
      photography fit the mood of what we were going for too, so it became a
      two-family collaboration in one part of the site."
    </p>

    <p>
      <strong>
        All three of you express yourselves in different ways - music, writing,
        illustration. Any difficulties piecing together three different creative
        outputs for this work?
      </strong>
    </p>
    <p>
      Kheng: "Finding a common aesthetic was something I felt we had to discover
      because we have never had to gel our different voices together in a
      singular piece of work before. As with all collaborations, some notes just
      fell harmoniously into place, and others required a bit more give and
      take."
    </p>

    <p>
      Shi-An: "I am in charge of generally putting together the site! So I guess
      I was a bit stressed out with the technical side of this and piecing it
      all together in a way that pleases everyone. But everybody has been very
      patient and encouraging hehe."
    </p>

    <p>
      Rob: ""It was nice that each of us were free to explore the themes using
      our own artistic “tools'' of choice, and then bring the results to the
      group. Something one of us would create would often inspire the others to
      add to the story using their own forms of expression. This made it very
      non-competitive and more multifaceted than perhaps would have resulted if
      we had all used the same form of expression.""
    </p>
  </>
)
const YenInfo = () => (
  <>
    <ProfileImage src={yenProfile} />
    <p>
      Yen Phang inhabits space where the biological rubs against the control. He
      is currently exploring concepts of dirt and the regulation of bodily
      processes and interactions through painting and performance.
    </p>

    <p>-</p>

    <p>How would you describe your practice? </p>

    <p>
      “I always tell myself and other curators that ideally, my works should
      feel like longkang water (drain water). Unspectacular, rather invisible at
      times, but always there beside you, near you, quietly flowing, performing
      its essential functions, sometimes hosting other life forms.
    </p>

    <p>What do I care about as an artist? </p>

    <p>
      1) how our experiences of the world are always shaped through the lens of
      flesh and nature, the senses and the material world, and how this might
      change as we move into an age of no-contact and the digital;
    </p>

    <p>
      2) how we may model fallibility, and what I mean by this is whether we can
      create space for softness, failure, and uncertainty, during a time when
      our beliefs are so polarised, and when confidence and strength are
      qualities that are valorised in our self-help culture and
      political/intellectual leaders.”
    </p>

    <p>How are you doing?</p>

    <p>
      “Thanks for asking this question. I’ve been alright, thanks. More
      comfortable with uncertainty, more okay with difficult opinions and
      unpleasant news, less avoidant of the discomfort of others too. It’s quite
      bracing and grounding to be able to look at reality in the face and
      embrace both laughter and anger or peace and sadness all at the same time.
      I think what I can be grateful for is the dependability of the current
      experience of still behind here now in flesh, and the knowledge that it
      will be gone some day in the future.”
    </p>
  </>
)

const ArtistInfo = ({ artist }) => {
  switch (artist) {
    case 'Lynn Lu':
      return <LLInfo />
    case 'Denise Yap':
      return <DYInfo />
    case 'Ezzam Rahman':
      return <EzzamInfo />
    case 'Berny Tan':
      return <BernyInfo />
    case 'Churen Li, Hell Low, Subhas Nair, Tim De Cotta, Weish':
      return <ChurenEtAlInfo />
    case 'Bailey Wait, Lim Shi-An, Robert Wait, Tan Kheng Hua':
      return <WaitEtAlInfo />
    case 'Yen Phang':
      return <YenInfo />
    default:
      return null
  }
}

const DYArtworkInfo = () => (
  <>
    <h3>ABOUT THE ARTWORK</h3>
    <p>
      Flan’s Glog is a series of video game vlogs or ‘glogs’, in which Denise
      plays Elder Scrolls V: Skyrim in a slow and measured way.
    </p>

    <p>
      Here, Denise adapts a popular form of content-making - video game
      playthroughs or vlogs. In these, players often focus on demonstrating
      skill, strength and experience.
    </p>

    <p>
      But Flan’s Glog is different. It doesn’t demand attention. Instead, it
      aims to send you to sleep.
    </p>

    <p>
      The work was born from Denise's inability to sustain sleep.
      Unintentionally ejected from sleep, they would often play games as an
      alternate form of rest or a method to lull themselves to sleep.
    </p>

    <p>
      With video games, people often imagine players killing hostiles violently
      and gruesome deaths. But this isn’t great when you’re trying to sleep.
    </p>

    <p>
      When trying to rest, Denise plays open-world games like Skyrim, which
      allow players to wander a virtual world freely and peacefully.
    </p>

    <p>
      In this work, you follow Flan, who is uninterested in fighting. They
      prefer more wholesome things such as making friends, taking walks,
      building a home and sleeping.
    </p>

    <ImageRow>
      <ArtworkImage src={denise1} alt="" />
      <ArtworkImage src={denise2} alt="" />
    </ImageRow>
    <ImageRow>
      <ArtworkImage src={denise3} alt="" />
      <ArtworkImage src={denise4} alt="" />
    </ImageRow>

    <p>
      If you can’t sleep, find Flan. Denise explains, “There is nothing to
      understand about this work, so please do not feel pressured to make sense
      of it. Just enjoy it. The work is low maintenance and is an accompaniment
      on your route to rest or sleep.”
    </p>

    <p>
      Explore the world of Skyrim together with Denise, as they converse with
      their viewers and enjoy the beautiful mountain ranges of Skyrim.
    </p>
    <p>
      Watch it here:{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://bit.ly/LetsPlaywithDeniseYap"
      >
        http://bit.ly/LetsPlaywithDeniseYap
      </a>
    </p>
  </>
)

const LLArtworkInfo = () => (
  <>
    <h3>ABOUT THE ARTWORK</h3>
    <p>
      La Peste is a lecture-performance inspired by Albert Camus’ novel The
      Plague.
    </p>
    <p>
      Lynn’s ‘lecture’ brings into play verbatim text excerpts from The Plague,
      which she chose for the style of its prose. It is sparse, haunting, and
      contemporary. The narration is accompanied by images from myriad sources.
      Together, they document humanity’s reactions to a pandemic – absurd,
      cunning, and kind, and the politics and tragedies of plagues.
    </p>

    <ImageRow>
      <ArtworkImage src={lynn1} alt="" />
      <ArtworkImage src={lynn2} alt="" />
    </ImageRow>
    <ImageRow>
      <ArtworkImage src={lynn3} alt="" />
      <ArtworkImage src={lynn4} alt="" />
    </ImageRow>
    <p>
      Lynn was interested “in how no one (beyond epidemiologists and doomsday
      preppers) could have anticipated the scale of such a pandemic, nor the
      bizarreness of some of the responses to this calamity – including flat out
      denial, toilet paper hoarding and deliberate infecting of others” but soon
      realised that “humankind has played out these scenarios to a T on loop,
      since forever.”
    </p>
    <p>
      Amidst the global and historical, Lynn also documents individual personal
      experiences of the pandemic. A sequence of images chronicles a friend's
      24-hour period in hotel quarantine. Another series features Facebook posts
      by a different friend, who photographed taped-off areas during the circuit
      breaker in Singapore.
    </p>
    <p>
      The work also often focuses on Singapore and Britain. Lynn lives between
      both countries and is personally experiencing the stark difference in how
      the pandemic continues to unfold on both islands.
    </p>
    <p>
      In Camus’ book, the plague ends. Suddenly and mercifully. In Lynn’s work,
      the plague ends and the hills are alive with the sound of music.
    </p>
    <p>We wait for a similar ending in real life.</p>
    <p>
      OH! curator Kirti discusses Lynn Lu's lecture-performance 'La Peste' with
      OH! volunteer Lynette and Dr. Liew Kai Khiun. Hear their stories and
      understanding of the pandemic through Lynn Lu's work.
    </p>
    <p>
      Watch it here:{' '}
      <a target="_blank" rel="noreferrer" href="http://bit.ly/ArtChatLynn">
        http://bit.ly/ArtChatLynn
      </a>
    </p>
  </>
)

const EzzamArtworkInfo = () => (
  <>
    <h3>ABOUT THE ARTWORK</h3>
    <p>
      As an artist, Ezzam has the unique privilege of addressing his worries
      through his work.
    </p>
    <p>
      you’re not alone in all this, you’re not alone i promise reflects the
      stories of single men in Singapore.
    </p>
    <p>
      As you click through the work, you’re taken from photos to videos to
      websites.
    </p>
    <p>
      These fragments slowly paint a picture about life in Singapore. The work
      showcases the homes, routines and worries of people who own a home, but
      live alone.
    </p>
    <p>
      Whilst making the work, Ezzam spoke with the participants about why they
      bought their own home, or what ‘distance’, ‘home’ and ‘loneliness’ meant
      to them.
    </p>
    <p>
      Sometimes, these answers appear explicitly – revealed as quotes attached
      to an image presented by Ezzam in the work. At other times, they are
      conveyed subtle through a photograph or piece of music.
    </p>
    <ImageRow>
      <ArtworkImage src={ezzam1} alt="" />
      <ArtworkImage src={ezzam2} alt="" />
    </ImageRow>
    <ImageRow>
      <ArtworkImage src={ezzam3} alt="" />
      <ArtworkImage src={ezzam4} alt="" />
    </ImageRow>
    <p>
      Over the course of making the work, Ezzam was ""touched by the fact that
      some of the participants thanked him for doing this for them because some
      of them never get to or are afraid to address these humanly issues.""
    </p>
    <p>At the end, Ezzam’s work reminds us:</p>
    <p>
      Home is not permanent.
      <br />
      Distance can be closer than you think.
      <br />
      Loneliness is when you are forgotten.
      <br />
    </p>
    <p>We are all seeking our own definition of happiness.</p>

    <p>
      Artist Ezzam Rahman breaks down his work 'you are not alone in this,
      you're not alone i promise' with OH! curator Kirti.
    </p>
    <p>
      Watch it here:{' '}
      <a target="_blank" rel="noreferrer" href="http://bit.ly/ArtChatEzzam">
        http://bit.ly/ArtChatEzzam
      </a>
    </p>
  </>
)

const BernyArtworkInfo = () => (
  <>
    <h3>ABOUT THE ARTIST</h3>
    <p>
      In present-day Singapore, it is still common to see tape measures
      everywhere.
    </p>
    <p>
      Keeping Distancing features images of these safe distancing markers. Some
      of Berny’s writing was directly inspired by a specific image or type of
      marker (like the two imaginary dialogues titled The Box and The Queue).
    </p>

    <ImageRow>
      <img src={berny1} alt="" />
      <img src={berny2} alt="" />
    </ImageRow>
    <p>
      Other pieces consider the nature of ‘distancing’ or ‘isolation’ beyond the
      pandemic. The work as a whole “meditates on the discomforts of a body that
      is isolated by choice, rather than through rules imposed externally.”
    </p>
    <p>
      Berny believes that safe distancing has made people... “imposition of safe
      distancing has made people more acutely aware of their bodies and the
      distance between their own body and the next person’s. I think this is a
      really interesting space for my writing to exist, because I am writing
      about exactly this kind of awareness that I have personally been living
      with for much longer than the pandemic itself.”
    </p>
    <p>
      Keeping Distance unfolds over time. Words and images slowly appear on the
      page. Each vignette takes a different form than the one before. Some are
      fictional, others inspired by real memories.
    </p>
    <ImageRow>
      <img src={berny3} alt="" />
      <img src={berny4} alt="" />
    </ImageRow>
    <p>
      As a whole, the work invites audiences to “think more deeply about their
      body and their body as it interacts—or doesn’t—with others. To consider
      the comforts and discomforts of isolation. To reflect on what intimacy
      really means and how it manifests within their own lives.”
    </p>
  </>
)

const LoveLockdownArtworkInfo = () => (
  <>
    <p>‘The pandemic brought me here.’</p>
    <p>‘Hoping to be in town soon, looking for someone to go out with.’</p>
    <p>‘In SHN rn, looking to meet after I’m out.’</p>
    <p>
      The pandemic has not stopped anyone from seeking out love, but it has
      changed the way in which we navigate the tricky waters of online dating.
    </p>
    <p>
      Love Lockdown was conceptualised back when the pandemic measures were much
      tighter than they are now. Meeting somebody, especially for the first
      time, was out of the question. People dated from a distance.
    </p>
    <p>
      But what happens when you meet new people on a small screen? When there
      isn’t room to wine and dine, or let palms graze the others on a long walk
      home. Worlds and pixels are all you have left to figure out if someone is
      the one for you.
    </p>
    <p>
      In Love Lockdown, music takes the place of words. A pianist looks for love
      from her living room, responding to the songs played to her by potential
      suitors. Sometimes she is charmed, other times confused.
    </p>
    <ImageRow>
      <img src={lovelockdown1} alt="" />
      <img src={lovelockdown2} alt="" />
    </ImageRow>
    <ImageRow>
      <img src={lovelockdown3} alt="" />
      <img src={lovelockdown4} alt="" />
    </ImageRow>
    <p>
      If you’ve been on a dating app, the whole situation might feel painfully
      familiar. The work is a cheeky, musical introspection on the absurdity of
      dating, not just during these bleak times, but all the time.
    </p>
    <p>
      Dating apps are here to stay. We will continue to connect online, swipe
      left and right, taking chances on people, start conversations, end
      conversations and find that we don’t have chemistry with most people.
    </p>
    <p>But there is always the hope of finding the one. </p>
    <p>
      Watch artists Churen Li, Hell Low, Subhas Nair and Tim De Cotta as they
      chit chat about what inspires their work and practices, and answer the
      burning questions viewers had for them.
    </p>
    <p>
      Watch it here:{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://bit.ly/LoveLockdownLiveChat"
      >
        http://bit.ly/LoveLockdownLiveChat
      </a>
    </p>
  </>
)

const MilesArtworkInfo = () => (
  <>
    <p>
      When you’re lost at sea, you look for the shoreline. You wait for the
      moment you can return home.
    </p>
    <p>
      But what if, for the time being, there is no homecoming? Only waiting.
    </p>
    <p>
      Finding themselves in different parts of the world, Kheng, Shi-An, Bailey
      and Robert, had to figure out the answer to this question.
    </p>
    <p>
      Their work, Miles To Go Before We Sleep, Miles To Go Before We Wake, is
      one possible answer. It is a collaborative online diary that features the
      many things that keep them connected.
    </p>
    <p>
      The group stays connected via drawings and dreams, videos and songs. As
      Shi-An shares, “art (and technology) helps to bridge that emotional gulf
      that occurs when you are alone and separated from your loved ones.”
    </p>
    <p>
      In these fragments of their life, the sea Is a recurring motif. It shifts
      from holding the sadness of being apart, to being a space that brings them
      together, wherever they are.
    </p>
    <ImageRow>
      <img src={khengvideos1} alt="" />
      <img src={khengmusic2} alt="" />
    </ImageRow>
    <p>
      It appears in the song Robert wrote, Lost At Sea, which was “born in the
      flicker of a candle when a fond memory suddenly appeared on the edge of
      the candle glow in my room. I remember desperately wanting it to be real
      rather than a fleeting memory, but as reality took over, I realized how
      alone I really felt. I went through several different lyrical themes to
      define that aloneness, but ultimately realized that being lost at sea,
      riding waves of both desperation and occasional hope, really captured how
      I was feeling.”
    </p>
    <p>
      At the same time, the sea and the sky are also “elements that we all share
      no matter where we are in the world.” When people feel far away, they
      remind Kheng that she is not so distant from the people she loves.
    </p>
    <p>
      If you too are lost at sea, their work might offer some comfort. And
      remind you that you are not alone. These memories are the artists’ own,
      but they invite us to remember the things and moments that connect us to
      each other. A shared meal, a beloved place, or a special song.
    </p>
    <p>All things to hold onto while waiting for a homecoming.</p>
  </>
)

const YenArtworkInfo = () => (
  <>
    <p>Value Store is an artwork and a store. </p>
    <ImageRow>
      <img src={yenlistings} alt="" />
    </ImageRow>
    <p>
      In creating this space, Yen opens up ways of thinking about archives, the
      way we tell stories and write memories. He chooses to do so not through
      any grand means, but via Carousell, an accessible platform that is already
      an archive of sorts. Carousell reflects back to us the nature of our
      lives, the things we value as commodities and the stories we tell about
      them, in order to convey their value or status to others.{' '}
    </p>
    <p>
      Yen’s own reflections are tied to the objects for sale in his store. Each
      is accompanied by a story. Some are about the everyday – a meal, a
      conversation, a gift. Others deal with illness, loss, and grief.{' '}
    </p>
    <p>
      In Yen’s own words, Value Store “considers the nature of memory, the grief
      process, and absence, through the lens of household objects to be kept or
      discarded.”{' '}
    </p>
    <p>
      Value Store is also about letting go. Listed online, these objects are not
      merely ‘objects in an artwork’ but ‘objects for sale’. Their stories could
      go on for a little longer. They could be associated with new memories,
      should someone place a bid, purchase them and make them a part of their
      lives.{' '}
    </p>
    <p>
      By creating an artwork that is also a store, Yen also invites us to
      consider how we acquire things, and to pay attention to what it is that we
      buy, keep, or collect. Yen also hopes we might consider what it is that we
      have lost or are letting go of, be it people, experiences, things, or a
      way of life.{' '}
    </p>
    <p>
      In this way, we might think about reshaping the narratives of what is
      meaningful in our own lives now, and maybe even tell stories about them to
      others. Although how and when we share these stories, it's up to you.{' '}
    </p>
    <p>
      You might share this story when a friend notices the object whilst
      visiting your house, take a picture and write an instagram post about it,
      make an artwork or simply write a fond farewell via the listings text when
      selling the item on Carousell.{' '}
    </p>
    <p>
      In spending time with our own stories on whatever platforms, we might
      “open up space for considering interfaces not just as transactional
      products, but as autonomous zones of interaction that mediate between our
      more personal, subjective realities.” And consider “how we shape or hide
      our own histories and identities within the public formats presented to us
      online.”
    </p>
  </>
)

const ArtworkInfo = ({ name }) => {
  switch (name) {
    case 'Flan’s Glog':
      return <DYArtworkInfo />
    case 'La Peste':
      return <LLArtworkInfo />
    case "you're not alone in all this, you're not alone i promise":
      return <EzzamArtworkInfo />
    case 'Keeping Distance':
      return <BernyArtworkInfo />
    case 'Love Lockdown':
      return <LoveLockdownArtworkInfo />
    case 'Miles To Go Before We Sleep, Miles To Go Before We Wake':
      return <MilesArtworkInfo />
    case 'Value Store':
      return <YenArtworkInfo />
    default:
      return null
  }
}

const E1Info = () => (
  <>
    <h3>ABOUT THE EPISODE</h3>
    <p>
      In Episode 1: Reality & Rest, the Sleeping Man is faced with sobering
      reality: that this pandemic might only just be a blip in human history.
      Humankind has lived through many crises and calamities. COVID-19 is just
      one of many.
    </p>

    <p>
      Yet, reality is hard to face, and people often find ways to escape. The
      Sleeping Man finds solace the only way he knows how to - through games.
    </p>

    <p>
      Episode one features works by artists Denise Yap and Lynn Lu. While Lynn
      Lu's artwork highlights how humankind has slogged through such trying
      times, Denise Yap's work offers a respite from our current reality.
    </p>
  </>
)

const E2Info = () => (
  <>
    <h3>ABOUT THE EPISODE</h3>
    <p>
      In Episode 2: Home Alone, the Sleeping Man ruminates on how the home he
      lives in feels bigger and more lonely during such times.
    </p>

    <p>
      A text message from a friend sharing an artwork relieves some of the
      loneliness. A lengthy discussion around the artwork commences, touching on
      what it is like to live alone.
    </p>

    <p>
      Artist Ezzam Rahman responds to such notions of isolation, featuring
      personal stories of homeowners who live alone in such times.
    </p>
  </>
)

const E3Info = () => (
  <>
    <h3>ABOUT THE EPISODE</h3>
    <p>
      In Episode 3: Distance Kept, Distance Bridged, the Sleeping Man negotiates
      where he draws the line, in a time where distances has to be kept.
    </p>

    <p>
      While he stresses on keeping physical distance, he begins to reach out
      over the virtual space to find love and companionship.
    </p>

    <p>
      Artist Berny Tan explores a taped up city and the boundaries it creates in
      her work. Artist Weish and co smashes through those boundaries in an
      attempt to find love during such times.
    </p>
  </>
)

const E4Info = () => (
  <>
    <h3>ABOUT THE EPISODE</h3>
    <p>No matter the distance, people find ways to overcome it.</p>

    <p>
      In episode 4: Working Through, Making Do, the Sleeping Man discovers that
      relationships are held together by various rituals people perform. And
      maybe, that's how he can overcome the pandemic.
    </p>

    <p>Maybe, it's time for him to wake up now.</p>

    <p>
      The final episode features works by Tan Kheng Hua and Yen Phang. Both
      Kheng and Yen's work explores how people connect over thousand of miles,
      even ones that are infinite.
    </p>
  </>
)

const EpisodeInfo = ({ name }) => {
  switch (name) {
    case 'E1: Reality & Rest':
      return <E1Info />
    case 'E2: Home Alone':
      return <E2Info />
    case 'E3: Distance Kept, Distance Bridged':
      return <E3Info />
    case 'E4: Working Through, Making Do':
      return <E4Info />
    default:
      return null
  }
}

export { ArtistInfo, ArtworkInfo, EpisodeInfo }
