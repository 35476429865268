import Window from '../Window'

const BernyArt = (props) => (
  <Window {...props}>
    <iframe
      title="berny"
      width="100%"
      height="100%"
      style={{ border: 'none' }}
      src="https://readymag.com/ohopenhouse/2469301/"
    ></iframe>
  </Window>
)

export default BernyArt
