import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import useSound from 'use-sound'
import { TextContainer } from '../components/Text'
import Button from '../components/Button'
import MoreInfo from '../components/MoreInfo'
import ep2Content from '../content/ep2-content'

import bg from '../assets/img/e2-room-bg.png'
import bedroom from '../assets/sound/bedroom2.wav'
import curation from '../assets/sound/curation2.wav'
import Phone from '../components/Phone'
import { useHistory } from 'react-router'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
`
const ImageContainer = styled.div`
  position: fixed;
  width: 100vw;
`
const StyledImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Fixed = styled.div`
  position: fixed;
`

const StyledButton = styled(Button)`
  width: auto;
  font-size: 1.5em;
  background-color: ${(props) => props.theme.colors.hotMagenta};
  color: white;
  animation: fadeIn ease 0.5s;
`
const LoveLettersButton = styled(StyledButton)`
  position: fixed;
  top: 75%;
  left: 20%;
`

const LaptopButton = styled.button`
  position: fixed;
  bottom: 0;
  right: 25%;
  width: 15%;
  height: 20%;
  background: white;
  opacity: 0;
  box-shadow: 60px -16px teal;
  transition: 0.5s;
  cursor: pointer;
  clip-path: polygon(
    40% 0,
    37% 53%,
    2% 69%,
    50% 100%,
    66% 100%,
    89% 78%,
    95% 10%,
    41% 0
  );

  &:hover {
    opacity: 0.5;
  }
`

const Ending = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  padding: 100px 0;

  animation: endingbg 6s;
  @keyframes endingbg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const BigText = styled.p`
  width: 50vw;
  margin: 100px 25vw;
  text-align: center;
  font-family: Roslindale;
  font-size: 5em;
  color: white;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 700px;
  margin: 0 auto;
`

const FirstButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  margin: 0 10px;
  padding: 30px 80px;
  cursor: pointer;
  border: 1px solid white;
  background: none;
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const SecondButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 80px;
  height: 120px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  background: ${(props) => props.theme.colors.darkPurple};
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const EpButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  background: ${(props) => props.theme.colors.darkPurple};
  color: white;
  cursor: pointer;
  border: none;
  padding: 20px;
  margin: 20px 4px;
  flex: 1;
  transition: 0.3s;

  animation: fadeIn ease ${(props) => `${1 + props.i * 0.3}s`};

  &:hover {
    background: white;
    color: black;
  }

  @keyframes fadeIn {
    0% {
      transform: translate(0, 40px);
      opacity: 0;
    }
    100% {
      tranform: translate(0);
      opacity: 1;
    }
  }
`

const EpisodeTwo = () => {
  const textRef = useRef()
  const phoneRef = useRef()
  const history = useHistory()
  const [loveLettersText, setLoveLettersText] = useState('')
  const loveLettersBtnCallbackRef = useRef()
  const [showInfo, setShowInfo] = useState(false)
  const [showPhone, setShowPhone] = useState(false)

  const [ending, setEnding] = useState(false)

  const [playBedroom, bedroomTrack] = useSound(bedroom, { loop: true })
  const [playCuration, curationTrack] = useSound(curation, { loop: true })

  useEffect(playBedroom, [playBedroom])

  useEffect(() => {
    loveLettersBtnCallbackRef.current = () => {
      textRef.current.addNode('etwo16')
      setLoveLettersText('')
    }
  }, [])

  const playCurationMusic = () => {
    bedroomTrack.stop()
    playCuration()
  }
  const playBedroomMusic = () => {
    curationTrack.stop()
    playBedroom()
  }

  const endEpisode = () => {
    ReactGA.event({
      category: 'Completion of episode (DAC S2)',
      label: 'DAC S2 - Completed EP2',
      action: 'Reached the end of S2 EP2',
    })
    setEnding(true)
  }

  const handleRestart = () => {
    ReactGA.event({
      category: 'Button (DAC S2)',
      label: 'DAC S2- Replayed EP2',
      action: 'Replay (EP2)',
    })
    bedroomTrack.stop()
    curationTrack.stop()
    playBedroom()
    textRef.current.reset()
    phoneRef.current.reset()
    setEnding(false)
    setShowPhone(false)
    setShowInfo(false)
  }
  const handleReturn = () => {
    setEnding(false)
  }
  const handleExit = () => {
    ReactGA.event({
      category: 'Button (DAC S2)',
      label: 'DAC S2 - Exited at end',
      action: 'Clicked the Exit button at end of experience',
    })
  }

  const triggerEvent = (id) => {
    switch (id) {
      case 'playbedroommusic':
        playBedroomMusic()
        break
      case 'eatloveletters':
        setLoveLettersText('Eat the love letters.')
        break
      case 'openphone':
        setShowPhone(true)
        break
      case 'phone-etwo41':
        setTimeout(() => {
          phoneRef.current.addNode('etwo41')
        }, 3000)
        break
      case 'phone-etwo43':
        setTimeout(() => {
          phoneRef.current.addNode('etwo43')
        }, 3000)
        break
      case 'phone-etwoxa0':
        setTimeout(() => {
          phoneRef.current.addNode('etwoxa0')
        }, 3000)
        break
      case 'phone-etwoxb0':
        setTimeout(() => {
          phoneRef.current.addNode('etwoxb0')
        }, 3000)
        break
      case 'phone-etwoz6':
        setTimeout(() => {
          phoneRef.current.addNode('etwoz6')
        }, 3000)
        break
      default:
        break
    }
  }

  const handleEpClick = (url, category, label, action) => {
    ReactGA.event({ category, label, action })
    history.push(url)
  }

  return (
    <Container>
      <ImageContainer>
        <StyledImage src={bg} />

        {Boolean(loveLettersText) && (
          <LoveLettersButton
            direction="outgoing"
            onClick={loveLettersBtnCallbackRef.current}
          >
            {loveLettersText}
          </LoveLettersButton>
        )}
        <LaptopButton
          onClick={() => {
            if (ending) return
            ReactGA.event({
              category: 'Click (DAC S2)',
              label: 'DAC S2 - Clicked laptop',
              action: 'Clicked S2 laptop',
            })
            setShowInfo(true)
          }}
        />
      </ImageContainer>
      <Fixed>
        <TextContainer
          ref={textRef}
          contentObj={ep2Content}
          triggerEvent={triggerEvent}
        />
      </Fixed>
      <Phone
        playCurationMusic={playCurationMusic}
        stopBedroomMusic={bedroomTrack.stop}
        endEpisode={endEpisode}
        show={showPhone}
        ref={phoneRef}
        mainAddNode={textRef.current ? textRef.current.addNode : () => {}}
      />
      <MoreInfo show={showInfo} onClose={() => setShowInfo(false)} />

      {ending && (
        <Ending>
          <BigText>Next episode launches at 05/03/2021, 06:00</BigText>
          <Row>
            <FirstButton onClick={handleRestart}>RESTART</FirstButton>
            <FirstButton onClick={handleReturn}>BEDROOM</FirstButton>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4yBXfROmiLd0UjjdoIYoWwZEptel5sKUHnDhBhamEuQJUXg/viewform?usp=sf_link">
              <SecondButton>GIVE FEEDBACK</SecondButton>
            </a>
            <a href="https://ohopenhouse.give.asia/">
              <SecondButton onClick={handleExit}>EXIT</SecondButton>
            </a>
          </Row>
          <Row>
            <EpButton
              i={1}
              onClick={() =>
                handleEpClick(
                  '/prologue',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 1 from another ep',
                  'Clicked to enter Episode 1 from end of an ep',
                )
              }
            >
              Episode One
            </EpButton>
            <EpButton
              i={2}
              onClick={() =>
                handleEpClick(
                  '/ep3',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 3 from another ep',
                  'Clicked to enter Episode 3 from end of an ep',
                )
              }
            >
              Episode Three
            </EpButton>
            <EpButton
              i={3}
              onClick={() =>
                handleEpClick(
                  '/ep4',
                  'Button (DAC S2)',
                  'DAC S2 - Entered Episode 4 from another ep',
                  'Clicked to enter Episode 4 from end of an ep',
                )
              }
            >
              Episode Four
            </EpButton>
          </Row>
        </Ending>
      )}
    </Container>
  )
}

export default EpisodeTwo
