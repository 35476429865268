import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NextBtn } from '../../pages/Prologue'
import Slide from '../Slide'

const BigText = styled.p`
  text-align: center;
  font-family: Roslindale;
  font-size: 5em;
  color: white;
`
const DelayedText = styled.p`
  text-align: center;
  font-family: Roslindale;
  font-size: 5em;
  color: white;
  margin: 0;

  animation: fadeUp ease 3s;

  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    50% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 30vh;
  left: 15vw;
  width: 70vw;
`

const Scene9 = ({ slideId, setSlideId }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (slideId !== 10) return
    setTimeout(() => {
      setShow(true)
    }, 3000)
  }, [slideId])

  if (slideId !== 10) return null

  return (
    <Slide>
      <Content>
        <BigText>Home has never been more familiar to you...</BigText>
        <DelayedText>You have all you need here.</DelayedText>
      </Content>
      <NextBtn show={show} onClick={() => setSlideId(11)}>
        {'>'}
      </NextBtn>
    </Slide>
  )
}

export default Scene9
