import Slide, { SlideText } from '../Slide'
import couch from '../../assets/img/prologue-couch.png'
import couchNegative from '../../assets/img/prologue-couch-negative.png'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

const StyledImg = styled.img`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 60vh;
`
const StyledMask = styled.img.attrs((props) => {
  let clipPath = ''
  for (let i = 0; i <= 20; i++) {
    clipPath += `
      0 ${(props.offset || 0) + 1000 - i * 100}px,
      100% ${(props.offset || 0) - 50 + 1000 - i * 100}px,
      100% ${(props.offset || 0) + 1000 - i * 100}px,
      0 ${(props.offset || 0) + 50 + 1000 - i * 100}px${i === 20 ? '' : ','}`
  }
  return {
    style: {
      clipPath: `polygon(${clipPath})`,
    },
  }
})`
  position: fixed;
  top: 20vh;
  max-width: 900px;
  max-height: 60vh;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Scene1 = ({ slideId }) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const cbDate = new Date(2020, 3, 7) // month starts at indexed 0
  cbDate.setUTCHours(8)
  const todayDate = new Date()
  todayDate.setUTCHours(8)
  const day = Math.round(Math.abs((cbDate - todayDate) / oneDay))

  const [offset, setOffset] = useState(0)
  useEffect(
    () =>
      setInterval(() => {
        setOffset((prev) => (prev < 800 ? prev + 1 : 0))
      }, 16),
    [],
  )

  if (slideId !== 1 && slideId !== 2) return null

  return (
    <Slide>
      <Content>
        <StyledImg src={couch} />
        <StyledMask offset={offset} src={couchNegative} />
      </Content>
      {slideId === 1 && <SlideText>It is day {day}.</SlideText>}
      {slideId === 2 && (
        <SlideText>
          You're uncomfortable.
          <br />A cushion falls flat on the floor. You twist. Backrest... a soft
          glow... urgh you fell asleep on the sofa again.
        </SlideText>
      )}
    </Slide>
  )
}

export default Scene1
