import { useState } from 'react'
import styled from 'styled-components'
import Window from '../Window'

const Advisory = styled.div`
  background: white;
  width: 400px;
  height: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 8px;
  margin: 100px auto;
  animation: scale ease 1s;

  @keyframes scale {
    0% {
      transform: scaleX(0) scaleY(0);
      opacity: 0;
    }
    50% {
      transform: scaleX(0) scaleY(0);
      opacity: 0;
    }
    100% {
      transform: scaleX(1) scaleY(1);
      opacity: 1;
    }
  }
`
const AdvisoryBtn = styled.button`
  font-family: BarlowRegular;
  margin: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;

  &:hover {
    background: black;
    color: white;
    border: 1px solid white;
  }
`

const LLArt = (props) => {
  const [showArt, setShowArt] = useState(false)
  return (
    <Window {...props}>
      {showArt ? (
        <iframe
          title="lynn lu"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          src="https://player.vimeo.com/video/512388311?autoplay=1"
        ></iframe>
      ) : (
        <Advisory>
          Viewer discretion advised. The following artwork contains content that
          may be disturbing to you. The artwork makes reference to the current
          Covid-19 pandemic and death.
          <AdvisoryBtn onClick={() => setShowArt(true)}>
            Continue to view artwork
          </AdvisoryBtn>
          <AdvisoryBtn onClick={() => props.onClose()}>
            Close and skip artwork
          </AdvisoryBtn>
        </Advisory>
      )}
    </Window>
  )
}

export default LLArt
