import Window from '../Window'

const KhengArt = (props) => (
  <Window {...props}>
    <iframe
      title="kheng hua"
      width="100%"
      height="100%"
      src="https://lettuce-coyote-79ee.squarespace.com/"
      frameborder="0"
    ></iframe>
  </Window>
)

export default KhengArt
