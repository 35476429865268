import styled from 'styled-components'
import Slide from '../Slide'

const BigText = styled.p`
  text-align: center;
  font-family: Roslindale;
  font-size: 5em;
  color: white;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 30vh;
  width: 100vw;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 700px;
  margin: 0 auto;
`

const FirstButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 80px;
  cursor: pointer;
  border: 1px solid white;
  background: none;
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const SecondButton = styled.button`
  font-size: 1.5em;
  font-family: Cirka;
  color: white;
  padding: 30px 80px;
  cursor: pointer;
  border: none;
  background: ${(props) => props.theme.colors.darkPurple};
  transition: 0.4s;

  &:hover {
    background: white;
    color: black;
  }
`

const Scene2 = ({ slideId, setSlideId }) => {
  if (slideId !== 3) return null

  return (
    <Slide>
      <Content>
        <BigText>Are you awake?</BigText>

        <Row>
          <FirstButton onClick={() => setSlideId(7)}>Open eyes.</FirstButton>
          <SecondButton onClick={() => setSlideId(4)}>
            Keep your eyes closed.
          </SecondButton>
        </Row>
      </Content>
    </Slide>
  )
}

export default Scene2
